export default {
    apiGetGalleryPhotosDataMoke() {
        let data = [
            {
                name: "Photo name",
                attachment: "Attachment here",
                description: "description",
                createdOn: "1 Jan 2022",
                createdBy: "Admin",
            }
        ];
        return this.mokeapi(() => data, "Getting all data please wait...", 1000);
    },
    apiGetGalleryVideosDataMoke() {
        let data = [
            {
                name: "Video name",
                attachment: "Attachment here",
                description: "description",
                createdOn: "1 Jan 2022",
                createdBy: "Admin",
            }
        ];
        return this.mokeapi(() => data, "Getting all data please wait...", 1000);
    },
    // PHOTOS API
    apiGetGalleryPhotos(data, urldata = {}) {
        let payload = {
            ...data,
        };

        return this.request(this.api().getGalleryPhotos, payload)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0,
                };
            })
            .get();
    },

    apiCreateGalleryPhoto(data) {
        let payload = {
            ...data
        };

        return this.request(this.api().createGalleryPhoto, payload).exec().log().get();
    },
    apiDeleteGalleryPhoto(urldata = mixins.params()) {
        return this.request(this.api().deleteGalleryPhoto)
            .urltransform(urldata)
            .exec()
            .log()
            .get();
    },
    // VIDEOS API
    apiGetGalleryVideos(data, urldata = {}) {
        let payload = {
            ...data,
        };

        return this.request(this.api().getGalleryVideos, payload)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0,
                };
            })
            .get();
    },

    apiCreateGalleryVideo(data) {
        let payload = {
            ...data
        };

        return this.request(this.api().createGalleryVideo, payload).exec().log().get();
    },
    apiDeleteGalleryVideo(urldata = mixins.params()) {
        return this.request(this.api().deleteGalleryVideo)
            .urltransform(urldata)
            .exec()
            .log()
            .get();
    },
}