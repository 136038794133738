import React from "react";
import { Link } from "react-router-dom";

function AppLinkTabs(props) {
  let {
    className = "",
    data = [],
    // overideClass = false,
    linkClass = "",
    // disabled,
    params = props.urlparams,
  } = props;
  // console.log("AppLinkTabs", props);
  // disabled = [undefined].includes(disabled)
  //   ? !!props?.urlparams?.new
  //   : disabled;
  // const isDisabled = (link) => {
  //   if (typeof disabled === "boolean") return disabled;
  //   else if (disabled instanceof Object) return disabled[link.name] || false;
  // };
  return (
    <div
    // className={Object.className({
    //   "flex flex-wrap mb-6": !overideClass,
    //   [className]: true,
    // })}
    >
      {data
        .map((link, i) => ({
          ...link,
          completed: data.findIndex((o) => o.path == window.location.pathname),
        }))
        .map((link, i) => (
          <Link
            key={i}
            // className={Object.className({
            //   "btn rounded-full border mx-1 min-w-[150px] m-clip tab_class": true,
            //   "btn-completed": i < link.completed,
            //   "btn-current": i == link.completed,
            //   [linkClass]: true,
            // })}
            className="btn session_tabs rounded-full border mx-1 min-w-[150px] my-1 "
            href="#"
            // disabled={isDisabled(link)}
            to={link.path + "?" + Object.QueryString(params)}
          >
            <span className="label"

            >{link.label}</span>
          </Link>
        ))}
    </div>
  );
}

export default connect(AppLinkTabs);
