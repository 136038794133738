/**
 * App Header
 */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sidemenu from "./sidemenu";

const Sidebar = (props) => {
  const [isSideMenu, setSideMenu] = useState("");
  const [menu, setMenu] = useState({});
  const [role, setRole] = useState("");
  useEffect(() => {
    setRole(localStorage.getItem("currentAdminRole"));
  }, []);

  const MenuLink = ({
    value,
    iconStyle,
    iconActive,
    name,
    children,
    className,
  }) => (
    <>
      <a
        href="#"
        className={Object.className({
          [className]: true,
          subdrop: isSideMenu == value,
        })}
        onClick={() => toggleSidebar(isSideMenu == value ? "" : value)}
      >
        {<div className="menu-icon">
          <img src={iconStyle}
            className={Object.className({
              inactive: !pathname.startsWith(menu.path),
              active: pathname.startsWith(menu.path),
              'menu-icon-img': true
            })}
            width="20px" alt="" />
          <img src={iconActive}
            className={Object.className({
              inactive: pathname.startsWith(menu.path),
              active: !pathname.startsWith(menu.path),
              'menu-icon-img': true
            })}
            width="20px" alt="" />
        </div>}
        <span>{name}</span>
        <span className="menu-arrow" />
      </a>
      {isSideMenu == value && children}
    </>
  );

  const toggleSidebar = (value) => {
    setSideMenu(value);
  };

  let pathname = props.location.pathname;
  return (
    <div className="sidebar" id="sidebar">
        {!!menu && " "}
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul className="metismenu" id="menu">
              {/* <li className="menu-title">
                <span>Main</span>
              </li> */}
              {role == "SUPER_ADMIN" &&
                sidemenu?.superAdmin.map((menu, i) => (
                  <li
                    key={i}
                    className={Object.className({
                      submenu: menu.children && menu.children.length,
                    })}
                  >
                    {menu.children && menu.children.length && (
                      <MenuLink
                        name={menu.label}
                        value={menu.label.toLowerCase()}
                        iconStyle={menu.iconStyle}
                        iconActive={menu.iconActive}
                        className={Object.className({
                          active: pathname.startsWith(menu.path),
                        })}
                      >
                        <ul>
                          {menu.children.map((submenu, j) => (
                            <li key={j}>
                              <Link
                                className={Object.className({
                                  active: pathname == submenu.path,
                                })}
                                onClick={() => setMenu(submenu)}
                                to={submenu.path}
                              >
                                {submenu.label}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </MenuLink>
                    )}
                    {!(menu.children && menu.children.length) && (
                      <Link
                        to={menu.path}
                        onClick={() => setMenu(submenu)}
                        className={Object.className({
                          active:
                            pathname == menu.path ||
                            pathname.startsWith(menu.path),
                        })}
                      >
                        {<div className="menu-icon">
                            <img src={menu.iconStyle}
                              className={Object.className({
                                inactive: !pathname.startsWith(menu.path),
                                active: pathname.startsWith(menu.path),
                                'menu-icon-img': true
                              })}
                              width="20px" alt="" />
                            <img src={menu.iconActive}
                              className={Object.className({
                                inactive: pathname.startsWith(menu.path),
                                active: !pathname.startsWith(menu.path),
                                'menu-icon-img': true
                              })}
                              width="20px" alt="" />
                          </div>}
                        <span>{menu.label}</span>
                        {pathname == menu.path && (
                          <span className={`!ml-auto ${menu.path}`}>
                            <em className="la la-hand-o-left"></em>
                          </span>
                        )}
                      </Link>
                    )}
                  </li>
                ))}
              {role == "SCHOOL_ADMIN" &&
                sidemenu.schoolAdmin.map((menu, i) => (
                  <li
                    key={i}
                    className={Object.className({
                      submenu: menu.children && menu.children.length,
                    })}
                  >
                    {menu.children && menu.children.length && (
                      <MenuLink
                        name={menu.label}
                        value={menu.label.toLowerCase()}
                        iconStyle={menu.iconStyle}
                        iconActive={menu.iconActive}
                        className={Object.className({
                          active: pathname.startsWith(menu.path),
                        })}
                      >
                        <ul>
                          {menu.children.map((submenu, j) => (
                            <li key={j}>
                              <Link
                                className={Object.className({
                                  active: pathname == submenu.path,
                                })}
                                onClick={() => setMenu(submenu)}
                                to={submenu.path}
                              >
                                {submenu.label}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </MenuLink>
                    )}
                    {!(menu.children && menu.children.length) && (
                      <Link
                        to={menu.path}
                        onClick={() => setMenu(submenu)}
                        className={Object.className({
                          active:
                            pathname == menu.path ||
                            pathname.startsWith(menu.path),
                        })}
                      >
                        {<div className="menu-icon">
                            <img src={menu.iconStyle}
                              className={Object.className({
                                inactive: !pathname.startsWith(menu.path),
                                active: pathname.startsWith(menu.path),
                                'menu-icon-img': true
                              })}
                              width="20px" alt="" />
                            <img src={menu.iconActive}
                              className={Object.className({
                                inactive: pathname.startsWith(menu.path),
                                active: !pathname.startsWith(menu.path),
                                'menu-icon-img': true
                              })}
                              width="20px" alt="" />
                          </div>}
                        {menu.image && <img src={menu.image} />}
                        <span>{menu.label}</span>
                        {pathname == menu.path && (
                            <span className={`!ml-auto ${menu.path}`}>
                              <em className="la la-hand-o-left"></em>
                            </span>
                          )}
                      </Link>
                    )}
                  </li>
                ))}
              {role == "SCHOOL_COORDINATOR" &&
                sidemenu?.schoolCoordinator.map((menu, i) => (
                  <li
                    key={i}
                    className={Object.className({
                      submenu: menu.children && menu.children.length,
                    })}
                  >
                    {menu.children && menu.children.length && (
                      <MenuLink
                        name={menu.label}
                        value={menu.label.toLowerCase()}
                        iconStyle={menu.iconStyle}
                        iconActive={menu.iconActive}
                        className={Object.className({
                          active: pathname.startsWith(menu.path),
                        })}
                      >
                        <ul>
                          {menu.children.map((submenu, j) => (
                            <li key={j}>
                              <Link
                                className={Object.className({
                                  active: pathname == submenu.path,
                                })}
                                onClick={() => setMenu(submenu)}
                                to={submenu.path}
                              >
                                {submenu.label}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </MenuLink>
                    )}
                    {!(menu.children && menu.children.length) && (
                      <Link
                        to={menu.path}
                        onClick={() => setMenu(submenu)}
                        className={Object.className({
                          active:
                            pathname == menu.path ||
                            pathname.startsWith(menu.path),
                        })}
                      >
                        {<div className="menu-icon">
                            <img src={menu.iconStyle}
                              className={Object.className({
                                inactive: !pathname.startsWith(menu.path),
                                active: pathname.startsWith(menu.path),
                                'menu-icon-img': true
                              })}
                              width="20px" alt="" />
                            <img src={menu.iconActive}
                              className={Object.className({
                                inactive: pathname.startsWith(menu.path),
                                active: !pathname.startsWith(menu.path),
                                'menu-icon-img': true
                              })}
                              width="20px" alt="" />
                          </div>}
                        <span>{menu.label}</span>
                        {pathname == menu.path && (
                          <span className={`!ml-auto ${menu.path}`}>
                            <em className="la la-hand-o-left"></em>
                          </span>
                        )}
                      </Link>
                    )}
                  </li>
                ))}
            </ul>
          </div>
        </div>
    </div>
  );
};

export default connect(Sidebar);
