import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { right } from '@popperjs/core';
import React from 'react';
import { FullScreen, useFullScreenHandle } from "react-full-screen";


const DocViewerComp = ({ documents, show, ext }) => {
    const handle = useFullScreenHandle();

    return (
        <>
            {!!documents?.length && (<div className="w-100 d-flex justify-content-end">
                <button className="btn btn-primary btn-sm" onClick={handle.enter}>
                    <i className="fa fa-expand"></i>
                </button>
            </div>)}
            <div style={{
                maxHeight: "100%",
                minHeight: "300px",
                height: "100%",
                overflow: "hidden"
            }}>
                <FullScreen handle={handle} className="h-100">
                    {(ext == 'ppt' || ext == 'pptx') && <div className={handle.active == true ? "doc-overlay-full" : "doc-overlay"}></div>}
                    {(ext == 'doc' || ext == 'docx') && <div className={handle.active == true ? "doc-overlay-full" : "doc-overlay"}
                    ></div>}
                    <DocViewer documents={documents} pluginRenderers={DocViewerRenderers} config={{ header: { disableHeader: true } }} className="doc-viewer" allowfullscreen="true" sandbox="allow-scripts" />
                </FullScreen>
            </div>
        </>);
};
export default DocViewerComp;