import { Component } from "react";
import LayoutWrapper from "../../LayoutWrapper";
import "./preview.scss";
import { Link } from "react-router-dom";

export class Preview extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("Preview");
    this.state = {
      sessionData: {},
      videosData: [],
      pptData: [],
      assessmentData: [],
      handoutsData: [],
      previewData: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    return new Promise((resolve, reject) => {
      this.props.api.apiGetPreviewDetails({ session_id: this.props.urlparams.session_id }).then((response) =>{
        let data = response.data.data;
        let obj = {
          session_name : data.session_name,
          session_description : data.session_description
        }
        this.setState({
          sessionData: obj,
          previewData: data,
        });
        resolve();
      })
    });
  }

  sumitForm(event, data, form) {
    console.log({ previewData: data });
  }

  getBreadCums() {
    if (this.props.urlparams.grade) {
      return `Content Management / ${
        this.props.urlparams.grade
      } Grade / Courses / ${this.props.urlparams.type.toTitleCase()} / Add Session`;
    } else {
      return "Content Management / Sessions";
    }
  }

  getBackLink() {
    if (this.props.urlparams.grade) {
      let backLink = `/contentmanagement/sessions/addassessments?grade=${this.props.urlparams.grade}&level=${this.props.urlparams.level}&type=${this.props.urlparams.type}&curriculum_id=${this.props.urlparams.curriculum_id}&session_id=${this.props.urlparams.session_id}`;
      return backLink;
    } else {
      return "/contentmanagement/sessions";
    }
  }
  render() {
    let { props } = this;
    let { previewData } = this.state;

    return (
      <LayoutWrapper
        title="Course Configure"
        back={this.getBreadCums()}
        backlink={this.getBackLink()}
      >
        <div className="row m-auto">
          <div className="col"></div>
          <div className="mb-4"></div>
        </div>

        <div className="row bg-white">
          <div className="module_detail_container">
            {/* <h2 className="title">{sessionData.session_name}</h2><br/> */}
            {/* <p className="sub_title">{sessionData.session_description}</p> */}

            <div className="media_card">
              <p className="heading">No of Videos - {previewData?.video?.length}</p>
              <div className="mini_box_container row g-3">
                {previewData?.video?.map((item, i) => {
                  return (
                    <div className="mini_box col-6 col-md-3" key={i}>
                      <p className="card_title">{item.content_title}</p>
                      {/* <p className="link">Preview Video</p> */}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="media_card">
              <p className="heading">No of Manual - {previewData?.ppt?.length}</p>
              <div className="mini_box_container row g-3">
                {previewData?.ppt?.map((item, i) => { 
                  return (
                    <div className="mini_box col-6 col-md-3" key={i}>
                      <p className="card_title">{item.content_title}</p>
                      {/* <p className="link">Preview Manual</p> */}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="media_card">
              <p className="heading">No of Handout - {previewData?.handout?.length}</p>
              <div className="mini_box_container row g-3">
                {previewData?.handout?.map((item, i) => {
                  return (
                    <div className="mini_box col-6 col-md-3" key={i}>
                      <p className="card_title">{item.content_title}</p>
                      {/* <p className="link">Preview Handout</p> */}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="assessment_card">
              <p className="heading">
                No of Assessments - {previewData?.assessment?.length}
              </p>
              <div className="assessment_box_container">
                {previewData?.assessment?.map((item, i) => {
                  return (
                    <div className="assessment_box" key={i}>
                      <p className="card_title">{item.assessment_name}</p>
                      {/* {item?.assessment_questions?.map((field, j) => {
                        return (
                          <div className="mb-2" key={j}>
                            <p className="question">
                              <span>Q:</span>
                              {field.q_label}
                            </p>
                            <p className="answer">
                              <span>A:</span>
                              {field.a_value}
                            </p>
                          </div>
                        );
                      })} */}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-12 my-5 px-4">
            <Link
              to={
                "/contentmanagement/sessions?" +
                Object.QueryString({
                  ...this.props.urlparams,
                })
              }
              className="btn add-btn ms-3"
            >
              Submit
            </Link>
            <Link
              to={`/contentmanagement/sessions/addassessments?grade=${this.props.urlparams.grade}&level=${this.props.urlparams.level}&type=${this.props.urlparams.type}&curriculum_id=${this.props.urlparams.curriculum_id}&session_id=${this.props.urlparams.session_id}`}
              className="btn btn_primary grey col col-md-1 float-right"
            >
              Cancel
            </Link>
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(Preview);
