import moment from "moment";

/*eslint no-extend-native: ["error", { "exceptions": ["Date","String","Number"] }]*/
String.prototype.moment = Number.prototype.moment = Date.prototype.moment = function () {
    return moment(this);
};

Date.prototype.addDays = function (days = 1) {
    let startDate = moment(this).clone().add(days, "days");
    return new Date(startDate);
};

Date.prototype.firstDate = function () {
    const startOfMonth = moment(this).startOf("month").format("YYYY-MM-DD");
    return new Date(startOfMonth);
};

Date.prototype.lastDate = function () {
    const endOfMonth = moment(this).endOf("month").format("YYYY-MM-DD");
    return new Date(endOfMonth);
};

Date.prototype.toDate = function (sep = "-") {
    return moment(this).format(`YYYY${sep}MM${sep}DD`);
};

Date.prototype.toTime = function (sep = ":") {
    return moment(this).format(`HH${sep}mm${sep}ss`);
};
Date.prototype.toTimems = function (sep = ":") {
    return moment(this).format(`HH${sep}mm${sep}ss.S`);
};


Number.prototype.duration = function () {
    return moment.duration(this);
};

Date.prototype.diff = function (date, type = "seconds") {
    return this.moment().diff(date, type);
}

String.prototype.moment = Number.prototype.moment = function (...args) {
    return moment(this, ...args);
}

String.prototype.to24Time = Number.prototype.to24Time = function () {
    return this.moment(["h:mm A"]).format("HH:mm");
}

Number.prototype.duration = function () {
    return moment.duration(this);
}

Date.prototype.getMonthList = function (startMonthNum = 3) {
    return [].filllen(12).map((v, i) => {
        let index = (i + startMonthNum) % 12;
        return {
            index,
            addyear: index < startMonthNum ? 1 : 0
        }
    }).map(({ index: i, addyear }) => {
        let value = new Date(new Date().getFullYear() + addyear, i, 1);
        return {
            key: value.getMonthDisplay().toLowerCase(),
            label: value.getMonthDisplay(),
            month_number: value.getMonth(),
            year: value.getFullYear(),
        }
    })
}

Date.prototype.dateList = function () {
    let date = this.firstDate(), arr = [];
    let lastDate = date.lastDate();
    let i = 0;
    while (date <= lastDate && i < 40) {
        i++;
        arr.push(date);
        date = new Date(date).addDays();
    }
    return arr;
};

Date.prototype.elapsedTime = function (now = new Date(), trunk = 2) {
    let date = this;
    let timestamp = date.toDateTime().moment();
    let diff = timestamp.diff(now.getTime().moment());
    let duration = Math.abs(diff).duration();
    let arr = [
        {
            name: "years",
            short: "Y",
            value: duration.years(),
        },
        {
            name: "months",
            short: "M",
            value: duration.months(),
        },
        {
            name: "days",
            short: "d",
            value: duration.days(),
        },
        {
            name: "hours",
            short: "h",
            value: duration.hours(),
        },
        {
            name: "minutes",
            short: "mi",
            value: duration.minutes(),
        },
        {
            name: "seconds",
            short: "s",
            value: duration.seconds(),
        },
    ];
    return arr.filter(o => o.value).map(o => o.value + o.short).splice(0, trunk || arr.length).join(' , ') + ' ' + (diff > 0 ? 'ahead' : 'ago');
};

Date.prototype.calender = function () {
    let first_date = this.firstDate();
    let last_date = this.lastDate();
    let list = first_date.dateList();
    let fpos = first_date.getDay();
    let lpos = (7 - last_date.getDay()) - 1;
    let first = []
        .filllen(fpos, new Date(this).firstDate())
        .map((o, i) => new Date(o).addDays(-(i + 1))).reverse();
    let last = []
        .filllen(lpos, new Date(this).lastDate())
        .map((o, i) => new Date(o).addDays(i + 1));
    return [].concat(first).concat(list).concat(last).chunk(7);
}


Date.prototype.toDateTime = function () {
    let date = this;
    return `${date.toDate()} ${date.toTime()}`;
};

Date.prototype.toDateTimeMs = function () {
    let date = this;
    return `${date.toDate()} ${date.toTimems()}`;
};

String.prototype.date = Number.prototype.date = function (pre = "", post = "") {
    return new Date(pre + this + post);
};

Date.prototype.nextSec = function (diff = 1) {
    return new Date(
        this.getFullYear(),
        this.getMonth(),
        this.getDate(),
        this.getHours(),
        this.getMinutes(),
        this.getSeconds() + diff
    );
};

String.prototype.time2date = function (diff = 1) {
    return new Date(new Date().toDate() + "T" + this);
};

Date.prototype.getMonthDisplay = function () {
    let months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    let month = this.getMonth();
    let year = this.getFullYear();
    return `${months[month].toLowerCase().toTitleCase()}-${year}`;
}

Date.prototype.toMonthDate = function (split = "-") {
    return this.toDate().split(split).slice(0, 2).join(split);
}

Date.prototype.getDayName = function () {
    return (["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"])[this.getDay()];
}

Date.prototype.getDayFullName = function () {
    let days = ["Sanday", "Monday", "Tuesday", "Wednesday", "Thrusday", "Friday", "Saturday"];
    return days[this.getDay()];
}

Date.prototype.getAge = function () {
    let today = new Date();
    let birthDate = new Date(this);
    let ageValue = today.getYear() - birthDate.getYear();
    let birthMonthDiff = today.getMonth() - birthDate.getMonth();
    let inSameYear = birthMonthDiff === 0 && today.getDate() < birthDate.getDate();
    let isBirthdayInCompleated = birthMonthDiff < 0 || inSameYear;
    if (isBirthdayInCompleated) { ageValue--; }
    return ageValue;
}

Date.prototype.setAge = function (age) {
    let year = this.getFullYear();
    let birthYear = year - age;
    return new Date(birthYear, this.getMonth(), this.getDate());
}

Date.prototype.getFinacialYear = function (finacialMonth = 3) {
    let year = new Date().getFullYear();
    let miniyear = (year) => year.toString().slice(-2);
    return this.getMonth() > finacialMonth ? `${year}-${miniyear(year + 1)}` : `${year - 1}-${miniyear(year)}`;
}

Date.prototype.dateToDDMMYYYY = function (date, separation = "-") {
    let day = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let year = date.getFullYear();
    if (separation === "/") {
        return `${day}/${month}/${year}`;
    } else {
        return `${day}-${month}-${year}`;
    }
}