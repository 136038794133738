import cmMapcolumns from "./cm.mapcolumns";
export default {
  apiFileUploader(data, urldata = mixins.params()) {
    console.log("filedata", data);
    return this.request(this.api().fileuploader, data)
      .urltransform(urldata)
      .multipart()
      .exec()
      .log()
      .get();
  },
  apiFileRemover(data, urldata = mixins.params()) {
    return this.request(this.api().filedeleter, data)
      .urltransform(urldata)
      .multipart()
      .exec()
      .log()
      .get();
  },
  apiFileDownloader(data, urldata = mixins.params()) {
    return this.request(this.api().fileDownloader, data)
      .urltransform(urldata)
      .multipart()
      .exec()
      .log()
      .get();
  },
  apiGetPptdata(data, urldata = {}) {
    return this.request(this.api().fileDownloader, data)
      .urltransform(data)
      .exec()
      .log()
      .get();
  },
  apiContentsData(urldata) {
    let data = [
      {
        grade: "VI",
        level: 1,
        content:
          "Major focus areas: Experiential learning, Brain Power Enrichment Behavioral and communication skills.",
      },
      {
        grade: "VII",
        level: 2,
        content:
          "Major focus areas: Experiential learning, Brain Power Enrichment Behavioral and communication skills.",
      },
      {
        grade: "VIII",
        level: 3,
        content:
          "Major focus areas: Experiential learning, Brain Power Enrichment Behavioral and communication skills.",
      },
      {
        grade: "IX",
        level: 4,
        content:
          "Major focus areas: Experiential learning, Brain Power Enrichment Behavioral and communication skills.",
      },
    ];

    return this.request(this.api().getCurriculum)
      .urltransform(urldata)
      .multipart()
      .exec()
      .log()
      .get();
  },
  apiAddClassMoke(data) {
    let payload = {
      class_name: data.class_name,
      class_level: data.class_level,
      class_description: data.class_description,
    };
    // return this.request(this.api().addSchool, payload).exec().log().get();
    return this.mokeapi(() => {
      return mokeresults.CLASS_LIST;
    });
  },
  apiGetClasses(urldata = {}) {
    //data,

    console.log("this is data", { urldata });
    urldata = { ...mixins.params(), ...urldata };
    // const role = localStorage.getItem('currentAdminRole');
    // let endPointObj = role == 'SCHOOL_ADMIN' ? this.api().getCurriculumSchool : this.api().getClasses;
    let endPointObj = this.api().getClasses;
    return this.request(endPointObj) //, data
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiGetClasseById(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().getClassById)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        console.log("getClassById: ", response);
        return {
          data: response.data,
        };
      })
      .get();
  },
  apiAddClass(data) {
    console.log({ apiAddClass: data });
    let payload = {
      ...cmMapcolumns.mapPayload("class", data),
    };
    payload = Object.filter(payload, (v) => v);

    return this.request(this.api().createClass, payload).exec().log().get();
  },

  apiChangeSessionAccess(data, urldata = { ...mixins.params() }) {
    let payload = {
      ...data,
      curriculum_id: urldata.curriculum_id,
      school_id: "1osdg2w6llnlnzpk",
      // session_id: "2yoiyi2rnsllm7v71m",
    };

    // console.log("urldata", urldata)

    return this.request(this.api().changeSessionAccess, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },
  apiCreateSession(data) {
    // console.log({ apiCreateSession: data });
    let payload = {
      ...cmMapcolumns.mapPayload("session", data),
    };
    payload = Object.filter(payload, (v) => v);

    return this.request(this.api().createSession, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },

  apiGetFileMetaData(urldata) {
    return this.request(this.api().getFileMetaData)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },
  apiGetSessionsMoke() {
    let data = [
      // {
      //     title: "Session 1",
      //     status: "active",
      //     percent: 75,
      //     description:
      //         "Sorem ipsum dolor sit amet consectetur adipiscing elit massa aenean orci erat pellentesque areaultrices idposere interdum est proin lacus acilisis faucibus egestas fringilla Sorem ipsum dolor sit amet consectetur adipiscing elit massa aenean orci erat pellentesque areaultrices idposere interdum est proin lacus acilisis faucibus egestas fringilla",
      //     links: [
      //         {
      //             attachment_id: "267f0f00-4339-4f5b-b38b-f856111ff03j",
      //             file_type: "video",
      //             file_name: "Video",
      //         },
      //         {
      //             attachment_id: "267f0f00-4339-4f5b-b38b-f856111ff04h",
      //             file_type: "ppt",
      //             file_name: "PPT",
      //         },
      //         {
      //             attachment_id: "267f0f00-4339-4f5b-b38b-f856111dp03j",
      //             file_type: "doc",
      //             file_name: "Handouts",
      //         },
      //         {
      //             attachment_id: "267f0f00-4339-4f5b-b38b-f856091ff04h",
      //             file_type: "notes",
      //             file_name: "Assessments",
      //         },
      //     ],
      // },
      // {
      //     title: "Session 2",
      //     status: "disabled",
      //     percent: 75,
      //     description:
      //         "Sorem ipsum dolor sit amet consectetur adipiscing elit massa aenean orci erat pellentesque areaultrices idposere interdum est proin lacus acilisis faucibus egestas fringilla Sorem ipsum dolor sit amet consectetur adipiscing elit massa aenean orci erat pellentesque areaultrices idposere interdum est proin lacus acilisis faucibus egestas fringilla",
      // },
    ];
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },
  apiGetSessions(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    const role = localStorage.getItem("currentAdminRole");
    const endPointObj =
      role == "SCHOOL_COORDINATOR"
        ? this.api().getSchoolSessions
        : this.api().getAllSessions;

    return this.request(endPointObj, data)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        if (role == "SCHOOL_COORDINATOR") {
          console.log(response.data);

          return {
            data: response.data.map((record) =>
              cmMapcolumns.mapFields("session", record)
            ),
            total: 0,
          };
        }

        console.log(response.data[0].records);

        return {
          data: response.data[0].records.map((record) =>
            cmMapcolumns.mapFields("session", record)
          ),
          total: response.data[0].pagination[0]?.totalRecords || 0,
        };
      })
      .get();
  },
  apiGetSessionById(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().getSessionById)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },
  apiGetSessions(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().getSessions)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },
  apiUpdateSession(data) {
    console.log({ apiUpdateSession: data });

    let urldata = {
      session_id: data.session_id,
    };

    let payload = {
      // ...Object.except(cmMapcolumns.mapPayload('session', data), ["session_id", "session_name"])
      ...Object.except(data, ["session_id"]),
    };

    payload = Object.filter(payload, (v) => v);

    return this.request(this.api().updateSession, payload)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },

  apiUpdateSessionActive(payload) {
    let urldata = {
      ...mixins.params(),
      session_id: payload.session_id,
    };

    delete payload.session_id;

    return this.request(this.api().updateSession, payload)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },

  apiDeleteSession(urldata = mixins.params()) {
    return this.request(this.api().deleteSession)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  // PPT

  apiGetAllPPTById(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    const role = localStorage.getItem("currentAdminRole");
    const endPointObj =
      role == "SCHOOL_COORDINATOR"
        ? this.api().getSchoolPpts
        : this.api().getAllPPTById;

    return this.request(endPointObj)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data.map((record) =>
            cmMapcolumns.mapFields("ppt", record)
          ),
          total: response.data.length || 0,
        };
      })
      .get();
  },

  apiDeletePPT(urldata = mixins.params()) {
    return this.request(this.api().deletePPT)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },



  apiDeleteHandout(urldata = mixins.params()) {
    return this.request(this.api().deleteHandout)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  // ASSESSMENTS
  apiCreateAssessment(data, urldata = {}) {
    // console.log({ apiCreateAssessment: data });
    urldata = { ...mixins.params(), ...urldata };
    let payload = {
      ...data,
    };
    payload = Object.filter(payload, (v) => v);

    return this.request(this.api().createAssessment, payload)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },
  apiGetAssessments(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    const role = localStorage.getItem("currentAdminRole");
    const endPointObj =
      role == "SCHOOL_COORDINATOR"
        ? this.api().getSchoolAssessments
        : this.api().getAssessments;

    return this.request(endPointObj)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
          total: response.data.length || 0,
        };
      })
      .get();
  },

  apiGetAssessmentById(data) {
    return this.request(this.api().getAssessmentById)
      .urltransform(data)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },

  apiUpdateAssessment(data, urldata = {}) {
    // console.log({ apiUpdateHandout: data, urldata });
    urldata = { ...mixins.params(), ...urldata };
    let payload = {
      ...data,
    };
    payload = Object.filter(payload, (v) => v);

    return this.request(this.api().updateAssessment, payload)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiDeleteAssessment(urldata = mixins.params()) {
    return this.request(this.api().deleteAssessment)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiAddAssessmentQuestion(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    let payload = {
      ...data,
    };
    if (data.q_options) {
      payload = {
        ...data,
        q_options: data.q_options.split(",") || [],
      };
    }
    return this.request(this.api().addAssessmentQuestion, payload)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },

  apiUpdateAssessmentQuestion(data, urldata = {}) {
    // console.log({ apiUpdateHandout: data, urldata });
    urldata = { ...mixins.params(), ...urldata };
    let payload = {
      ...data,
    };
    if (data.q_options) {
      payload = {
        ...data,
        q_options: data.q_options.split(",") || [],
      };
    }
    payload = Object.filter(payload, (v) => v);

    return this.request(this.api().updateAssessmentQuestion, payload)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiDeleteAssessmentQuestion(urldata = mixins.params()) {
    return this.request(this.api().deleteAssessmentQuestion)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  // VIDEO API CALLS
  apiCreateVideo(data) {
    return this.request(this.api().createVideo, [data])
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },

  apiGetVideosById(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    const role = localStorage.getItem("currentAdminRole");
    const endPointObj =
      role == "SCHOOL_COORDINATOR"
        ? this.api().getSchoolVideos
        : this.api().getVideos;

    return this.request(endPointObj)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data.map((record) =>
            cmMapcolumns.mapFields("ppt", record)
          ),
          total: response.data.length || 0,
        };
      })
      .get();
  },

  apiDeleteVideo(urldata = mixins.params()) {
    return this.request(this.api().deleteVideo)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  // video progress
  apiRecordProgress(urldata = {}) {
    return this.request(this.api().recordProgress, urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },

  // STUDENT API CALLS
  apiSubmitAssessment(data) {
    return this.request(this.api().submitAssessment, data)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },
  // MOKES API CALLS
  apiGetPillarCardsMoke() {
    let data = [
      {
        key: "conceptual",
        title: "Conceptual",
        percent: 75,
        totalSessions: 8,
        bgImg: imagepaths.ConceptualBg,
        description:
          "Targets conceptual understanding of classroom topics through...",
      },
      {
        key: "engineering",
        title: "Engineering & Technology",
        percent: 75,
        totalSessions: 12,
        bgImg: imagepaths.ApplicationBg,
        description: "Targets real world application knowledge by exposing...",
      },
      {
        key: "personality",
        title: "Personality",
        percent: 75,
        totalSessions: 15,
        bgImg: imagepaths.PersonalityBg,
        description: "Targets developing communication, leadership and...",
      },
    ];
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },
  apiGetVideosMoke() {
    let data = [
      {
        video_name: "video name 1",
        attachments: [
          {
            attachmentId: "dcd6e187-9f3b-4986-accb-caf2ebe44adc",
            attachmentName: "Download",
            _id: "64d66eecf0f18d0475e5d177",
          },
        ],

        description: "description here",
        created_on: "1 Jan 2022",
        created_by: "Admin",
      },
      {
        video_name: "video name 2",
        attachments: [
          {
            attachmentId: "dcd6e187-9f3b-4986-accb-caf2ebe44add",
            attachmentName: "Download",
            _id: "64d66eecf0f18d0475e5d178",
          },
        ],
        description: "description here",
        created_on: "14 Mar 2022",
        created_by: "Admin",
      },
      {
        video_name: "video name 3",
        attachments: [
          {
            attachmentId: "dcd6e187-9f3b-4986-accb-caf2ebe44ade",
            attachmentName: "Download",
            _id: "64d66eecf0f18d0475e5d179",
          },
        ],
        description: "description here",
        created_on: "13 Sept 2022",
        created_by: "Admin",
      },
      {
        video_name: "video name 4",
        attachments: [
          {
            attachmentId: "dcd6e187-9f3b-4986-accb-caf2ebe44adf",
            attachmentName: "Download",
            _id: "64d66eecf0f18d0475e5d180",
          },
        ],
        description: "description here",
        created_on: "1 Jul 2023",
        created_by: "Admin",
      },
    ];
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },
  apiGetVideoDataMoke() {
    let data = [
      {
        session_title: "Session 1",
        description: "Description",
        link: "",
        total_likes: "12,456",
        total_dislikes: "456",
        total_percent: 75,
        suggestions: [
          {
            title: "Video 1",
            link: "",
          },
          {
            title: "Video 2",
            link: "",
          },
          {
            title: "Video 3",
            link: "",
          },
        ],
      },
    ];
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },
  apiGetPPTMoke() {
    let data = [
      {
        ppt_name: "ppt name 1",
        attachments: [
          {
            attachmentId: "dcd6e187-9f3b-4986-accb-caf2ebe44adc",
            attachmentName: "Download",
            _id: "64d66eecf0f18d0475e5d177",
          },
        ],

        description: "description here",
        created_on: "1 Jan 2022",
        created_by: "Admin",
      },
      {
        ppt_name: "ppt name 2",
        attachments: [
          {
            attachmentId: "dcd6e187-9f3b-4986-accb-caf2ebe44add",
            attachmentName: "Download",
            _id: "64d66eecf0f18d0475e5d178",
          },
        ],
        description: "description here",
        created_on: "14 Mar 2022",
        created_by: "Admin",
      },
      {
        ppt_name: "ppt name 3",
        attachments: [
          {
            attachmentId: "dcd6e187-9f3b-4986-accb-caf2ebe44ade",
            attachmentName: "Download",
            _id: "64d66eecf0f18d0475e5d179",
          },
        ],
        description: "description here",
        created_on: "13 Sept 2022",
        created_by: "Admin",
      },
      {
        ppt_name: "ppt name 4",
        attachments: [
          {
            attachmentId: "dcd6e187-9f3b-4986-accb-caf2ebe44adf",
            attachmentName: "Download",
            _id: "64d66eecf0f18d0475e5d180",
          },
        ],
        description: "description here",
        created_on: "1 Jul 2023",
        created_by: "Admin",
      },
    ];
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },
  apiGetHandoutsMoke() {
    let data = [
      {
        handout_name: "Handout name 1",
        attachments: [
          {
            attachmentId: "dcd6e187-9f3b-4986-accb-caf2ebe44adc",
            attachmentName: "Download",
            _id: "64d66eecf0f18d0475e5d177",
          },
        ],
        description: "description here",
        created_on: "1 Jan 2022",
        created_by: "Admin",
      },
      {
        handout_name: "Handout name 2",
        attachments: [
          {
            attachmentId: "dcd6e187-9f3b-4986-accb-caf2ebe44add",
            attachmentName: "Download",
            _id: "64d66eecf0f18d0475e5d178",
          },
        ],
        description: "description here",
        created_on: "14 Mar 2022",
        created_by: "Admin",
      },
      {
        handout_name: "Handout name 3",
        attachments: [
          {
            attachmentId: "dcd6e187-9f3b-4986-accb-caf2ebe44ade",
            attachmentName: "Download",
            _id: "64d66eecf0f18d0475e5d179",
          },
        ],
        description: "description here",
        created_on: "13 Sept 2022",
        created_by: "Admin",
      },
      {
        handout_name: "Handout name 4",
        attachments: [
          {
            attachmentId: "dcd6e187-9f3b-4986-accb-caf2ebe44adf",
            attachmentName: "Download",
            _id: "64d66eecf0f18d0475e5d180",
          },
        ],
        description: "description here",
        created_on: "1 Jul 2023",
        created_by: "Admin",
      },
    ];
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },
  apiGetAssessmentDataMoke() {
    let data = [
      {
        assessment_id: "01",
        assessment_name: "assessment new",
        assessment_questions: [
          {
            question_id: "01",
            q_type: "text",
            q_label: "Lorem Ipsum placeholder text for use in your graphic",
            q_level: "simple",
            possible_values: "a,s,d,f",
          },
          {
            question_id: "02",
            q_type: "checkbox",
            q_label: "question 2",
            q_level: "complex",
            possible_values: "d,f,g",
          },
          {
            question_id: "03",
            q_type: "radio",
            q_label: "question 3",
            q_level: "simple",
            possible_values: "yes, no",
          },
          {
            question_id: "04",
            q_type: "text",
            q_label: "question 4",
            q_level: "medium",
            possible_values: "5,3,6,7",
          },
        ],
      },
      {
        assessment_id: "02",
        assessment_name: "test assessment",
        assessment_questions: [
          {
            q_label: "Lorem Ipsum placeholder text for use in your graphic",
            q_type: "checkbox",
            q_level: "medium",
            possible_values: "one, two",
            question_id: "01",
          },
          {
            question_id: "02",
            q_type: "radio",
            q_label: "question 3",
            q_level: "simple",
            possible_values: "yes, no",
          },
        ],
      },
    ];
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },
  apiGetVideosPreviewDataMoke() {
    let data = [
      {
        file_id: "1",
        file_name: "Video Name 1",
        file_type: "video",
        attachment_id: "attachment id",
      },
      {
        file_id: "2",
        file_name: "Video Name 2",
        file_type: "video",
        attachment_id: "attachment id",
      },
      {
        file_id: "3",
        file_name: "Video Name 3",
        file_type: "video",
        attachment_id: "attachment id",
      },
    ];
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },
  apiGetPPTPreviewDataMoke() {
    let data = [
      {
        file_id: "1",
        file_name: "PPT Name - 1",
        file_type: "ppt",
        attachment_id: "attachment id",
      },
      {
        file_id: "2",
        file_name: "PPT Name - 2",
        file_type: "ppt",
        attachment_id: "attachment id",
      },
      {
        file_id: "3",
        file_name: "PPT Name - 3",
        file_type: "ppt",
        attachment_id: "attachment id",
      },
    ];
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },
  apiGetHandoutsPreviewDataMoke() {
    let data = [
      {
        file_id: "1",
        file_name: "Handout Name - 1",
        file_type: "doc",
        attachment_id: "attachment id",
      },
      {
        file_id: "2",
        file_name: "Handout Name - 2",
        file_type: "doc",
        attachment_id: "attachment id",
      },
      {
        file_id: "3",
        file_name: "Handout Name - 3",
        file_type: "doc",
        attachment_id: "attachment id",
      },
    ];
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },
  apiGetAssessmentsPreviewDataMoke() {
    let data = [
      {
        assessment_id: "1",
        assessment_name: "Assessment - 1",
        fields: [
          {
            question: "Lorem Ipsum placeholder text for use in your graphic",
            answer:
              "Explore the origins, history and meaning of the famous passage",
          },
          {
            question: "Lorem Ipsum placeholder text for use in your graphic",
            answer:
              "Explore the origins, history and meaning of the famous passage",
          },
          {
            question: "Lorem Ipsum placeholder text for use in your graphic",
            answer:
              "Explore the origins, history and meaning of the famous passage",
          },
        ],
      },
      {
        assessment_id: "2",
        assessment_name: "Assessment - 2",
        fields: [
          {
            question: "Lorem Ipsum placeholder text for use in your graphic",
            answer:
              "Explore the origins, history and meaning of the famous passage",
          },
          {
            question: "Lorem Ipsum placeholder text for use in your graphic",
            answer:
              "Explore the origins, history and meaning of the famous passage",
          },
          {
            question: "Lorem Ipsum placeholder text for use in your graphic",
            answer:
              "Explore the origins, history and meaning of the famous passage",
          },
        ],
      },
    ];
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },
  apiAddSession(data) {
    // console.log({ apiAddSession: data });
    let payload = {
      ...cmMapcolumns.mapPayload("session", data),
    };
    payload = Object.filter(payload, (v) => v);

    return this.request(this.api().addSessions, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },
  apiGetSessionsList(data) {

    return this.request(this.api().getSessionList, data)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },
  apiGetContentSessionById(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().getcontentSessionById)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },
  apiGetPreviewDetails(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().getPreviewDetails)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response,
        };
      })
      .get();
  },

  apiUpdateSessionById(data) {
    let urldata = {
      session_id: data.session_id,
    };
    let payload = {
      ...Object.except(data, ["session_id"]),
    };
    return this.request(this.api().updateSessionById, payload)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },

  apiGetContentBySession(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().getContentBySession)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
          total: response.count || 0,
        };
      })
      .get();
  },

  apiCreateConetent(data) {
    return this.request(this.api().createConetent, data)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },

  apiUpdateConetent(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().updateConetent, data)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },
  apiRecordConentProgress(urldata = {}, data) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().recordContentProgress, data)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },
};
