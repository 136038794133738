import React, { Component } from "react";
import { Link } from "react-router-dom";
import LayoutWrapper from "../../LayoutWrapper";

export class Module extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("Module");
    this.state = {
      data: [],
      modules: [],
      baseroute: "",
    };
  }

  componentDidMount() {
    this.fetchList();
  }

  fetchList() {
    this.props.api.apiGetModulesDataMoke().then((res) => {
      this.setState({
        modules: res,
      });
    });
  }

  handleEdit(e, index) {
    e.preventDefault();
    e.stopPropagation();
    console.log("edit modules", index);
  }

  handleDelete(e, index) {
    e.preventDefault();
    e.stopPropagation();
    console.log("delete modules", index);
  }

  handleToggle(checked) {
    console.log("toggle modules:", checked);
  }

  handleRoute(e, type) {
    e.preventDefault();
    e.stopPropagation();
    console.log("link type:", type);

    let link;
    switch (type) {
      case "notes":
        link = `/lms/courses/module/assessmentspage?course_id=${this.props.urlparams.course_id}`;
        break;

      default:
        link = `/lms/courses/module/videopage?course_id=${this.props.urlparams.course_id}`;
        break;
    }
    this.setState({ baseroute: link });
    this.props.navigate(link);
  }
  getBreadCums() {
    if (this.props.urlparams.course_id) {
      return `Course ${this.props.urlparams.course_id}`;
    } else {
      return "Courses";
    }
  }

  getBackLink() {
    if (this.props.urlparams.course_id) {
      let backLink = `/lms/courses`;
      return backLink;
    } else {
      return "/lms/courses";
    }
  }

  render() {
    let { modules } = this.state;
    return (
      <LayoutWrapper
        title="Course Modules"
        back={this.getBreadCums()}
        backlink={this.getBackLink()}
      >
        <AppAccordian
          data={modules?.map((o, i) => ({
            Id: o.module_id && (() => o.module_id),
            Title: o.title && (() => o.title),
            Status: o.status && (() => o.status),
            Percent: o.percent && (() => o.percent),
            Content: o.description && (() => o.description),
            Links: o.links && (() => o.links),
          }))}
          crudaction={false}
          toggleaction={false}
          statusaction={false}
          targetType="route"
          baseroute={this.state.baseroute}
          getlinktype={(...args) => this.handleRoute(...args)}
        ></AppAccordian>
      </LayoutWrapper>
    );
  }
}

export default connect(Module);
