import React, { Component } from "react";
import { Link } from "react-router-dom";
import LayoutWrapper from "./LayoutWrapper";
import SchoolSearch from "./SchoolSearch";
import ProfileModal from "./ProfileModal";
import SchoolStepper from "./SchoolStepper";
import AlterSchoolManagement from "./AlterSchoolManagement";
import { toast, ToastContainer  } from "react-toastify";

export class SchoolManagement extends Component {
	constructor(props) {
		super(props);
		props.initialconstruct.bind(this)("SchoolManagement");
		this.state = {
			data: [],
			rowcount: 0,
			search: {
				limit: 50,
				page: 1,
				order_by: "updatedAt",
				order_direction: "DESC",
			},
			editData: {},
			viewMode: false,
			editMode: false,
			// popupfields: {},
			// popupdata: {},
			schoolAdmins: [],
			loader: false,
			currentCity: "",
			showSchoolStepper: false,
			showEditData: false,
			schoolStatus: "",
			goToStepper: 0,
			adminNamesList: [],
			coordinatorNamesList: [],
			namesListOf: '',
		};
		this.columns = [
			{
				dataIndex: "school_name",
				title: "School Name",
			},
			{
				dataIndex: "school_admin_count",
				title: "# of Admin",
				render: (text, record) =>
					<a href="#"	className="anchorTag" onClick={() => this.getAdminNames(record, 'admin')}
					>{text}</a>			
			},
			{
				dataIndex: "school_coordinator_count",
				title: "# of Coordinator",
				render: (text, record) =>
					<a href="#"	className="anchorTag" onClick={() => this.getAdminNames(record, 'coordinator')}
					>{text}</a>
			},
			{
				dataIndex: "state",
				title: "State",
			},
			{
				dataIndex: "onboarding_date",
				title: "Onboarding Date",
				render: (text, record) => {
					let date = new Date(text).dateToDDMMYYYY(new Date(text));
					return date;
				},
			},
			{
				dataIndex: "pending_payment",
				title: "Pending Payments",
			},
			{
				dataIndex: "status",
				title: "Status",
				render: (text, record) => {
					// console.log(text, 'text')
					return (
					  <span
						className={`badge light border-0 ${
						  record.status?.toTitleCase() === "Pending" ? "text-primary" : record.status == "DE_ACTIVE" ? "badge-danger" : "badge-success"
						} `}
					  >
						{record.status?.toTitleCase() == "Pending" ? "Pending" : record.status == "DE_ACTIVE" ? "Deactive" : "Active"}
					  </span>
					);
				  },
			},
		];
		this.modalFields = [
			{
				type: "text",
				name: "school_name",
				label: "School Name",
				required: true,
			},
			{
				type: "select",
				name: "admin_name",
				label: "School Admin Name",
				options: [],
				required: true,
			},
			{
				type: "text",
				name: "mobile_number",
				label: "Mobile No.",
				pattern: "[0-9]{10}",
				onInvalid: (e) =>
					e.target.setError(
						"ex. 91-9XXXXXXXXX 10 digit phone number with country code."
					),
				onInput: (e) => e.target.setMessage(),
				required: true,
			},
			{
				type: "text",
				name: "alt_mobile_number",
				label: "Alternative Mobile No.",
				pattern: "[0-9]{10}",
				onInvalid: (e) =>
					e.target.setError(
						"ex. 91-9XXXXXXXXX 10 digit phone number with country code."
					),
				onInput: (e) => e.target.setMessage(),
				required: true,
			},
			{
				type: "date",
				name: "onboarding_date",
				label: "Onboarding Date",
				// disabledDates: (date) => {
				//   return date.getTime() < new Date().toDate().date().getTime();
				// },
				required: true,
			},
			{
				type: "email",
				name: "email",
				label: "Email ID",
				required: true,
			},

			{
				type: "select",
				name: "state",
				label: "State",
				options: Object.keys(statecity).options(),
				onChange: (value) => {
					let city = this.modalFields.find((o) => o.name == "city");
					city.options = Object.assign(
						city.options,
						statecity[value[0].key].options()
					);
				},
				required: true,
			},
			{
				type: "select",
				name: "city",
				label: "City",
				options: [],
				required: true,
			},
			{
				type: "number",
				name: "no_of_students",
				label: "No. of Students",
				max: 10000,
				required: true,
			},
			{
				type: "text",
				name: "sections",
				label: "Section Name",
				required: true,
			},
			{
				type: "text",
				name: "address",
				label: "Address",
				required: true,
			},
		];
		this.schoolModalRef = React.createRef();
		this.role = localStorage.getItem("currentAdminRole");
	}

	componentDidMount() {
		this.fetchList();
		this.getAllAdmins();
		this.props.navigate(
			`/schoolmanagement`
		);
	}

	getAdminNames(record, namesList) {
		// console.log(record.school_id, 'Show Admin list in popup');
		this.openSchoolModal();
		this.props.api.apiGetAdminSchoolById({school_id: record.school_id})
        .then((response) => {
			// console.log("getAdminNames", response.data)
			this.setState({
				adminNamesList: response.data.school_admins.map((item) => item.name),
				coordinatorNamesList: response.data.school_coordinators.map((item) => item.name),
				namesListOf: namesList,
			})
		})
	}

	getAllAdmins() {
		this.props.api.apiGetAllProfile(this.state.search).then(({ data, total }) => {
			console.log({ data })
			let newArr = []
			data.map(obj => {
				newArr.push({ key: obj.profile_id, label: obj.name })
			});
			this.setState({ schoolAdmins: newArr });
		})
			.catch((err) => {
				this.setState({
					loader: false
				})
			});;
		// schoolAdmins = schoolAdmins.map((item) => {
		// 	return Object.only(item, ["key", "label"]);
		// });
	}

	addModalFields() {
		let fields = (this.modalFields || []).reduce(
			(o, n) => ({ ...o, [n.name]: n }),
			{}
		);
		let formdata = Object.map(fields, (v, k) => "");
		// console.log("formdata: ", fields, formdata);
		this.setState({
			popupfields: fields,
			popupdata: formdata,
		});
	}

	addSchoolInfo() {
		this.setState({
			showSchoolStepper: true,
		})
	}

	showDataToEdit = (value) => {
		this.setState({
			showEditData: true,
		}, this.fetchList(value))
	}

	fetchList = (value) => {
		this.setState({ loader: true })
		// const school_id = localStorage.getItem("school_id");
	  	if (this.role === "SCHOOL_ADMIN" || this.state.showEditData){
		let school_id = this.state.showEditData ? value || localStorage.getItem("school_id") : localStorage.getItem("school_admin_id");
        this.props.api.apiGetAdminSchoolById({school_id: school_id})
        .then((response) => {
            console.log({apiGetAdminSchoolById: response.data});
                this.setState({
                  editData: response.data,
				  loader: false,
				  schoolStatus: response.data.status,
                });
        }).catch((error) => {
            console.log('error', error);
			this.setState({ loader: false })
        });
		} else {
			this.props.api.apiGetAllSchools(this.state.search).then((res) => {
				if(res.data[0].records.length == 0) {
					this.setState({
						loader: false,
						data: [],
					})
				} else {
				this.setState({
					data: res.data[0].records,
					rowcount: res.data[0].pagination[0].totalRecords,
					loader: false,
				});
				}
			}).catch((error) => {
				console.log({error: error});
				this.setState({
					loader: false,
				})
			});
		}
	}

	downloadFile() { }

	onNext() { }

	onPrev() { }

	onPageChange(pageData) {
		this.setState(
			{
				search: {
					...this.state.search,
					page: pageData.current,
					limit: pageData.pageSize || pageData.defaultPageSize || 25,
				},
			},
			() => this.fetchList()
		);
	}

	deleteAction(value) {
		confirm(`Do you really want to delete these school?`).promise.then(() => {
			let payload = {
				is_active: false,
			}
			this.props.api
		  .apiEditGenericInfo(payload, { school_id: value.school_id })
		  .then((res) => {
			this.fetchList()
			this.setState({
			  loader: false,
			});
		  })
		  .catch((err) => {
			this.setState({
			  loader: false,
			});
		  });
		});
	}

	editAction(v) {
		// console.log("edit: ", v);
		this.props.navigate(
			`/schoolmanagement?school_id=${v.school_id}&stepper_id=${v.configuration_step+1}`
		)
		if(v.status == "PENDING") {
		this.setState({
			showSchoolStepper: true,
			schoolStatus: v.status,
			goToStepper: v.configuration_step,
		})
		}
		this.setState({
			editMode: true,
			viewMode: false,
			editData: v,
			currentCity: v.city,
		});
	}

	viewAction(v) {
		// console.log("view: ", v);
		this.props.navigate(
			`/schoolmanagement?school_id=${v.school_id}&stepper_id=${v.configuration_step}`
		)
		this.setState({
			viewMode: true,
			editData: v,
		})
		let city = this.modalFields.find((o) => o.name == "city");
		city.options = Object.assign(city.options, statecity[v.state].options());
	}

	deactiveAction(v){
		let payload = {
			status: v.status === "DE_ACTIVE" ? "ACTIVE" : "DE_ACTIVE",
		}
		this.props.api
      .apiEditGenericInfo(payload, { school_id: v.school_id })
      .then((res) => {
		this.fetchList()
        this.setState({
          loader: false,
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
        });
      });
	}

	isDeactivated(record) {
		return record.status == "ACTIVE" ? "Activate" : "Deactivate";
	}

	onSearch(data) {
		console.log("onSearch data: ", data);
		this.setState({ search: data }, () => {
			this.fetchList();
		});
	}

	openSchoolModal() {
		this.schoolModalRef.current.click();
	}
	closeModal() {
		document.querySelector("#add_school_modal #bsmodalclose").click();
	}

	handleSubmit(event, data, form) {
		event.preventDefault();
		// console.log("formData", data, form);
		this.setState({
			loader: true,
		})
		const resolveList = () => {
			success(
				`${this.state.editMode
					? "School Updated successfully"
					: "School added successfully"
				}`
			);
			form.reset();
			this.closeModal();
			this.fetchList();
		};
		if (this.state.editMode) {
			this.props.api
				.apiEditSchool(data, { school_id: this.state.editData.school_id })
				.then(resolveList)
				.then(() => {
					this.setState({
						loader: false
					})
				}).catch((error) => {
					console.error("Error:", error);
					this.setState({
						loader: false
					});
				});
		} else {
			let payload = {
				...data,
			};
			this.props.api.apiAddSchool(payload).then(resolveList).then(() => {
				this.setState({
					loader: false
				})
			}).catch((error) => {
				console.error("Error:", error);
				this.setState({
					loader: false
				});
			});
		}
	}

	updateFields(fields) {
		fields.find((o) => o.name === "admin_name").options =
			this.state.schoolAdmins;
		return fields;
	}
	
	render() {
		let {
			data = [],
			rowcount,
			editData,
			// popupdata,
			// popupfields,
			editMode,
			viewMode,
			loader,
			showSchoolStepper,
			schoolStatus,
			goToStepper,
		} = this.state;
		let { columns, modalFields } = this;
		modalFields = this.updateFields(modalFields);

		return (
			<>
				{loader && <div className="loader-container">
					<div className="spinner"></div>
				</div>}
				<ToastContainer position="top-right" />
				{(viewMode || editMode && schoolStatus != "PENDING" || this.role == "SCHOOL_ADMIN") ?
				<AlterSchoolManagement
					viewMode={viewMode}
					editMode={editMode}
					editData={editData}
					showDataToEdit={this.showDataToEdit}
					goToStepper={goToStepper}
				/>
				: (showSchoolStepper ?
				<SchoolStepper
					viewMode={viewMode}
					editMode={editMode}
					editData={editData}
					showDataToEdit={this.showDataToEdit}
					schoolStatus={schoolStatus}
					goToStepper={goToStepper}
				/>
				: <LayoutWrapper
					title="School Management"
					back="School Management"
				>
				<div>
					<div className="row">
						<div className="col"></div>
						{localStorage.getItem("currentAdminRole") == "SUPER_ADMIN" && (
							<div className="mb-4">
								<Link
									className="btn add-btn"
									onClick={() => this.addSchoolInfo()}
								>
									<i className="fa fa-plus"></i>
									Add School
								</Link>
							</div>
						)}
					</div>
					<div className="row mb-3">
						{/* <div className="col-sm-6 col-md-3">
							<div class="form-group relative form-focus">

								<input type="text" value={this.state.currentCity} className="form-control" id="exampleInput" placeholder="Enter text" />
								<label htmlFor="exampleInput" className="focus-label">School Name</label>
							</div>
						</div> */}
						<div className="col">
							<SchoolSearch
								inactive={() => {
									console.log(
										this.props.inactive
									)
									return this.props.inactive
								}}
								onSearch={(...arg) => this.onSearch(...arg)}
								currentCity={this.state.currentCity}
							/>
						</div>
					</div>
					<div className="row schoolmanagement">
						<div className="col-12">
							{!!data.length && (
								<AppTable
									data={data}
									columns={columns}
									onNext={() => this.onNext()}
									onPrev={() => this.onPrev()}
									onChange={(...arg) => this.onPageChange(...arg)}
									total={rowcount}
									reorder={true}
									deleteAction={(v) => this.deleteAction(v)}
									editAction={(v) => this.editAction(v)}
									viewAction={(v) => this.viewAction(v)}
									deactiveAction={(v) => this.deactiveAction(v)}
									isDeactivated={(v) => this.isDeactivated(v)}
									deactivatable={true}
									deletable={false}
									targetType="tap"
									isresponsive={false}
								></AppTable>
							)}
							{data.length == 0 && <div className="empty_layout"></div>}
						</div>
					</div>
					</div>
					<ProfileModal
						id="add_school_modal"
						title={`${this.state.namesListOf == 'admin' ? "# of Admin" : "# of Coordinator"}`}
						data={this.state.namesListOf == 'admin' ? this.state.adminNamesList : this.state.coordinatorNamesList}
						contentClass="smallModalBox"
					/>
				</LayoutWrapper>
				)}
				{/* below button hidden on UI */}
                <button
                    className="btn btn-sm btn-outline-secondary d-none"
                    onClick={(...args) => this.addModalFields(...args)}
                    ref={this.schoolModalRef}
                    data-bs-toggle="modal"
                    data-bs-target="#add_school_modal"
                >
                    <span>Hidden Button</span>
                </button>
			</>
		);
	}
}

export default connect(SchoolManagement);
