export default {
    apiGetProfileBasicDetailsMoke() {
        let data = {
            "name": "Ashish Kumar",
            "employeeId": "23424234",
            "gender": "male",
            "dateOfBirth": "1988-10-23",
            "age": "31",
            "mobileNo": "9989786866",
            "dateOfJoining": "2022-10-12",
            "workLocation": "hyderabad",
            "designation": "employee",
            "panNo": "CBGPP2598C",
            "salaryType": "savings",
            "emailId": "email@gmail.com",
            "address": "Some Demo address",
        };
        return this.mokeapi(() => (data), "Getting all employee profile basic details please wait...", 2000);
    },
    apiGetProfileBasicDetails(data = {}) {
        //return this.apiGetProfileBasicDetailsMoke();
        console.log("apiGetProfileBasicDetails", data);
        let fields = ['name', 'employeeId', 'gender', 'dateOfBirth', 'age', 'mobileNo', 'dateOfJoining', 'workLocation', 'designation', 'panNo', 'salaryType', 'emailId', 'address'];
        return this.request(this.api().employeeInfo, data)
            .urltransform(mixins.params())
            .exec().log().mapresults((data) => ({
                //...Object.only(data,fields),
                "name": data.name,
                "employeeId": data.employee_id,
                "gender": data.gender,
                "dateOfBirth": data.date_of_birth,
                "age": data.age,
                "mobileNo": data.mobile_number,
                "dateOfJoining": data.date_of_joining,
                "workLocation": data.work_location,
                "designation": data.designation,
                "panNo": data.pan_no,
                "salaryType": data.salary_type,
                "emailId": data.email,
                "address": data.address,
                "profile_picture": data?.profile_picture,
                "profile_picture_key": data?.profile_picture_key || ''
            })).get();
    },
    apiGetProfileAccountDetailsMoke() {
        let data = {
            "holderName": "Ashis Kumar",
            "accountNo": "3434234242",
            "bankName": "City Bank",
            "branchName": "Vinobhanagar",
            "ifsc_code": "ABCD00042"
        };
        return this.mokeapi(() => (data), "Getting all employee profile basic details please wait...", 2000);
    },
    apiGetProfileAccountDetails(data = {}) {
        //return this.apiGetProfileAccountDetailsMoke();
        return this.request(this.api().employeeBankInfo, data)
            .urltransform(mixins.params())
            .exec().log().mapresults((data) => ({
                "holderName": (data || {}).account_name,
                "accountNo": (data || {}).account_number,
                "bankName": (data || {}).bank_name,
                "branchName": (data || {}).branch,
                "ifsc_code": (data || {}).ifsc_code,
            })).get();
    },
    apiGetProfileSalaryDetailsMoke() {
        let data = {
            "ctc": 600000,
            "fixed": 30000,
            "variable": 40000,
            "monthlySalary": 60000,
            "basicSalary": 600000,
            "conyavance": 30000,
            "others": 30000,
            "hra": 600000,
            "otherAllowances": 30000,
            "totalEarnings": 40000,
            "pf": 60000,
            "tds": 40000,
            "professionalTax": 20000,
            "totalDeductions": 30000
        };
        return this.mokeapi(() => (data), "Getting all employee profile salary details please wait...", 2000);
    },
    apiGetProfileSalaryDetails(data = {}) {
        //return this.apiGetProfileSalaryDetailsMoke();
        return this.request(this.api().employeeSalaryInfo, data)
            .urltransform(mixins.params())
            .exec().log().mapresults((data) => ({
                "ctc": data.annual_ctc,
                "fixed": data.fixed,
                "variable": data.variable,
                "monthlySalary": data.monthly_salary,
                "basicSalary": data.basic_salary,
                "conveyance": data.conveyance,
                "others": 0,
                "hra": data.hra,
                "otherAllowances": data.other_allowance,
                "totalEarnings": data.total_earnings,
                "pf": data.pf,
                "tds": data.tds,
                "professionalTax": data.professional_tax,
                "totalDeductions": data.total_deductions
            })).get();

    },
    apiGetProfileStatutaryDetailsMoke() {
        let data = {
            "pfNo": "242424",
            "esiNo": "34343442",
            "ptNo": "HDFC876989",
        };
        return this.mokeapi(() => (data), "Getting all employee profile statutory details please wait...", 2000);
    },
    apiGetProfileStatutaryDetails(data = {}) {
        //return this.apiGetProfileStatutaryDetailsMoke();
        return this.request(this.api().employeeInfo, data)
            .urltransform(mixins.params())
            .exec().log().mapresults((data) => {
                let payload = {
                    "pfNo": data.pf_number,
                    "esiNo": data.esi_number,
                    "ptNo": data.pt_number,
                };
                return payload;
            }).get();
    },
    apiSoftDeleteEmployee(data = {}, urldata = {}) {
        return this.request(this.api().deleteSoftEmployee, data)
            .urltransform({ ...mixins.params(), ...urldata })
            .exec("Temporarily De-activating employee please wait..").log().get();
    },
    // apiDeleteEmployee(data = {}) {
    //     return this.request(this.api().deleteEmployee, data)
    //         .urltransform(data)
    //         .exec("De-activating employee please wait..").log().get();
    // },
    apiGetProfileDocsDetailsMoke() {
        let data = {
            "panCard": "https://www.w3schools.com/images/myw3schoolsimage.jpg",
            "aadharCard": "https://www.w3schools.com/images/myw3schoolsimage.jpg",
            "passportCard": "https://www.w3schools.com/images/myw3schoolsimage.jpg",
        };
        return this.mokeapi(() => (data), "Getting all employee profile statutory details please wait...", 2000);
    },
    apiGetProfileDocsDetails(data = {}) {
        return this.request(this.api().employeeDocList, data)
            .urltransform(mixins.params())
            .exec().log().mapresults((data) => (data)).get();
    },
    apiUploadDocument(data = {}) {
        return this.request(this.api().uploadDocuments, data)
            .urltransform(mixins.params())
            .multipart()
            .exec("Uploading please wait...").log().mapresults((data) => (data)).get();
    },

    apiUpdateEmployeeProfileBasic(data) {
        console.log({ data });
        return this.request(this.api().updateEmployeeProfile, data)
            .urltransform(mixins.params())
            .transform((post) => ({
                //"role": "EMPLOYEE",
                "name": post.name,
                "employee_id": post.employeeId,
                "gender": post.gender,
                "date_of_birth": post.dateOfBirth,
                "mobile_number": post.mobileNo,
                "date_of_joining": post.dateOfJoining,
                "work_location": post.workLocation,
                "designation": post.designation,
                "department": post.department,
                "pan_no": post.panNo,
                "salary_type": post.salaryType,
                //"blood_group": "O +ve",
                "address": post.address,
                //"device_id": "device id unique"
            }))
            .exec().log().mapresults((data) => (data)).get();
    },

    apiUploadProfilePic(data = {}) {
        return this.request(this.api().updateProfilePic, data)
            .urltransform(mixins.params())
            .multipart()
            .exec("Uploading please wait...")
            .log().get();
    },
    apiUpdateEmployeeProfileAccount(data) {
        return this.request(this.api().updateEmployeeAccountInfo, data)
            .urltransform(mixins.params())
            .transform((post) => ({
                "account_name": post.holderName,
                "account_number": post.accountNo,
                "bank_name": post.bankName,
                "branch": post.branchName,
                "ifsc_code": post.ifsc_code,
            }))
            .exec().log().get();
    },

    apiUpdateEmployeeProfilePatchAccount(data) {
        return this.request(this.api().updateEmployeeAccountInfoPatch, data)
            .urltransform(mixins.params())
            .transform((post) => ({
                "account_name": post.holderName,
                "account_number": post.accountNo,
                "bank_name": post.bankName,
                "branch": post.branchName,
                "ifsc_code": post.ifsc_code,
            }))
            .exec().log().get();
    },

    apiUpdateEmployeeProfileSalary(data) {
        return this.request(this.api().updateEmployeeSalaryInfo, data)
            .urltransform(mixins.params())
            .transform((post) => ({
                "annual_ctc": post.ctc,
                "fixed": post.fixed,
                "variable": post.variable,
                "monthly_salary": post.monthlySalary,
                "basic_salary": post.basicSalary,
                "conveyance": post.conyavance,
                "hra": post.hra,
                "other_allowance": post.otherAllowances,
                //"total_earnings": post.totalEarnings,
                "pf": post.pf,
                "tds": post.tds,
                "professional_tax": post.totalDeductions,
                "others": post.others,
                "applicable_from": (new Date()).toMonthDate()
            }))
            .exec().log().get();
    },

    apiUpdateEmployeeProfilePatchSalary(data) {
        return this.request(this.api().updateEmployeeSalaryInfoPatch, data)
            .urltransform(mixins.params())
            .transform((post) => ({
                "annual_ctc": post.ctc,
                "fixed": post.fixed,
                "applicable_from": (new Date()).toMonthDate()
            }))
            .exec().log().get();
    },

    apiDownloadFile(row) {
        let apibase = this.api().api_base;
        let downloadurl = this.api().downloadFile.url;
        let auth = app.props.store.auth;
        return `${apibase}${downloadurl}?access_token=${auth}&fileKey=${row.file_key}`;
    },

    apiProfileFile(row) {
        let profileurl = row.profile_picture;
        let auth = app.props.store.auth;
        return `${profileurl}?access_token=${auth}`;
    },

    apiDeleteFile(data = {}, urldata = {}) {
        return this.request(this.api().removeEmployeeDocuments, data)
            .urltransform({ ...mixins.params(), ...urldata })
            .multipart().exec().log().get();
    },

    apiUpdateEmployeeProfileStatutary(data) {
        return this.request(this.api().updateEmployeeProfile, data)
            .urltransform(mixins.params())
            .transform((post) => {
                let payload = {
                    "pf": post.pfNo,
                    "esi_number": post.esiNo,
                    "professional_tax": post.ptNo
                };
                return Object.filter(payload, v => !!v)
            })
            .exec().log().mapresults((data) => (data)).get();
    },
}