
import React, {Component} from 'react';

export class AddEventModal extends Component {
  constructor(props){
    super(props);
  }

  componentDidMount(){
    window.AddEventModal = this;
  }

  render(){
    let {props} = this;
    let {id, title, onClose, submitButton, closeButton} = props;
    let attrs = Object.except(props, ["id", "title", "type", "onClose"]);

    return (
      <AppModalBs id={id} title={title} onClose={onClose} submitButton={submitButton} closeButton={closeButton}>
        <AppFormData {...attrs} onClose={onClose}></AppFormData>
      </AppModalBs>
    )
  }
}

export default AddEventModal;