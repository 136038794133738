import React, { Component } from "react";

export class EmployeeModal extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.EmployeeModal = this;
  }
  render() {
    let { props } = this;
    let { id, title, onClose, submitButton, closeButton } = props;
    let attrs = Object.except(props, ["id", "title", "type", "onClose"]);
    let city = attrs.data.city;
    delete attrs.data.city;

    let newAttrsData = {
      ...attrs.data,
      city: city
    }

    attrs.data = newAttrsData;
    console.log(attrs)
    // debugger;

    return (
      <AppModalBs id={id} title={title} onClose={onClose}>
        <AppFormData {...attrs} onClose={onClose} submitButton={submitButton} closeButton={closeButton}></AppFormData>
      </AppModalBs>
    );
  }
}

export default EmployeeModal;
