import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

class ForgotPassword extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Forgot Password - CognoSpace</title>
          <meta name="description" content="Login page" />
        </Helmet>
        <div className="account-content">
          <div className="container">
            <div className="account-box">
              <div className="account-wrapper">
                {/* Account Logo */}
                <div className="account-logo">
                  <Link to={"/open/login"}>
                    <img src={imagepaths.Applogo} alt="" />
                  </Link>
                </div>
                {/* /Account Logo */}
                <h3 className="account-title">Forgot Password?</h3>
                <p className="text-dark text-center">
                  Please enter registered email ID
                </p>
                {/* Account Form */}
                <form className="my-4">
                  <div className="form-group">
                    <label>Email Address</label>
                    <input className="form-control" type="text" />
                  </div>
                  <div className="form-group text-center">
                    <button
                      className="btn btn-primary account-btn"
                      type="submit"
                    >
                      Forgot Password
                    </button>
                  </div>
                  <div className="account-footer d-none">
                    <p className="text-dark">
                      Remember your password?{" "}
                      <Link to="/open/login">Login</Link>
                    </p>
                  </div>
                </form>
                {/* /Account Form */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(ForgotPassword);
