import React, { Component } from "react";

export class AssessmentModal extends Component {
  render() {
    let { props } = this;
    let { id, title, onClose, size = 2, submitText = "SUBMIT" } = props;
    let attrs = Object.except(props, ["id", "title", "type", "onClose"]);
    this.formFields = [
      {
        type: "text",
        name: "assessment_name",
        label: "Assessment Name",
        required: true,
      },
    ];
    return (
      <AppModalBs
        id={id}
        title={title}
        contentClass={"m-auto"}
        onClose={onClose}
      >
        <AppForm
          className="flex flex-col gap-4 bg-white p-4"
          onSubmit={(...e) => (this.props?.onSubmit || (() => ""))(...e)}
        >
          <>
            <Row className="mx-0 mb-3">
              {this.formFields.map((field, i) => (
                <Col
                  className={field.fieldclass ? field.fieldclass : "col-12"}
                  key={i}
                >
                  <AppInput {...field}></AppInput>
                </Col>
              ))}
            </Row>
          </>
          <Row className="pt-4">
            <Col className="d-flex">
              <button
                type="button"
                className="btn btn_modal_grey btn-block ml-auto px-4"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-success btn-block ms-2 px-4"
              >
                Save
              </button>
            </Col>
          </Row>
        </AppForm>
      </AppModalBs>
    );
  }
}

export default AssessmentModal;
