import React, { Component } from "react";

export class PhotoModal extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.ClassModal = this;
  }
  render() {
    let { props } = this;
    let { id, title, onClose, data } = props;
    let attrs = Object.except(props, ["id", "title", "type", "onClose"]);
    return (
      <AppModalBs id={id} title={title} onClose={onClose} {...props}>
        <div>
          {data?.attach_file_type === "VIDEO" ? (
            <div style={{ height: "50vh" }}>
              <iframe
                src={data?.attach_file}
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              ></iframe>
            </div>
          ) : (
            <div className="text-center">
              <img src={data?.src} alt="Image" />
            </div>
          )}
        </div>
        {props.children}
      </AppModalBs>
    );
  }
}

export default PhotoModal;
