import React, { Component } from "react";
import DeviceModal from "./DeviceModal";
import LayoutWrapper from "./LayoutWrapper";
import { Link } from "react-router-dom";
import { toast, ToastContainer  } from "react-toastify";

export class DeviceConfiguration extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("SubModules");
    this.deviceModalRef = React.createRef();
    this.state = {
      tableData: [],
      rowcount: 0,
      search: {
        limit: 50,
        page: 1,
        order_by: "updatedAt",
        order_direction: "DESC",
      },
      editData: {},
      viewMode: false,
      editMode: false,
      popupfields: {},
      popupdata: {},
      loader: false,
      modalFields: [
        {
          type: "select",
          name: "school_id",
          label: "School Name",
          options: [],
          placeholder: "Select School",
          required: true,
          customcolspan: "col-12",
        },
        {
          type: "text",
          name: "device_id",
          label: "Device ID",
          required: true,
          customcolspan: "col-12"
        },
        {
          type: "text",
          name: "device_name",
          label: "Device Name",
          required: true,
          customcolspan: "col-12"
        }
      ],
      columns: [
        {
          dataIndex: "device_id",
          title: "Device Id",
        },
        {
          dataIndex: "school_name",
          title: "School Name",
        },
        {
          dataIndex: "device_name",
          title: "Device Name",
        },
      ]
    };
  }

  componentDidMount() {
    this.fetchDevices(); // to fetch table data
    this.getSchoolNames();
  }

  // addModalFields() {
  //   let fields = (this.state.modalFields || []).reduce(
  //     (o, n) => ({ ...o, [n.name]: n }),
  //     {}
  //   );
  //   let formdata = Object.map(fields, (v, k) => "");
  //   this.setState({
  //     popupfields: fields,
  //     popupdata: formdata,
  //   });
  //   }

  fetchDevices() {
    this.setState({ loader: true })
    let payload = {
      ...this.state.search
    }
    this.props.api.apiGetDevice(payload).then((res) => {
      console.log({ apiGetDevice: res.data[0]?.records });
      if (res.data && res.data[0].records.length !== 0) {
        this.setState({
          tableData: res.data[0].records,
          rowcount: res.data[0].pagination[0].totalRecords,
          loader: false,
        });
      }
    }).catch(() => {
      this.setState({ loader: false })
    })
  }

    getSchoolNames() {
        this.setState({ loader: true });
        let payload = {
            status: "ACTIVE",
        };
        this.props.api
            .apiGetAllSchools(payload)
            .then(({ data, total }) => {
                let classNames = this.state.modalFields.find(
                    (o) => o.name == "school_id"
                );
                classNames.options = data[0].records?.options(
                    "school_id",
                    "school_name"
                );
                this.setState({
                    modalFields: [...this.state.modalFields],
                    loader: false,
                });
            })
            .catch((error) => {
                this.setState({
                    loader: false,
                });
            });
    }

  onNext() {}

  onPrev() {}

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      () => this.fetchDevices()
    );
  }

  // deleteAction(value) {
  //   let payload = {
  //     device_id: value.device_id,
  //   };

  //   confirm(`Are you sure want to delete this Device ?`).promise.then(() => {
  //     this.api.apiDeleteDevice(payload).then(() => {
  //       this.fetchDevices();
  //     });
  //   });
  // }


  onSearch() {
    let data = this.refs.form.form.current.getData();
    data = Object.filter(data, (val) => val);
    this.setState({ search: data }, () => {
      this.fetchDevices();
    });
  }

  clearTextData() {
    this.refs.form.form.current.reset();
  }

  openModal() {
    this.deviceModalRef.current.click();
  }

  closeModal() {
    document.querySelector("#device_modal #bsmodalclose").click();
  }

  toastConfig(text, message) {
    const options = {
      autoClose: 3000,
      closeButton: false,
      hideProgressBar: true,
    };
  
    if (message === "success") {
      toast.success(text, options);
    } else {
      toast.error(text, options);
    }
  } 

  handleSubmit(event, data, form) {
    event.preventDefault();
    // console.log("formData", data, form);
    this.setState({ loader: true })
    if (this.state.editMode) {
      let payload = {
        school_id: data.school_id,
        device_id: data.device_id,
        device_name: data.device_name,
      }
        this.props.api.apiEditDeviceById(payload, { device_id: data.device_id })
          .then(() => {
            this.setState({
              loader: false,
            });
            this.closeModal();
            this.fetchDevices();
            this.toastConfig("Updated device suceessfully!!", "success");
          }).catch((error) => {
            this.setState({
              loader: false,
            });
            this.toastConfig("Failed to update device!!", "error");
          });
    } else {
      let payload = {
        ...data,
      };
      this.props.api.apiAddDevice(payload).then((response) => {
        console.log({apiAddDevice: response});
        this.setState({
          loader: false,
        });
        this.closeModal();
        this.fetchDevices();
        this.toastConfig("Added device suceessfully!!", "success");
      }).catch((error) => {
        this.setState({
          loader: false,
        });
        this.toastConfig("Unable to add device!!", "error");
      });
    }
  }

  updateFields(fields) {
    return fields;
  }

  render() {
    let {
      tableData = [],
      rowcount,
      editData,
      popupdata,
      popupfields,
      editMode,
      viewMode,
      loader,
      columns,
      modalFields,
    } = this.state;
    modalFields = this.updateFields(modalFields);
    return (
      <>
        {loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <ToastContainer position="top-right" />
        <LayoutWrapper title="Device Configuration" back="Device Configuration">
          <div className="row">
            <div className="col-12 device-table">
              {!!tableData.length && (
                <AppTable
                  data={tableData}
                  columns={columns}
                  onNext={() => this.onNext()}
                  onPrev={() => this.onPrev()}
                  onChange={(...arg) => this.onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  viewable={false}
                  editable={false}
                  deletable={false}
                  targetType="tap"
                ></AppTable>
              )}
              {tableData.length == 0 && <div className="device_layout"></div>}
            </div>
          </div>
          <DeviceModal
            id="device_modal"
            title={`${editMode ? "Edit Device Details" : viewMode ? "View Device Details" : "Add Device"}`}
            submitText={`${editMode ? 'Update' : 'Submit'}`}
            editdata={editData}
            data={popupdata}
            fields={popupfields}
            contentClass="subModuleModal"
            disableallfields={viewMode ? true : false}
            onSubmit={(...args) => this.handleSubmit(...args)}
            submitButton={viewMode ? false : true}
            closeButton={viewMode ? true : false}
            onClose={() =>
              this.setState({
                popupdata: {},
                editData: {},
                editMode: false,
                viewMode: false,
              })
            }
          />
        </LayoutWrapper>
      </>
    );
  }
}

export default connect(DeviceConfiguration);
