/*eslint no-extend-native: ["error", { "exceptions": ["String","Array","Number"] }]*/
String.prototype.url = function () {
    try {
        let str = this.includes("http") ? this : (window.location.origin + this);
        let url = new URL(str);
        let splits = url.hash.split("?");
        if(splits.length===2) {
            url.hash = splits[1];
        }
        url.search += splits[1]||'';
        url.params = Object.fromEntries(url.searchParams.entries());
        return url;
    } catch (e) {
        return this;
    }
}
String.prototype.cap2space = function() {
    return this.split(' ').join('').split('').map((ch,i)=>{
        if('A'.charCodeAt()<=ch.charCodeAt() && ch.charCodeAt()<='Z'.charCodeAt() && i!=0) { return ' '+ch; }
        else { return ch; }
    }).join('');
}
Array.prototype.splitBy = function(delimit=" ") {
    return this.map(val=>val.split(delimit)).flat();
}
String.prototype.splitBy = function(delimit=" ") {
    return [this].splitBy(delimit);
}
String.prototype.splitByArr = function(delimiters=[' ','_','-']) {
    let arr = [this];
    delimiters.map((delimit)=>{
        arr = arr.splitBy(delimit);
        return false;
    })
    arr = arr.filter(o=>!delimiters.includes(o));
    return arr;
}

String.prototype.spaceCase = function() {
    return this.cap2space().splitByArr().join(' ');
}

String.prototype.snakeCase = function() {
    return this.cap2space().splitByArr().join('_').toLowerCase();
}

String.prototype.camelCase = function() {
    return this.cap2space().splitByArr().map((word,i)=>{
        return i===0?word.toLowerCase():word[0].toUpperCase()+word.slice(1).toLowerCase();
    }).join('').toLowerCase();
}

String.prototype.toTitleCase = function() {
    return this.splitByArr().map(word=>{
        return (word[0]||"").toUpperCase()+word.slice(1).toLowerCase();
    }).join(' ');
}

String.prototype.emphasis = function(len=13){
    let res = this.substr(0,len);
    return (res.length<=(len-3))?res:(res.substr(0,len-3)+"...");
}

String.prototype.cur = Number.prototype.cur = function(deci=2,country="INR",format="en-IN"){
    let formater = new Intl.NumberFormat(format, {
        style: 'currency',
        currency: country,
    });
    let cur =  formater.format(this.parse().rounded(deci));
    return deci==0?cur.split('.')[0]:cur;
}

String.prototype.rounded = Number.prototype.rounded = function(deci=0){
    return this.parse().toFixed(deci).parse();
}

String.prototype.parse = Number.prototype.parse = function() {
    try{ return JSON.parse(this)}catch(e){ 
        return Number(this);
    }
}

String.prototype.removeExt = function() {
    return this.split('.')[0];
}

String.prototype.basename = function(sep='/',index=1) {
    return (this.split(sep)[index]||this).removeExt();
}
String.prototype.dotname = function(sep=".") {
    return this.split('/').filter(v=>v).join(sep);
}
String.prototype.posiblepath = function(sep='/',join=".") {
    return this.split(sep).reduce((col,key)=>{
        let prev = col[col.length-1]||"";
        let attach = prev?`${prev}${join}`:prev;
        let newkey = attach+key;
        newkey && col.push(newkey);
        return col;
    },[]);
}
String.prototype.file = function(sep='/') {
    return this.substring(this.lastIndexOf(sep)+1);
}

String.prototype.filename = function(sep='/') {
    return this.file(sep).removeExt();
}

String.prototype.env = function() {
    return atob(this).parse();
}

String.prototype.decode = function() { try { return atob(this); } catch(e) { return ''} }

String.prototype.encode = function() { try { return btoa(this); } catch(e) { return ''} }

String.prototype.access = function(token=window?.a824?.env()?.STATIC_TOKEN){
    return this+((token&&"?"+("access".encode())+"="+token.encode())||'');
}

String.prototype.trimLeft = function(charlist=`\\s`) {
    return this.replace(new RegExp("^[" + charlist + "]+"), "");
};

String.prototype.trimRight = function(charlist="\\s") {
    return this.replace(new RegExp("[" + charlist + "]+$"), "");
};

String.prototype.trim = function(charlist=" ") {
    return this.trimLeft(charlist).trimRight(charlist);
};

String.prototype.pathpluck = function(index=1) {
    return this.split("/")[index]||this.basename();
}

String.prototype.dowload = function (fileName = "download") {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    let blob = new Blob([this], { type: "octet/stream" }),
        url = window.URL.createObjectURL(blob);
    a.id = "download";
    a.href = url;
    a.target = "_blank";
    a.download = fileName;
    console.log("a", a);
    a.click();
    a.remove()
    window.URL.revokeObjectURL(url);
}