import React, { Component } from "react";
export class SchoolSearch extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      fields: [
        {
          name: "school_name",
          // label: "School Name",
          type: "select",
          className: "status-dropdown",
          placeholder: "School Name",
          options: [],
        },
        {
          name: "onboarding_date",
          label: "Onboarding Date",
          type: "date",
        },
        {
          name: "status",
          type: "select",
          className: "status-dropdown",
          placeholder: "Status",
          options: _enum.SCHOOL_STATUS,
        },
      ],
    };
  }

  getSchoolNames = () => {
    this.props.api
      .apiGetAllSchoolListSuperAdmin()
      .then(({ data, total }) => {
        let schoolNames = this.state.fields.find(
          (o) => o.name == "school_name"
        );
        schoolNames.options = data?.options("school_name");
        this.setState({
          ...this.state.fields,
        });
      })
      .catch(() => {});
  };

  onSearch() {
    let data = this.refs.form.form.current.getData();
    data = {
      ...data,
      city: this.props.currentCity,
    };
    // console.log("onSearch formData", data);
    data = Object.filter(data, (val) => val);
    // console.log("data on search", data);
    this.props.onSearch && this.props.onSearch(data);
  }

  clearTextData = () => {
    this.setState({
      fields: this.state.fields.map(field => ({...field, value: '' }))
    });
        let data = this.refs.form.form.current.getData();
    data = Object.filter(data, (val) => "");
    this.props.onSearch && this.props.onSearch(data);
  };

  handleInputChange = (i, event) => {
    const newFields = [...this.state.fields];
    if (newFields[i].type != "select") newFields[i].value = event.target.value;
    this.setState({ fields: newFields });
  };

  componentDidMount() {
    console.log("school search");
    this.getSchoolNames();
  }

  render() {
    let { fields } = this.state;

    return (
      <AppForm className="row filter-row" ref="form">
        {fields.map((field, i) => (
          <div className="col-sm-6 col-md-3" key={i}>
            <AppInputFocus
              {...field}
              onChange={(event) => this.handleInputChange(i, event)}
            ></AppInputFocus>
          </div>
        ))}
        <div className="col-sm-6 col-md-3">
          <button
            className="btn btn-success btn-block w-100"
            onClick={() => this.onSearch()}
          >
            Search
          </button>
        </div>
        <span className="clear-all" onClick={() => this.clearTextData()}>
          Clear Filter
        </span>
      </AppForm>
    );
  }
}

export default connect(SchoolSearch);
