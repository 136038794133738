import { useEffect, useState } from "react";
import moment from "moment";
import { Table } from "antd";
import _enum from "src/mixins/enum";

const Financials = (props) => {
      const currentYear = new Date().getFullYear();
      const [date, setDate] = useState({});
      const [current, setCurrent] = useState({});
      const [loader, setLoader] = useState(false);
      const [tableData, setTableData] = useState([]);
      const [selectYear, setSelectYear] = useState(currentYear);
      const [search, setSearch] = useState({
        limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },)

        let columns = [
            {
              title: "Months",
              dataIndex: "month",
              render: (record) => {
                let monthMapping = {
                  1 : "January",
                  2 : "February",
                  3 : "March",
                  4 : "April",
                  5 : "May",
                  6 : "June",
                  7 : "July",
                  8 : "August",
                  9 : "September",
                  10 : "October",
                  11 : "November",
                  12 : "December"
              };
              if (record && typeof record === 'number' && monthMapping.hasOwnProperty(record)) {
                const monthName = monthMapping[record]
                return monthName;
            }
            }
            },
            {
              title: "Invoice",
              children: [
                {
                  title: "Invoice No.",
                  dataIndex: "invoice_disp_id",
                },
                {
                  title: "Invoice Date",
                  dataIndex: "invoice_date",
                  render: (text, record) => {
                    if(text != "N/A"){
                      let date = new Date(text).dateToDDMMYYYY(new Date(text));
                      return date;
                    } else {
                      return text;
                    }
                  },
                },
                {
                  title: "Invoice Amount",
                  dataIndex: "invoice_amount",
                },
                {
                  title: "Attachment",
                  dataIndex: "attachment",
                  render: (record) =>
                    <a
                    href="#"
                    className="anchorTag financials"
                    onClick={() => downloadFile(record)}
                    >
                    Download
                    </a>
                  },
              ],
            },
            {
              title: "Payment",
              children: [
                {
                  title: "Payment ID",
                  dataIndex: "payment_id",
                },
                {
                  title: "Status",
                  dataIndex: "status",
                  render: (text) => {
                    let status = text == "PAID" ? "Paid" : "Not Paid"
                    return status;
                  }
                },
                {
                  title: "Amount",
                  dataIndex: "payment_amount",
                },
                {
                  title: "Date",
                  dataIndex: "payment_date",
                  render: (text, record) => {
                    if(text != "N/A"){
                      let date = new Date(text).dateToDDMMYYYY(new Date(text));
                      return date;
                    } else {
                      return text;
                    }
                  },
                },
              ],
            },
          ];

        let yearFields = {
        type: "select",
        name: "year",
        placeholder: "Choose Year",
        options: [
          { key: moment().year() - 9, label: moment().year() - 9, index: 9 },
          { key: moment().year() - 8, label: moment().year() - 8, index: 8 },
          { key: moment().year() - 7, label: moment().year() - 7, index: 7 },
          { key: moment().year() - 6, label: moment().year() - 6, index: 6 },
          { key: moment().year() - 5, label: moment().year() - 5, index: 5 },
          { key: moment().year() - 4, label: moment().year() - 4, index: 4 },
          { key: moment().year() - 3, label: moment().year() - 3, index: 3 },
          { key: moment().year() - 2, label: moment().year() - 2, index: 2 },
          { key: moment().year() - 1, label: moment().year() - 1, index: 1 },
          { key: moment().year(), label: moment().year(), index: 10 },
          { key: moment().year() + 1, label: moment().year() + 1, index: 11 },
          { key: moment().year() + 2, label: moment().year() + 2, index: 12 },
          { key: moment().year() + 3, label: moment().year() + 3, index: 13 },
          { key: moment().year() + 4, label: moment().year() + 4, index: 14 },
          { key: moment().year() + 5, label: moment().year() + 5, index: 15 },
          { key: moment().year() + 6, label: moment().year() + 6, index: 16 },
          { key: moment().year() + 7, label: moment().year() + 7, index: 17 },
          { key: moment().year() + 8, label: moment().year() + 8, index: 18 },
          { key: moment().year() + 9, label: moment().year() + 9, index: 19 },
        ],
        defaultValue: currentYear,
        className: "month-select Yearly-sort",
        onChange: (...args) => handleYearChange(...args),
      };

    const handleYearChange = (e) => {
        const selectedYear = e[0].key;
        let obj = {
          ...current
        }
        obj.year = selectedYear;
        setDate(moment(obj));
        setCurrent(obj);
        console.log(`Selected Year: ${selectedYear}`);
        setSelectYear(selectedYear);
      }

    const downloadFile = (fileId) => {
      let authToken = window.store.getState().root.auth;
      console.log({ authToken: authToken, fileId });
      const apiUrl =
        app.api.request(app.apis().fileDownloader).urltransform({ attachmentId: fileId }).url +
        "?auth=" +
        authToken;
    
      fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `${fileId}`;
          link.click();
        })
        .catch((error) => console.error('Error downloading file:', error));
    };
    
      useEffect(( ) => {
        fetchFinancialsList();
      }, []);

      useEffect(() => {
        fetchFinancialsList();
      }, [selectYear])

     const fetchFinancialsList = () => {
      setLoader(true);
      let payload = {
        ...search,
        year: selectYear
      }
      const school_id = (props.selectedEditMode || props.readMode) ?
      props.selectedRowData.school_id : localStorage.getItem("school_id");
      props.api
      .apiGetFinancialsList(payload, {
        school_id: school_id,
      })
      .then((res) => {
        console.log({ apiGetFinancialsList: res.data });
          setLoader(false);
          setTableData(res.data[0].records)
      })
      .catch((error) => {
        setLoader(false);
        console.log({ error: error });
      });
     }

    return (
        <>
        {loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
            <div className="row">
                <div className="year-field">
                    <AppInput {...yearFields}></AppInput>
                </div><br/>
                <div className="row financials financial-table">
                    <div className="col-12">
                        {!!tableData.length && (
                              <Table
                              columns={columns}
                              dataSource={tableData}
                              bordered
                              size="middle"
                              pagination={false}
                            />
                        )}
                        {tableData.length === 0 && <div className="financial_layout"></div>}
                    </div>
                </div>
            </div>
        </>
    )
  }

export default connect(Financials);