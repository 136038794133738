import React, { Component } from "react";
import LayoutWrapper from "../../LayoutWrapper";
import { Link } from "react-router-dom";
import mixins from "../../mixins";
import ClassModal from "../../ClassModal";
// import AssessmentModal from "./AssessmentModal";

export class AddAssessments extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AddAssessments");
    this.state = {
      data: [],
      rowcount: 0,
      search: {
        limit: 20,
        orderBy: "updatedAt",
        orderDirection: "DESC",
        page: 1,
        filter: {
          session_id: props?.urlparams?.session_id,
        },
      },
      editData: {},
      viewMode: false,
      editMode: false,
      popupfields: {},
      popupdata: {},
      modalName: "assessmentModal",
      assessmentId: "",
      questionId: "",
      questionType: "",
      ansArr: [],
    };
    this.tablinks = mixins.tablinks;
    this.columns = [
      {
        dataIndex: "question_id",
        title: "Q. No.",
        render: (value, item, index) => {
          return <>{index + 1}.</>;
        },
      },
      {
        dataIndex: "q_label",
        title: "Question",
        render: (text, record) => {
          return (
            <span
              className="text_truncate w-200px cursor-pointer"
              title={record?.q_label}
              onClick={() => alert(record?.q_label)}
            >
              {record?.q_label}
            </span>
          );
        },
      },
      {
        dataIndex: "q_options",
        title: "Possible Values",
        render: (text) => {
          return <>{text.join(", ")}</>;
        },
      },
      {
        dataIndex: "q_level",
        title: "Difficulty Levels",
      },
    ];
    this.assessmentModalFields = [
      {
        type: "text",
        name: "assessment_name",
        label: "Assessment Name",
        required: true,
        customcolspan: "col-12",
      },
    ];
    this.questionsModalFields = [
      {
        type: "radio",
        name: "video",
        label: "",
        onClick: (e) => this.handleVideoImageChange(e),
        value: "VIDEO",
        customcolspan: "col-md-4",
      },
      {
        type: "radio",
        name: "image",
        onChange: (e) => this.handleVideoImageChange(e),
        label: "",
        value: "IMAGE",
        customcolspan: "col-md-4",
      },
      {
        type: "link",
        onLinkClick: (v) => console.log(v),
        label: "",
        name: "clear",
        linkLabel: "Clear Selection",
        customcolspan: "col-md-4 d-none",
      },
      {
        name: "attach_file_type",
        label: "",
        customcolspan: "d-none",
      },
      {
        type: "file",
        name: "attach_file",
        label: "Upload Image",
        fileType: "assessment",
        accept: "image/*",
        customcolspan: "d-none",
      },
      {
        type: "text",
        name: "q_label",
        label: "Question",
        required: true,
        className: "col-12",
      },
      {
        type: "select",
        options: _enum.QUESTION_TYPES,
        name: "q_type",
        label: "Question Type",
        required: true,
        onChange: (v) => this.handleTypeChange(v),
      },
      // {
      //   type: "text",
      //   name: "q_options",
      //   label: "Possible Values",
      //   required: true,
      // },
      {
        type: "select",
        options: _enum.DIFFICULTY_LEVEL,
        name: "q_level",
        label: "Difficulty Level",
        required: true,
      },
    ];
    this.assessmentModalRef = React.createRef();
  }

  getGradeLevelParams() {
    return {
      grade: this.props.urlparams.grade,
      level: this.props.urlparams.level,
      curriculum_id: this.props.urlparams.curriculum_id,
      // school_id: this.props.urlparams.school_id,
      // class_id: this.props.urlparams.class_id,
      session_id: this.props.urlparams.session_id,
    };
  }

  getPillarParams() {
    return this.props.urlparams.type;
  }

  sumitForm(event, data, form) {
    console.log({ addAssessments: data });
  }

  componentDidMount() {
    console.log("component did mount called");
    this.fetchData();
  }

  addModalFields(values, modalfields, modaltype) {
    console.log({ values, modalfields, modaltype });
    let fields = (modalfields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    this.setState({
      popupfields: fields,
      popupdata: formdata,
    });
    if (modaltype === "assessmentModal") {
      this.setState({
        modalName: "assessmentModal",
      });
    } else {
      this.setState({
        modalName: "questionsModal",
      });
    }
  }

  handleClearSelection() {
    this.setState({
      popupdata: {
        ...this.state.popupdata,
        attach_file_type: "",
      },
      popupfields: {
        ...this.state.popupfields,
        video: {
          type: "radio",
          name: "video",
          label: "",
          customcolspan: "col-md-4",
          onClick: (e) => this.handleVideoImageChange(e),
          value: "VIDEO",
          checked: false,
        },
        image: {
          type: "radio",
          name: "image",
          customcolspan: "col-md-4",
          label: "",
          onClick: (e) => this.handleVideoImageChange(e),
          value: "IMAGE",
          checked: false,
        },
        clear: {
          type: "link",
          label: "",
          linkLabel: "Clear Selection",
          customcolspan: "col-md-4 d-none",
        },
        attach_file_type: {
          name: "attach_file_type",
          label: "",
          value: "",
          customcolspan: "d-none",
        },
        attach_file: {
          type: "file",
          name: "attach_file",
          label: "Upload Image",
          fileType: "assessment",
          accept: "image/*",
          customcolspan: "d-none",
        },
      },
    });
  }

  handleVideoImageChange(e) {
    if (e.target.checked) {
      this.setState({
        popupdata: {
          ...this.state.popupdata,
          attach_file_type: e.target.value,
        },
        popupfields: {
          ...this.state.popupfields,
          video: {
            type: "radio",
            name: "video",
            label: "",
            customcolspan: "col-md-4",
            onClick: (e) => this.handleVideoImageChange(e),
            value: "VIDEO",
            checked: e.target.name === "video" ? e.target.checked : false,
          },
          image: {
            type: "radio",
            name: "image",
            customcolspan: "col-md-4",
            label: "",
            onClick: (e) => this.handleVideoImageChange(e),
            value: "IMAGE",
            checked: e.target.name === "image" ? e.target.checked : false,
          },
          clear: {
            type: "link",
            onLinkClick: (v) => this.handleClearSelection(),
            label: "",
            linkLabel: "Clear Selection",
            customcolspan: "col-md-4",
          },
          attach_file: {
            type: e.target.name === "image" ? "file" : "text",
            name: "attach_file",
            label: e.target.name === "image" ? "Upload Image" : "Video Link",
            accept: "image/*",
          },
        },
      });
    }
  }

  handleTypeChange(value) {
    console.log(value[0].key);

    if (value[0].key === "text") {
      this.setState({
        popupdata: {
          ...Object.except(this.state.popupdata, ["q_options", "a_value"]),
        },
        popupfields: {
          ...this.state.popupfields,
        },
      });
    } else {
      console.log(this.state.popupdata, this.state.popupfields);
      this.setState({ ansArr: [] });
      this.setState((prevState) => ({
        popupdata: {
          ...Object.assign(prevState.popupdata, { q_options: [], a_value: "" }),
        },
        popupfields: {
          ...prevState.popupfields,
          q_options: {
            type: "text",
            name: "q_options",
            label: "Possible Values",
            onBlur: (e) => {
              let a_value = this.state.popupfields.a_value;
              let val = e.target.value;
              let options = val.split(",");
              if (options[options.length - 1] == "") {
                options.pop();
              }
              a_value.options = options.map((value) => ({
                key: value,
                value: value,
                label: value,
              }));
              this.setState({
                ansArr: a_value.options,
              });
            },
            required: true,
          },
          a_value: {
            type: "select",
            name: "a_value",
            label: "Correct Answer",
            multi: value[0].key === "checkbox" ? true : false,
            options: [],
            className: "col-lg-12",
            required: true,
          },
        },
      }));
    }
  }

  async fetchData() {
    try {
      if(this.props.urlparams.session_id) {
      const { data } = await this.props.api.apiGetAssessments({
        session_id: this.props.urlparams.session_id,
      });

      const assessmentData = [];

      for (const item of data) {
        const assessmentResponse = await this.props.api.apiGetAssessmentById({
          assessment_id: item.assessment_id,
        });
        item.assessment_questions =
          assessmentResponse.data?.assessment_questions;
        assessmentData.push(item);
      }

      this.setState({
        data: assessmentData,
        rowcount: assessmentData.length,
      });
      }
    } catch (error) {
      // Handle errors here
      console.error("Error fetching data:", error);
    }
  }

  handleOptionChange(v) {
    console.log("v----", v);
  }

  fetchAssessmentById(item) {
    this.props.api
      .apiGetAssessmentById({ assessment_id: item.assessment_id })
      .then(({ data }) => {
        console.log("qdata", data);
        return data || {};
      });
  }

  openAssessmentModal() {
    this.assessmentModalRef.current.click();
  }

  editAction(v, assessmentId) {
    console.log("edit: ", v, assessmentId);
    this.openAssessmentModal();
    let showFields;
    if (v.q_options.length > 0) {
      showFields = ["q_label", "q_type", "q_level", "q_options", "a_value"];
    } else {
      showFields = ["q_label", "q_type", "q_level"];
    }
    this.setState((prevState) => ({
      popupdata: {
        video: "",
        image: "",
        clear: "",
        attach_file_type: v.attach_file_type,
        attach_file: v.attach_file,
        ...Object.only(v, showFields),
      },
      popupfields: {
        video: {
          type: "radio",
          name: "video",
          label: "",
          customcolspan: "col-md-4",
          onClick: (e) => this.handleVideoImageChange(e),
          value: "VIDEO",
          checked: v.attach_file_type === "VIDEO" ? true : false,
        },
        image: {
          type: "radio",
          name: "image",
          customcolspan: "col-md-4",
          label: "",
          onClick: (e) => this.handleVideoImageChange(e),
          value: "IMAGE",
          checked: v.attach_file_type === "IMAGE" ? true : false,
        },
        clear: {
          type: "link",
          label: "",
          name: "clear",
          linkLabel: "Clear Selection",
          customcolspan: "col-md-4",
        },
        attach_file_type: {
          name: "attach_file_type",
          label: "",
          customcolspan: "d-none",
        },
        attach_file: {
          type: v.attach_file_type === "IMAGE" ? "file" : "text",
          name: "attach_file",
          label: v.attach_file_type === "IMAGE" ? "Upload Image" : "Video Link",
          accept: "image/*",
          fileType: "assessment",
          accept: "image/*",
          customcolspan: "",
        },
        ...Object.except(prevState.popupfields, [
          "video",
          "image",
          "clear",
          "attach_file_type",
          "attach_file",
        ]),

        q_options: {
          type: "text",
          name: "q_options",
          label: "Possible Values",
          onBlur: (e) => {
            let a_value = this.state.popupfields.a_value;
            let val = e.target.value;
            let options = val.split(",");
            if (options[options.length - 1] == "") {
              options.pop();
            }
            a_value.options = options.map((value) => ({
              key: value,
              value: value,
              label: value,
            }));
            this.setState({
              ansArr: a_value.options,
            });
          },
          required: true,
        },
        a_value: {
          type: "select",
          name: "a_value",
          options: v.q_options.map((value) => ({
            key: value,
            value: value,
            label: value,
          })),
          defaultValue: v.a_value.splitBy(","),
          label: "Correct Answer",
          multi: true,
          className: "col-lg-12",
          required: true,
        },
      },
      editMode: true,
      viewMode: false,
      assessmentId,
      questionId: v.question_id,
    }));
  }

  viewAction(v) {
    console.log("view: ", v);
    this.openAssessmentModal();
    let showFields;
    if (v.q_options.length > 0) {
      showFields = ["q_label", "q_type", "q_level", "q_options"];
    } else {
      showFields = ["q_label", "q_type", "q_level"];
    }
    this.setState((prevState) => ({
      popupdata: {
        video: "",
        image: "",
        attach_file_type: v.attach_file_type,
        attach_file: v.attach_file,
        ...Object.only(v, showFields),
      },
      popupfields: {
        video: {
          type: "radio",
          name: "video",
          label: "",
          customcolspan: "col-md-4",
          onClick: (e) => this.handleVideoImageChange(e),
          value: "VIDEO",
          checked: v.attach_file_type === "VIDEO" ? true : false,
          disabled: true,
        },
        image: {
          type: "radio",
          name: "image",
          customcolspan: "col-md-4",
          label: "",
          onClick: (e) => this.handleVideoImageChange(e),
          value: "IMAGE",
          checked: v.attach_file_type === "IMAGE" ? true : false,
          disabled: true,
        },
        attach_file_type: {
          name: "attach_file_type",
          label: "",
          customcolspan: "d-none",
        },
        attach_file: {
          type: v.attach_file_type === "IMAGE" ? "file" : "text",
          name: "attach_file",
          label: v.attach_file_type === "IMAGE" ? "Upload Image" : "Video Link",
          accept: "image/*",
          fileType: "assessment",
          accept: "image/*",
          customcolspan: "",
        },
        ...Object.except(prevState.popupfields, [
          "video",
          "image",
          "attach_file_type",
          "attach_file",
        ]),
        q_options: {
          type: "text",
          name: "q_options",
          label: "Possible Values",
          required: true,
        },
      },
      editMode: false,
      viewMode: true,
    }));
  }

  deleteAction(v, assessmentId) {
    console.log("delete ", v);
    let payload = {
      assessment_id: assessmentId,
      question_id: v.question_id,
    };

    confirm(`Do you really want to delete these records?`).promise.then(() => {
      this.api.apiDeleteAssessmentQuestion(payload).then(() => {
        this.fetchData();
      });
    });
  }
  handleAssessmentDelete(e, assessmentId) {
    let payload = {
      assessment_id: assessmentId,
    };
    confirm(`Do you really want to delete these records?`).promise.then(() => {
      this.api.apiDeleteAssessment(payload).then(() => {
        this.fetchData();
      });
    });
  }

  handleAssessmentEdit(e, assessmentId) {
    this.addModalFields({}, this.assessmentModalFields, "assessmentModal");
    this.openAssessmentModal();
    // this.api.apiDeleteAssessment(payload).then(() => {
    //   this.fetchData();
    // });
  }

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      (state) => this.fetchData()
    );
  }

  closeModal() {
    document.querySelector("#assessmentModal #bsmodalclose").click();
  }

  handleSubmit(e, data) {
    e.preventDefault();
    console.log("formData", data);

    let formData = data;
    formData.video && delete formData.video;
    formData.image && delete formData.image;
    if (formData.attach_file_type == "") {
      delete formData.attach_file;
      delete formData.attach_file_type;
    }

    if (this.state.editMode) {
      if (this.state.modalName === "assessmentModal") {
        let payload = {
          assessment_id: this.state.assessmentId,
        };
        console.log(payload);
        this.closeModal();
        this.props.api.apiUpdateAssessment(formData, payload).then((res) => {
          console.log({ apiUpdateAssessment: res });
          success("Assessment Updated Successfully");
          this.fetchData();
        });
      } else {
        let payload = {
          assessment_id: this.state.assessmentId,
          question_id: this.state.questionId,
        };
        console.log(payload);
        this.props.api
          .apiUpdateAssessmentQuestion(formData, payload)
          .then((res) => {
            console.log({ apiUpdateAssessmentQuestion: res });
            this.closeModal();
            success("Question Updated Successfully");
            this.fetchData();
          });
      }
    } else {
      if (this.state.modalName === "assessmentModal") {
        let payload = {
          session_id: this.props.urlparams.session_id,
        };
        this.props.api.apiCreateAssessment(data, payload).then((res) => {
          console.log({ apiCreateAssessment: res });
          success("Assessments Added Successfully");
          this.closeModal();
          this.fetchData();
        });
      } else {
        let payload = {
          assessment_id: this.state.assessmentId,
        };
        console.log("Add question: ", payload);

        this.props.api.apiAddAssessmentQuestion(data, payload).then((res) => {
          console.log({ apiAddAssessmentQuestion: res });
          success("Question Added Successfully");
          this.closeModal();
          this.fetchData();
        });
      }
    }
  }

  updateFormFields() {
    let { formFields } = this;
  }

  getBreadCums() {
    if (this.props.urlparams.grade) {
      return `Content Management / ${
        this.props.urlparams.grade
      } Grade / Courses / ${this.props.urlparams.type.toTitleCase()} / Add Session`;
    } else {
      return "Content Management / Sessions";
    }
  }

  getBackLink() {
    if (this.props.urlparams.grade) {
      let backLink = `/contentmanagement/pillarspage?grade=${this.props.urlparams.grade}&level=${this.props.urlparams.level}&curriculum_id=${this.props.urlparams.curriculum_id}&session_id=${this.props.urlparams.session_id}`;
      return backLink;
    } else {
      return "/contentmanagement/sessions";
    }
  }

  render() {
    let { tablinks, columns, assessmentModalFields, questionsModalFields } =
      this;
    let {
      data = [],
      rowcount,
      editData,
      popupdata,
      popupfields,
      editMode,
      viewMode,
      modalName,
    } = this.state;
    console.log("datatatata", data);
    return (
      <LayoutWrapper
        title="Content Management"
        back={this.getBreadCums()}
        backlink={this.getBackLink()}
      >
        <AppLinkTabs data={tablinks} className="mb-0"></AppLinkTabs>
        <div className="row m-auto">
          <div className="col"></div>
          <div className="mb-4"></div>
        </div>
        <div className="row m-auto">
          <div className="col-12 mb-4">
            <Link
              className={Object.className({
                "btn add-btn ml-auto": true,
                hidden: this.props.urlparams.readonly == 1 ? true : false,
              })}
              ref={this.assessmentModalRef}
              data-bs-toggle="modal"
              data-bs-target="#assessmentModal"
              onClick={(...args) =>
                this.addModalFields(
                  ...args,
                  assessmentModalFields,
                  "assessmentModal"
                )
              }
            >
              <i className="fa fa-plus"></i>
              Add Assessment
            </Link>
          </div>
        </div>

        <div className="row m-auto">
          <div className="col-12">
            {!!data.length &&
              data.map((item, i) => {
                return (
                  <div className="assessment_container" key={i}>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <div className="flex flex-column">
                        <h4>Assessment No. {i + 1}</h4>
                        <p className="sub_title">{item?.assessment_name}</p>
                      </div>
                      <div className="flex">
                        {/* <button
                          type="button"
                          className="btn bg-grey-500 text-dark mx-1"
                          onClick={(...args) =>
                            this.handleAssessmentEdit(
                              ...args,
                              item?.assessment_id
                            )
                          }
                        >
                          <i className="fa fa-pencil fs-6" />
                        </button> */}
                        <button
                          type="button"
                          className="btn bg-red-500 text-white mx-1"
                          onClick={(...args) =>
                            this.handleAssessmentDelete(
                              ...args,
                              item?.assessment_id
                            )
                          }
                        >
                          <i className="fa fa-trash-o fs-6" />
                        </button>
                      </div>
                    </div>
                    <AppTable
                      data={item?.assessment_questions}
                      columns={columns}
                      onNext={() => this.onNext()}
                      onPrev={() => this.onPrev()}
                      onChange={(...arg) => this.onPageChange(...arg)}
                      total={rowcount}
                      reorder={true}
                      deleteAction={(v) =>
                        this.deleteAction(v, item?.assessment_id)
                      }
                      editAction={(v) =>
                        this.editAction(v, item?.assessment_id)
                      }
                      viewAction={(v) => this.viewAction(v)}
                      editable={
                        this.props.urlparams.readonly == 1 ? false : true
                      }
                      deletable={
                        this.props.urlparams.readonly == 1 ? false : true
                      }
                      showPagination={false}
                      targetType="tap"
                    ></AppTable>
                    <div className="w-100 my-4">
                      <button
                        type="button"
                        className="text-primary fw-bold border-0 bg-transparent fs-6 ml-auto d-block"
                        ref={this.assessmentModalRef}
                        data-bs-toggle="modal"
                        data-bs-target="#assessmentModal"
                        onClick={(...args) => {
                          this.addModalFields(
                            ...args,
                            questionsModalFields,
                            "questionsModal"
                          ),
                            this.setState({
                              assessmentId: item?.assessment_id,
                            });
                        }}
                      >
                        {/* <i className="fa fa-plus"></i> */}
                        <span>Add more Questions</span>
                      </button>
                      <hr />
                    </div>
                  </div>
                );
              })}
            {data.length == 0 && <div className="empty_layout"></div>}
          </div>
        </div>

        <div className="row m-auto">
          <div className="col-12 mt-4">
            <Link
              to={`/contentmanagement/sessions/preview${Object.encodeuri(
                this.getGradeLevelParams()
              )}&type=${this.getPillarParams()}&${Object.QueryString({
                new: 1,
              })}`}
              className="btn add-btn ms-3"
            >
              Preview
            </Link>
            <Link
              to={`/contentmanagement/sessions/preview${Object.encodeuri(
                this.getGradeLevelParams()
              )}&type=${this.getPillarParams()}&${Object.QueryString({
                new: 1,
              })}`}
              className="btn btn_primary grey col col-md-1 float-right"
            >
              Skip
            </Link>
          </div>
        </div>
        <ClassModal
          id="assessmentModal"
          title={`${
            modalName == "assessmentModal"
              ? "Add Assessment"
              : editMode
              ? "Update Question"
              : viewMode
              ? "View Question"
              : "Add Question"
          }`}
          submitText={`${
            modalName == "assessmentModal"
              ? "Add Assessment"
              : editMode
              ? "Update Question"
              : viewMode
              ? "View Question"
              : "Add Question"
          }`}
          editdata={editData}
          data={popupdata}
          fields={popupfields}
          contentClass="assessmentModal"
          disableallfields={viewMode ? true : false}
          onSubmit={(...args) => this.handleSubmit(...args)}
          onClose={() =>
            this.setState({
              popupdata: {},
              editData: {},
              editMode: false,
              viewMode: false,
              assessmentId: "",
              questionId: "",
            })
          }
        />
      </LayoutWrapper>
    );
  }
}

export default connect(AddAssessments);
