import methods from "./methods";
import apis from "./apis";
import _enum from "./enum";
import mokeresults from "./mokeresults";
import Emitter from "../utils/emiters";
window._enum = _enum;
window.mokeresults = mokeresults;
window.emit = new Emitter();
const setInitials = ()=>{
    window._enum = {
        ..._enum,
        ...window.getpathkey(_enum)
    };
    window.mokeresults = {
        ...mokeresults,
        ...window.getpathkey(mokeresults)
    }
    console.log({setInitials:window._enum});
};
export const mixins =  {
    ...methods,
    api:apis,
    setInitials,
    _enum:()=>(window._enum),
    emit:window.emit
}
window.onload = ()=>{
    console.log("webpageloaded");
    //setInitials();
};
export default mixins;