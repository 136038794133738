export default {

    SCHOOL_NAMES: [
        { key: "school_one", label: "School One" },
        { key: "school_two", label: "School Two" },
    ],
    ROMAN_LETTERS: {
        "1": "I",
        "2": "II",
        "3": "III",
        "4": "IV",
        "5": "V",
        "6": "VI",
        "7": "VII",
        "8": "VIII",
        "9": "IX",
        "10": "X",
    },
    YEARS: {
        1 : "January",
        2 : "February",
        3 : "March",
        4 : "April",
        5 : "May",
        6 : "June",
        7 : "July",
        8 : "August",
        9 : "September",
        10 : "October",
        11 : "November",
        12 : "December"
    },
}