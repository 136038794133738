import React, { useState, useEffect } from "react";
import { Step, Stepper } from "react-form-stepper";
import LayoutWrapper from "./LayoutWrapper";
import GenericInfo from "./GenericInfo";
import ClassConfig from "./ClassConfig";
import ManageAccess from "./MangeAccess";
import SchoolAdminConfig from "./SchoolAdminConfig";
import SchoolCoordinatorConfig from "./SchoolCoordinatorConfig";
import { toast, ToastContainer  } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const SchoolSteps = (props) => {
  console.log({ SchoolSteps: props });

  const [goSteps, setGoSteps] = useState(0);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if(props.editMode && props.goToStepper == 5) {
      setGoSteps(props.goToStepper-1)
    } else if (props.editMode && props.goToStepper != 5) {
      setGoSteps(props.goToStepper)
    }
  }, [])

  const stepLabels = [
    { title: "STEP 1", subtitle: "Generic Info" },
    { title: "STEP 2", subtitle: "Class Config" },
    { title: "STEP 3", subtitle: "Manage Access"  },
    { title: "STEP 4", subtitle: "School Admin Config" },
    { title: "STEP 5", subtitle: "School Coordinator Config" }
  ];

  const toastConfig = {
    autoClose: 3000,
    closeButton: false,
    hideProgressBar: true,
  };

  const onGenericInfoSubmit = (e, data, form) => {
    e.preventDefault();
    // console.log("formData", data, form);
    console.log({ onGenericInfoSubmit: e });

    let documents = [];
    if(data?.MOU) {
      documents.push({
        type: "MOU",
        name: "MOU",
        attachment_id: data.MOU,
      })
    }
    if(data?.NDA) {
      documents.push({
        type: "NDA",
        name: "NDA",
        attachment_id: data.NDA,
      })
    }
    if(data?.OTHER) {
      documents.push({
        type: "OTHER",
        name: "Freetext for OTHER",
        attachment_id: data.OTHER,
      })
    }

    let payload = {
        school_name: data.school_name,
        mobile_number: data.mobile_number,
        state: data.state,
        city: data.city,
        address: data.address,
        onboarding_date: data.onboarding_date,
    };
    if(data?.website) payload.website = data.website
    if(data?.notes) payload.notes = data.notes
    if(data?.logo) payload.logo = data.logo
    if(documents.length > 0) payload.documents = documents
    props.api.apiAddGenericInfo(payload).then((response) => {
      console.log({apiAddGenericInfo: response});
      localStorage.setItem("school_id", response.data.school_id);
      toast.success("Generic Info. Added Successfully", toastConfig);
      setGoSteps(1);
    }).catch((error) => {
      console.error({error: error});
      toast.error(error.response.data.issues.message, toastConfig)
    });
  };

  const onClassConfigSubmit = (e, data, form) => {
    setGoSteps(2);
  };

  const onManageAccessSubmit = (e) => {
    setGoSteps(3);
  };

  const onSchoolAdminConfigSubmit = (e) => {
    setGoSteps(4);
  };

  const onSchoolCoordinatorConfig = (e) => {
    setLoader(true);
    let school_id = props.editMode ? props.editData.school_id : localStorage.getItem("school_id");
      let payload = {
          school_id: school_id
      };
      props.api
      .apiEditSchoolActivation(payload, {school_id: school_id})
      .then(() => {
        setLoader(false); 
        success("School Onboarded Successfully");
      })
      .catch(() => {
        setLoader(false); 
      })
    setTimeout(() => { location.reload(); }, 3000);
  };

  return (
    <>
      {loader && <div className="loader-container">
				<div className="spinner"></div>
			</div>}
      <ToastContainer position="top-right" />
      <LayoutWrapper
					title="School Management"
					back="School Management / Add School"
				>
      <div className="form-wizard">
          <Stepper activeStep={goSteps} className="school-stepper">
          {stepLabels.map((step, index) => (
            <Step
              key={step.title}
              completed={goSteps > index}
              className="step-link"
              onClick={() => setGoSteps(index)}
            >
              <div className="step-container">
                <div
                  className={`step-title ${goSteps === index ? "active" : ""}`}
                >
                  {step.title}
                </div>
                <div className="step-subtitle">{step.subtitle}</div>
                {/* <div
                  className={`step-status ${goSteps === index ? "active" : ""}`}
                >
                  {step.status}
                </div> */}
              </div>
            </Step>
          ))}
        </Stepper>

        {goSteps === 0 && (
          <>
            <GenericInfo
            onGenericInfoSubmit={(...args) => onGenericInfoSubmit(...args)} 
            readMode={props.viewMode}
            selectedEditMode={props.editMode}
            selectedRowData={props.editData}
            showDataToEdit={props.showDataToEdit}
            schoolStatus={props.schoolStatus}
            setGoSteps={setGoSteps}
            />
          </>
        )}
        {goSteps === 1 && (
          <>
            <ClassConfig
            onClassConfigSubmit={onClassConfigSubmit}
            readMode={props.viewMode}
            selectedEditMode={props.editMode}
            selectedRowData={props.editData}
            schoolStatus={props.schoolStatus}
            setGoSteps={setGoSteps}
            />
          </>
        )}
        {goSteps === 2 && (
          <>
            <ManageAccess
            onManageAccessSubmit={onManageAccessSubmit}
            readMode={props.viewMode}
            selectedEditMode={props.editMode}
            selectedRowData={props.editData}
            schoolStatus={props.schoolStatus}
            setGoSteps={setGoSteps}
            />
          </>
        )}
        {goSteps === 3 && (
          <>
            <SchoolAdminConfig
              onSchoolAdminConfigSubmit={onSchoolAdminConfigSubmit}
              readMode={props.viewMode}
              selectedEditMode={props.editMode}
              selectedRowData={props.editData}
              schoolStatus={props.schoolStatus}
              setGoSteps={setGoSteps}
            />
          </>
        )}
        {goSteps === 4 && (
          <>
            <SchoolCoordinatorConfig
              onSchoolCoordinatorConfig={onSchoolCoordinatorConfig}
              readMode={props.viewMode}
              selectedEditMode={props.editMode}
              selectedRowData={props.editData}
              schoolStatus={props.schoolStatus}
              setGoSteps={setGoSteps}
            />
          </>
        )}
      </div>
      </LayoutWrapper>
    </>
  );
};

export default connect(SchoolSteps);
