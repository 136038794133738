import React, { Component } from "react";

export class ClassConfigModal extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("ClassConfigModal");
    this.form = React.createRef();
    this.state = {
      // section: [{}],
      // viewmode: this.props.viewmode,
      // editmode: this.props.editmode,
    };
  }
  componentDidMount() {
    window.ClassConfigModal = this;
  }
  componentDidUpdate() {
    let { viewmode, editmode, modalopened, editdata } = this.props;
    // console.log({viewmode, editmode, modalopened});
    if (modalopened) {
      // this.setState({
      //   section: [
      //     {name: "A", no_student: "12"},
      //     {name: "B", no_student: "13"},
      //     {name: "C", no_student: "14"},
      //   ],
      // });
    }
  }

  // addField(e) {
  //   let { section } = this.state;
  //   section = [...section, {}];
  //   console.log({ section });
  //   this.setState({
  //     section,
  //     viewmode: false,
  //     editmode: false
  //   });
  // }

  // deleteField(e, index) {
  //   e.preventDefault();
  //   const removeLocal = () => {
  //     let { section } = this.state;
  //     section = section.filter((v, i) => i != index);
  //     this.setState({
  //       section,
  //     });
  //   };
  //   removeLocal();
  // }

  closemodal() {
    this.props.onClose();
    this.setState({
      // section: [{}],
    });
  }

  render() {
    let { props } = this;
    let {
      id,
      title,
      data,
      size = 2,
      fields = {},
      submitText = "SUBMIT",
      onClose,
      disableallfields = false,
      disablefields = [],
      submitButton = false,
      closeButton = false,
      classnames,
      editdata,
      editmode,
      viewmode,
      section,
    } = props;
    let formAttrs = Object.except(props, [
      "data",
      "fields",
      "size",
      "submitText",
    ]);
    // let { section } = this.state;

    if (editdata) {
      console.log({ editdata, viewmode, editmode });
    }
    return (
      <AppModalBs
        id={id}
        title={title}
        onClose={(...args) => this.closemodal(...args)}
      >
        <AppForm {...formAttrs} ref={this.form}>
          <Row className="mx-0">
            <AppInput
              type="select"
              name="curriculum_id"
              label="Class Name"
              options={classnames}
              defaultValue={editdata?.curriculum_id || ""}
              required="true"
              disabled={viewmode || editmode}
              className="col-10"
            ></AppInput>
            {editmode || viewmode
              ? editdata?.section?.map((sec, i) => (
                  <div className="row" key={i}>
                    <AppInput
                      type="text"
                      name={`section.${i}.name`}
                      label="Section Name"
                      defaultValue={sec?.name}
                      required="true"
                      disabled={viewmode}
                      className="col-5 me-3"
                    ></AppInput>
                    <AppInput
                      type="text"
                      name={`section.${i}.no_student`}
                      defaultValue={sec?.no_student}
                      label="No. of Students"
                      required="true"
                      disabled={viewmode}
                      className="col-5"
                      pattern="^[0-9 ]+$"
                      invalidmessage="Please enter a valid number"
                    ></AppInput>
                    {i != 0 && (
                      <button
                        onClick={(e) => this.props?.deleteField(e, i)}
                        className="btn !mb-3 text-white col-1"
                      ><img src={imagepaths.deleteIcon} className="deleteIcon"/>
                      </button>
                    )}
                  </div>
                ))
              : section?.map((sec, i) => (
                  <div className="row" key={i}>
                    <AppInput
                      type="text"
                      name={`section.${i}.name`}
                      label="Section Name"
                      defaultValue={sec?.name}
                      required="true"
                      disabled={viewmode}
                      className="col-5 me-3"
                    ></AppInput>
                    <AppInput
                      type="text"
                      name={`section.${i}.no_student`}
                      defaultValue={sec?.no_student}
                      label="No. of Students"
                      required="true"
                      disabled={viewmode}
                      className="col-5"
                      pattern="^[0-9 ]+$"
                      invalidmessage="Please enter a valid number"
                    ></AppInput>
                    {i != 0 && (
                      <button disabled={viewmode}
                        onClick={(e) => this.props?.deleteField(e, i,this.form)}
                        className="btn !mb-3 text-white col-1"
                      >
                        <img
                          src={imagepaths.deleteIcon}
                          className="deleteIcon"
                        />
                      </button>
                    )}
                  </div>
                ))}
            <div className="col-6">
              <button disabled={viewmode}
                className="text-primary addMoreBtn"
                onClick={(...args) => this.props?.addField(...args)}
                type="button"
              >
                + Add more Section & Students
              </button>
            </div>
          </Row>
          {submitButton ? (
            <div className="row no-gutters mt-2">
              <Row className="mt-4">
                <button
                  type="reset"
                  className="btn close-btn col col-md-1 ml-auto rounded-1 text-dark"
                  data-bs-dismiss="modal"
                  onClick={(...args) => this.closemodal(...args)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn save-btn col col-md-1 ml-auto ms-2 me-2 rounded-1"
                >
                  {submitText}
                </button>
              </Row>
            </div>
          ) : closeButton ? (
            <div className="row no-gutters mt-2">
              <Row className="mt-4 d-flex justify-end">
                <button
                  type="reset"
                  data-bs-dismiss="modal"
                  onClick={(...args) => this.closemodal(...args)}
                  className="btn save-btn col col-md-1 ml-auto ms-2 me-2 rounded-1"
                >
                  Close
                </button>
              </Row>
            </div>
          ) : (
            <Row className="mt-4">
              <Col
                className={Object.className({
                  "text-right": true,
                  hidden: disableallfields,
                })}
              >
                <button type="submit" className="btn add-btn">
                  {submitText}
                </button>
              </Col>
              <Col>
                <button
                  type="reset"
                  className={Object.className({
                    "btn cancel-btn": true,
                    "m-auto": disableallfields,
                  })}
                  data-bs-dismiss="modal"
                  onClick={(...args) => this.closemodal(...args)}
                >
                  Cancel
                </button>
              </Col>
            </Row>
          )}
        </AppForm>
      </AppModalBs>
    );
  }
}

export default connect(ClassConfigModal);
