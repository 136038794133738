import { Component } from "react";
import LayoutWrapper from "../LayoutWrapper";
import "./sessionsview.scss";
import { Link } from "react-router-dom";

export class SessionsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    // this.props.api.apiCoursesDataMoke().then((data) => {
    //   this.setState({
    //     data,
    //   });
    // });
  }

  render() {
    let { data } = this.state;
    return (
      <>
        <LayoutWrapper title="SessionsView" back="SessionsView">
          <div className="row courses g-4 mx-auto">
            {data?.map((item, i) => {
              return (
                <div className="col-12 col-md-4 col-xl-3" key={i}>
                  <div className="courses_card">
                    <h3 className="title">{item.title}</h3>
                    <p>{item.description}</p>
                    <Link
                      to={`/lms/courses/module?course_id=${item.id}`}
                      className="btn add-btn m-0 float-none"
                    >
                      Explore
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </LayoutWrapper>
      </>
    );
  }
}

export default connect(SessionsView);
