import React, { Component } from "react";
export class TableSearch extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
        fields: [
            {
                name: "class_name",
                label: "Class Name",
                type: "text",
                defaultValue: " ",
            },
            {
                name: "table_no",
                label: "Table No.",
                type: "text",
                defaultValue: " ",
            },
            {
                name: "user_id",
                label: "User ID",
                type: "text",
                defaultValue: " ",
                searchField: true,
            },
        ]
    };
  }

  trimObjectValues(obj) {
    const trimmedObj = {};
    for (const key in obj) {
      if (typeof obj[key] === 'string') {
        trimmedObj[key] = obj[key].trim();
      } else {
        trimmedObj[key] = obj[key];
      }
    }
    return trimmedObj;
  }

  onTableSearch() {
    let data = this.refs.form.form.current.getData();
    data = {
      ...data,
    }
    // console.log("onTableSearch formData", data);
    data = this.trimObjectValues(data);
    data = Object.filter(data, (val) => val);
    // console.log("data on search", data);
    this.props.onTableSearch && this.props.onTableSearch(data);
  }

  clearTextData = () => {
    this.setState({
      fields: this.state.fields.map(field => ({...field, value: '' }))
    });
    let data = this.refs.form.form.current.getData();
    data = Object.filter(data, (val) => "");
    this.props.onSearch && this.props.onSearch(data);
  }  

  handleInputChange = (i, event) => {
    const newFields = [...this.state.fields];
    newFields[i].value = event.target.value;
    this.setState({ fields: newFields });
  }

  componentDidMount() {}

  render() {
    let { fields } = this.state;

    return (
      <AppForm className="row filter-row" ref="form">
        {fields.map((field, i) => (
            <div className="col-sm-6 col-md-3" key={i}>
              <AppInputFocus {...field} onChange={event => this.handleInputChange(i, event)}></AppInputFocus>
            </div>
          ))}
        <div className="col-sm-6 col-md-3">
          <button
            className="btn btn-success btn-block w-100"
            onClick={() => this.onTableSearch()}
          >
            Search
          </button>
        </div>
        <span className="clear-all" onClick={() => this.clearTextData()}>Clear Filter</span>
      </AppForm>
    );
  }
}

export default connect(TableSearch);
