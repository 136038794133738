export default {
    // TABLE APIs
    apiCreateTable(data) {
        console.log({ apiAddSchool: data });
        // let payload = {
        //     ...smMapcolumns.mapPayload("schools", data),
        // };
        // payload = Object.filter(payload, (v) => v);

        return this.request(this.api().createTable, data).exec().log().get();
    },
    apiGetAllTable(data, urldata = {}) {
        let payload = {
            ...data,
        };

        return this.request(this.api().getAllTable, payload)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records.map((record) =>
                        smMapcolumns.mapFields("table", record)
                    ),
                    total: response.data[0].pagination[0]?.totalRecords || 0,
                };
            })
            .get();
    },
    apiGetTableById(data) {
        let payload = {
            ...data,
        };

        return this.request(this.api().getTableById, payload)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records.map((record) =>
                        smMapcolumns.mapFields("table", record)
                    ),
                    total: response.data[0].pagination[0]?.totalRecords || 0,
                };
            })
            .get();
    },
    apiEditTable(data, urldata = {}) {
        console.log({ apiEditSchool: data, urldata });
        urldata = { ...mixins.params(), ...urldata };

        return this.request(this.api().updateTableById, data)
            .urltransform(urldata)
            .exec()
            .log()
            .get();
    },
    apiDeleteTable(urldata = mixins.params()) {
        return this.request(this.api().deleteTableById)
            .urltransform(urldata)
            .exec()
            .log()
            .get();
    },

    // STUDENT APIs

    apiCreateStudent(data) {
        console.log({ apiAddStudent: data });
        let payload = {
            ...smMapcolumns.mapPayload("Student", data),
        };
        payload = Object.filter(payload, (v) => v);

        return this.request(this.api().createStudent, payload).exec().log().get();
    },
    apiAddStudent(data) {
        console.log({ addStudent: data });
        let payload = {
            ...smMapcolumns.mapPayload("Student", data),
        };
        payload = Object.filter(payload, (v) => v);

        return this.request(this.api().addStudent, payload).exec().log().get();
    },
    apiAddTableData(data) {
        console.log({ addTableData: data });
        let payload = {
            ...smMapcolumns.mapPayload("Table", data),
        };
        payload = Object.filter(payload, (v) => v);

        return this.request(this.api().addTableData, payload).exec().log().get();
    },
    apiGetAllStudent(data, urldata = {}) {
        return this.request(this.api().getAllStudent, data)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0,
                };
            })
            .get();
    },
    apiGetStudentById(data) {
        let payload = {
            ...data,
        };

        return this.request(this.api().getStudentById, payload)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records.map((record) =>
                        smMapcolumns.mapFields("Student", record)
                    ),
                    total: response.data[0].pagination[0]?.totalRecords || 0,
                };
            })
            .get();
    },
    apiEditStudent(data, urldata = {}) {
        console.log({ apiEditStudent: data, urldata });
        urldata = { ...mixins.params(), ...urldata };

        return this.request(this.api().updateStudentById, data)
            .urltransform(urldata)
            .exec()
            .log()
            .get();
    },
    apiDeleteStudent(urldata = mixins.params()) {
        return this.request(this.api().deleteStudentById)
            .urltransform(urldata)
            .exec()
            .log()
            .get();
    },
    apiGetAllStudentResult(data, urldata = mixins.params()) {
        return this.request(this.api().getAllStudentResult, data)
            .urltransform(urldata)
            .mapresults((response) => {
                return {
                    data: response.data[0].records.map((record) =>
                        smMapcolumns.mapFields("Student Results", record)
                    ),
                    total: response.data[0].pagination[0]?.totalRecords || 0,
                };
            })
            .get();
    },

    //SECTION APIs
    apiGetAllSSections(urldata = {}) {
        return this.request(this.api().getAllSection)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data.sections
                };
            })
            .get();
    },

    // Phase 2 API to fetch all students
    apiGetAllStudents(data, urldata = {}) {
        return this.request(this.api().getAllStudents, data)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0,
                };
            })
            .get();
    },
};
