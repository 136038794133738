import React, { Component } from "react";

export class SubModuleModal extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.SubModuleModal = this;
  }
  render() {
    let { props } = this;
    let { id, title, onClose, submitButton, closeButton, contentClass } = props;
    let attrs = Object.except(props, ["id", "title", "type", "onClose"]);

    return (
      <AppModalBs id={id} title={title} onClose={onClose} contentClass={contentClass}>
        <AppFormData {...attrs} onClose={onClose} submitButton={submitButton} closeButton={closeButton}></AppFormData>
      </AppModalBs>
    );
  }
}

export default SubModuleModal;
