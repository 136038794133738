import React, { Component } from "react";

export class SchoolModal extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.SchoolModal = this;
  }
  render() {
    let { props } = this;
    let { id, title, onClose, data, contentClass } = props;

    return (
      <AppModalBs id={id} title={title} onClose={onClose} contentClass={contentClass}>
        {data.map((item, i) => <p className="namesList">{i+1}.  {item}</p>)}
        {data.length == 0 && <p className="namesList">No records found !!</p>}
        <div className="row no-gutters mt-2">
          <Row className="mt-4 d-flex justify-center">
            <button
              type="reset"
              data-bs-dismiss="modal"
              onClick={onClose}
              className="btn save-btn col col-md-1 ml-auto ms-2 me-2 rounded-1">
              Close
            </button>
          </Row>
        </div>
      </AppModalBs>
    );
  }
}

export default SchoolModal;
