export const cmMapcolumns = {
    attachments: {
        attachment_id: "attachmentId",
        file_name: "attachmentName",
    },
    class: {
        school_id: "school_id",
        class_name: "class_name",
        class_level: "class_level",
        class_description: "class_description"
    },
    session: {
        school_id: "school_id",
        class_id: "class_id",
        session_name: "session_name",
        session_description: "session_description",
        category_type: "category_type"
    },
    ppt: {
        session_id: "session_id",
        ppt_name: "ppt_name",
        ppt_description: "ppt_description",
        attachment_id: "attachment_id",
        is_active: "is_active"
    },
    handout: {
        session_id: "session_id",
        handout_name: "handout_name",
        handout_description: "handout_description",
        attachment_id: "attachment_id",
        is_active: "is_active"
    },
    video: {
        session_id: "session_id",
        video_title: "video_title",
        link: "link",
        video_description: "video_description"
    },
    mapFields(name, data) {
        let fields = Object.keys(data);
        let mapper = this[name] || {};
        // console.log(fields);
        return fields.reduce((c, key) => {
            let mapobj = mapper[key];
            let newkey = mapobj || key;
            newkey = typeof newkey === "string" ? newkey : newkey.key;
            newkey = newkey || key;
            let mapvalue = data[key];
            if (mapobj && mapobj.value instanceof Function) {
                mapvalue = mapobj.value(mapvalue, c);
            } else if (mapobj && ![undefined, null].includes(mapobj.value)) {
                mapvalue = mapobj.value;
            }
            return {
                ...c,
                [newkey]: mapvalue,
            };
        }, {});
    },
    mapPayload(name, data, excepts = []) {
        let payload = { ...data };
        let mapper = this[name] || {};
        mapper = Object.entries(mapper).reduce((c, [key, value]) => {
            if (value.rkeys && value.rkeys instanceof Array) {
                let commonMap = {};
                value.rkeys.forEach(key => {
                    if (Object.keys(payload).includes(key)) {
                        c[key] = {
                            commonMap,
                            key: value.key,
                            value: value.value,
                            reversevalue: value.reversevalue,
                            mapkey: key,
                        };
                    }
                })
            }
            return c
        }, mapper);
        // console.log({ mapper });
        if (mapper) {
            let mapdata = Object.entries(mapper).reduce((c, [key, value]) => {
                let newkey = value || key;
                newkey = typeof newkey === "string" ? newkey : newkey.key;
                newkey = newkey || key;
                if (excepts.includes(newkey)) {
                    key = newkey;
                }
                let reversevalue = value.reversevalue || ((v) => v);
                return {
                    ...c,
                    [key]: {
                        mapkey: newkey,
                        value: reversevalue(data[newkey], { collector: c, data: payload, mapper: value })
                    },
                };
            }, {});
            let filterMapData = Object.filter(mapdata, (v) => ![undefined].includes(v.value));
            let retData = Object.map(filterMapData, (v) => v.value);
            let filterMapKeys = Object.values(filterMapData).map(v => v.mapkey);
            let restData = Object.filter(payload, (v, k) => !filterMapKeys.includes(k));
            return {
                ...restData,
                ...retData
            };
        } else {
            return data;
        }
    },
};
window.cmMapcolumns = {
    ...(window.cmMapcolumns || {}),
    ...cmMapcolumns,
};
export default cmMapcolumns;
