// import projectmanagement from "./mokeresults/projectmanagement.moke";
export default {
	// projectmanagement,
	SCHOOLS_LIST: [
		{
			school_name: "school name",
			admin_name: "admin name",
			mobile_number: "9878767656",
			email_id: "test@example.com",
			onboarding_date: "1 Jan 2023",
			city: "Location",
			state: "TS",
		},
	],
	CLASS_LIST: [
		{
			class_name: "class A",
			class_level: "VI",
			class_description: "class description",
		},
		{
			class_name: "class B",
			class_level: "VII",
			class_description: "class description",
		},
		{
			class_name: "class C",
			class_level: "VIII",
			class_description: "class description",
		},
	],
};
