import React, { useState } from "react";
import LayoutWrapper from "./LayoutWrapper";
import GenericInfo from "./GenericInfo";
import ClassConfig from "./ClassConfig";
import ManageAccess from "./MangeAccess";
import SchoolAdminConfig from "./SchoolAdminConfig";
import SchoolCoordinatorConfig from "./SchoolCoordinatorConfig";
import Financials from "./Financials";

const AlterSchoolManagement = (props) => {
  console.log({ AlterSchoolManagement: props });
  const currentAdminRole = localStorage.getItem("currentAdminRole");
  const [goToStep, setGoToSteps] = useState('genericInfo');
  // const [loader, setLoader] = useState(false);

  const getBreadCums = () => {
      return `School Management / ${props.editData.school_name}`;
  }
  
  return (
    <>
        <LayoutWrapper
          title={`School Management`}
          back={getBreadCums()}
          backlink={`/schoolmanagement`}
        >
          <div className="row mb-3">
            <div className="col-md-12">
              <button
                className={`btn rounded-full border my-2 mx-1 min-w-[150px] m-clip ${
                    goToStep == "genericInfo" ? "tab-active" : ""
                }`}
                onClick={() => setGoToSteps("genericInfo")}
              >
                Generic Info.
              </button>
              <button
                className={`btn rounded-full border my-2 mx-1 min-w-[150px] m-clip ${
                    goToStep == "classInfo" ? "tab-active" : ""
                }`}
                onClick={() => setGoToSteps("classInfo")}
              >
                Class Info.
              </button>
              <button
                className={`btn rounded-full border my-2 mx-1 min-w-[150px] m-clip ${
                    goToStep == "manageAccess" ? "tab-active" : ""
                }`}
                onClick={() => setGoToSteps("manageAccess")}
              >
                Access Info.
              </button>
              <button
                className={`btn rounded-full border my-2 mx-1 min-w-[150px] m-clip ${
                    goToStep == "schoolAdminConfig" ? "tab-active" : ""
                }`}
                onClick={() => setGoToSteps("schoolAdminConfig")}
              >
                School Admin Info.
              </button>
              <button
                className={`btn rounded-full border my-2 mx-1 min-w-[150px] m-clip ${
                    goToStep == "schoolCoordinatorConfig" ? "tab-active" : ""
                }`}
                onClick={() => setGoToSteps("schoolCoordinatorConfig")}
              >
                School Coordinator Info.
              </button>
              {(currentAdminRole == "SUPER_ADMIN" && props.viewMode) &&
              <button
                className={`btn rounded-full border my-2 mx-1 min-w-[150px] m-clip ${
                    goToStep == "financials" ? "tab-active" : ""
                }`}
                onClick={() => setGoToSteps("financials")}
              >
                Financials
              </button>}
            </div>
          </div>

        {goToStep === 'genericInfo' && (
          <>
            <GenericInfo
              readMode={props.viewMode}
              selectedEditMode={props.editMode}
              selectedRowData={props.editData}
              showDataToEdit={props.showDataToEdit}
            />
          </>
        )}
        {goToStep === 'classInfo' && (
          <>
            <ClassConfig
              readMode={props.viewMode}
              selectedEditMode={props.editMode}
              selectedRowData={props.editData}
            />
          </>
        )}
        {goToStep === 'manageAccess' && (
          <>
            <ManageAccess
              readMode={props.viewMode}
              selectedEditMode={props.editMode}
              selectedRowData={props.editData}
            />
          </>
        )}
        {goToStep === 'schoolAdminConfig' && (
          <>
            <SchoolAdminConfig
              readMode={props.viewMode}
              selectedEditMode={props.editMode}
              selectedRowData={props.editData}
            />
          </>
        )}
        {goToStep === 'schoolCoordinatorConfig' && (
          <>
            <SchoolCoordinatorConfig
              readMode={props.viewMode}
              selectedEditMode={props.editMode}
              selectedRowData={props.editData}
            />
          </>
        )}
        {goToStep === 'financials' && (
          <>
            <Financials
              readMode={props.viewMode}
              selectedEditMode={props.editMode}
              selectedRowData={props.editData}
            />
          </>
        )}
      </LayoutWrapper>
    </>
  );
};

export default connect(AlterSchoolManagement);
