import React, { Component } from "react";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";

export class GenericInfo extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("GenericInfo");
    this.state = {
      editData: [],
      errorMessage: { name: "", message: "" },
      loader: false,
      sendAssessmentMail: false,
    };
    this.formFields = [
      {
        type: "text",
        name: "school_name",
        label: "School Name",
        required: true,
      },
      {
        type: "text",
        name: "mobile_number",
        label: "Contact Number",
        pattern: "[0-9]{10}",
        // onInvalid: (e) =>
        //   e.target.setError(
        //     "ex. 91-9XXXXXXXXX 10 digit phone number with country code."
        //   ),
        // onInput: (e) => e.target.setMessage(),
        required: true,
        onBlur: (e) => this.handleMobileNo(e),
      },
      {
        type: "select",
        name: "state",
        label: "State",
        placeholder: "Select",
        options: Object.keys(statecity).sort().options(),
        onChange: (value) => {
          // clear default value on change
          let cityinput = document.querySelector(
            "[name='appinput-city'].form-group"
          );
          let { values: cities = [] } =
            cityinput.vnode.refs.select2?.state;
          if (cities.length) {
            cityinput.vnode.refs.select2.clearAll();
          }
          // --------------------------------------------
          let city = this.formFields.find((o) => o.name == "city");
          city.options = Object.assign(
            city.options,
            statecity[value[0].key].sort().options()
          );
        },
        required: true,
      },
      {
        type: "select",
        name: "city",
        label: "City",
        placeholder: "Select",
        options: [],
        required: true,
      },
      {
        type: "date",
        name: "onboarding_date",
        label: "School Onboarding Date",
        placeholder: "select",
        defaultValue: new Date(),
        disabledDates: (date) => {
          return date.getTime() > moment().endOf('day');
        },
      },
      {
        type: "text",
        name: "website",
        label: "Website Link",
        onBlur: (e) => this.handleWebsiteLink(e),
      },
      {
        type: "text",
        name: "address",
        label: "Address",
        className: "address-field",
        required: true,
      },
      {
        type: "file",
        fileType: "school_document",
        name: "logo",
        label: "Upload Logo",
        placeholder: "Upload here",
      },
      {
        type: "file",
        fileType: "school_document",
        name: "MOU",
        label: "MOU Attachment",
        placeholder: "Upload here",
      },
      {
        type: "file",
        fileType: "school_document",
        name: "NDA",
        label: "NDA Attachment",
        placeholder: "Upload here",
      },
      {
        type: "text",
        name: "other_document_name",
        label: "Other Document Name",
      },
      {
        type: "file",
        fileType: "school_document",
        name: "OTHER",
        label: "Other Document Attachment",
        placeholder: "Upload here",
      },
      {
        type: "textarea",
        name: "notes",
        label: "Notes",
        customcolspan: "12",
      },
    ];
    this.role = localStorage.getItem("currentAdminRole");
  }

  handleWebsiteLink(e) {
    let urlValue = e.target.value;
    const urlRegex =
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm;
    if (!urlRegex.test(urlValue)) {
      this.setState({
        errorMessage: {
          name: "website",
          message: "Please enter valid website link",
        },
      });
    } else {
      this.setState({
        errorMessage: {
          name: "",
          message: "",
        },
      });
    }
  }

  handleMobileNo(e) {
    let Value = e.target.value;
    const urlRegex = /^[0]?[789]\d{9}$/;
    if (!urlRegex.test(Value)) {
    this.setState({
      errorMessage: {
        name: "mobile_number",
        message: "Please enter valid contact number",
      },
    });
  } else {
    this.setState({
      errorMessage: {
        name: "",
        message: "",
      },
    });
  }
  }
  
  handleCheckbox(e) {
    this.setState({
      sendAssessmentMail: e.target.checked,
    })
  }

  updateGenericInfo(event, data) {
    console.log({ editData: data });
    // let payload = Object.except(data, "onboarding_date");
    let documents = [];
    if (data?.MOU) {
      documents.push({
        type: "MOU",
        name: "MOU",
        attachment_id: data.MOU,
      });
    }
    if (data?.NDA) {
      documents.push({
        type: "NDA",
        name: "NDA",
        attachment_id: data.NDA,
      });
    }
    if (data?.OTHER) {
      documents.push({
        type: "OTHER",
        name: data.other_document_name,
        attachment_id: data?.OTHER || "",
      });
    }
    let urldata = {
      school_name: data.school_name,
      mobile_number: data.mobile_number,
      state: data.state,
      city: data.city,
      address: data.address,
      onboarding_date: data.onboarding_date,
      documents: documents,
      send_assessment_mail: this.state.sendAssessmentMail,
    };
    if (data?.website) urldata.website = data.website;
    if (data?.notes) urldata.notes = data.notes;
    if (data?.logo) urldata.logo = data.logo;
    this.setState({
      loader: true,
    });
    this.props.api
      .apiEditGenericInfo(urldata, {
        school_id:
          this.props.selectedRowData.school_id ||
          localStorage.getItem("school_id"),
      })
      .then((res) => {
        // console.log("apiEditGenericInfo: ", res);
        toast.success("Generic Info. Updated Successfully", {
          autoClose: 3000,
          closeButton: false,
          hideProgressBar: true,
        });
        this.props.showDataToEdit(this.props.selectedRowData.school_id);
        if (this.props.schoolStatus == "PENDING") {
          this.props.setGoSteps(1);
        }
      })
      .then(() => {
        this.setState({
          loader: false,
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
        });
      });
  }

  componentDidMount() {
    if (this.role != "SCHOOL_ADMIN")
      this.props.showDataToEdit(this.props.selectedRowData.school_id);
    else if (this.role == "SUPER_ADMIN")
      this.props.showDataToEdit(localStorage.getItem("school_id"));

    this.setState({
      sendAssessmentMail: this.props.selectedRowData.send_assessment_mail || this.state.sendAssessmentMail,
    })
  }

  componentDidUpdate() {}

  render() {
    let { formFields, role } = this;
    let { selectedRowData, selectedEditMode, readMode, schoolStatus } = this.props;
    console.log({selectedRowData,formFields});

    // if (selectedEditMode || readMode || role == "SCHOOL_ADMIN") {
      formFields = formFields.map((field) => {
        field.defaultValue = field.defaultValue;
        if (![undefined].includes(selectedRowData[field.name])) {
          if (field.type == "date") {
            field.defaultValue = selectedRowData[field.name].date();
          } else if(field?.name === "city"){
            let cityOptions = statecity[selectedRowData?.state].sort().options()
            field.options = cityOptions
            field.defaultValue = selectedRowData?.city;
          } else {
            field.defaultValue = selectedRowData[field.name];
          }
        }
         else if (
          field?.fileType === "school_document" &&
          (field.name === "MOU" || field.name === "NDA")
        ) {
          if (selectedRowData?.documents?.length > 0) {
            field.defaultValue =
              selectedRowData.documents.find((doc) => doc.name === field.name)
                ?.attachment_id || undefined;
          }
        } else if (
          field?.fileType === "school_document" && field.name === "OTHER"
        ) {
          if (selectedRowData?.documents?.length > 0) {
            field.defaultValue =
              selectedRowData.documents.find((doc) => doc.type === "OTHER")
                ?.attachment_id || undefined;
          }
        } else if (
          field?.type === "text" && field.name === "other_document_name"
        ) {
          if (selectedRowData?.documents?.length > 0) {
            field.defaultValue = selectedRowData.documents.find((doc) => doc.type === "OTHER")
            ?.name || undefined;
          }
        }
        return field;
      });
    // }

    return (
      <>
        {this.state.loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <ToastContainer position="top-right" />
        <div className="row m-auto">
          <AppForm
            className="flex flex-col gap-4 bg-white p-4"
            onSubmit={
              selectedEditMode || schoolStatus == "PENDING"
                ? (...args) => {
                    this.updateGenericInfo(...args);
                  }
                : (...args) => {
                    this.props.onGenericInfoSubmit(...args);
                  }
            }
          >
            <div className="row">
              {formFields?.map((field, i) => (
                <div
                  className={
                    field.fieldclass
                      ? field.fieldclass
                      : `col-12 col-md-6 col-lg-${field.customcolspan || 4}`
                  }
                  key={"field" + i}
                >
                  <AppInput
                    {...field}
                    disabled={
                      this.props.readMode || this.role == "SCHOOL_ADMIN"
                        ? true
                        : false
                    }
                  />
                  {this.state.errorMessage.name == field.name && (
                    <label className="invalid-feedback">
                      {this.state.errorMessage.message}
                    </label>
                  )}
                </div>
              ))}
              <div className="d-flex">
                <input
                  type="checkbox"
                  name="send_assessment_mail"
                  className="send-assessment-mail manage_check mt-1"
                  checked={this.state.sendAssessmentMail}
                  disabled={readMode}
                  onClick={(e) => this.handleCheckbox(e)}
                />
                <label htmlFor="send_assessment_mail" className="ms-2 mt-1">
                  Send Assessment E-mails to Students
                </label>
              </div>
            </div>
            {!this.props.readMode && role != "SCHOOL_ADMIN" && (
              <div className="row no-gutters pb-2 pt-4 pe-2">
                <>
                  <button
                    type="button"
                    onClick={() => location.reload()}
                    className="btn close-btn col col-md-1 ml-auto rounded-1 text-dark "
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn save-btn col col-md-1 ml-auto ms-2 me-2 rounded-1"
                  >
                    {this.props.selectedEditMode &&
                    this.props.schoolStatus != "PENDING"
                      ? "Update"
                      : "Save & Next"}
                  </button>
                </>
              </div>
            )}
          </AppForm>
        </div>
      </>
    );
  }
}

export default connect(GenericInfo);
