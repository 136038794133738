import React, { Component } from "react";
import { Link } from "react-router-dom";
import SchoolModal from "./SchoolModal";
import { toast, ToastContainer  } from "react-toastify";

export class SchoolAdminConfig extends Component {
    constructor(props) {
        super(props);
        props.initialconstruct.bind(this)("SchoolAdminConfig");
        this.state = {
          tableData: [],
          editData: {},
          viewMode: false,
          editMode: false,
          popupfields: {},
          popupdata: {},
          profileId: "",
          loader: false,
          errorMessage: { name: "", message: "" },
          search: {
            limit: 50,
            page: 1,
            order_by: "updatedAt",
            order_direction: "DESC",
          },
          modalFields: [
            {
              type: "text",
              name: "name",
              label: "Name",
              required: true,
              pattern: "^[A-Za-z ]+$",
              invalidmessage: "Please enter a valid name",
            },
            {
              type: "text",
              name: "email",
              label: "Email ID",
              required: true,
            },
            {
              type: "text",
              name: "mobile_number",
              label: "Mobile Number",
              pattern: "[0-9]{10}",
              onInvalid: (e) =>
                e.target.setError(
                  "ex. 91-9XXXXXXXXX 10 digit phone number with country code."
                ),
              onInput: (e) => e.target.setMessage(),
              required: true,
            },
            {
              type: "text",
              name: "user_id",
              label: "User ID",
              disabled: true,
            },
          ],
        };
        this.columns = [
            {
                dataIndex: "name",
                title: "Name",
            },
            {
                dataIndex: "email",
                title: "Email ID",
            },
            {
                dataIndex: "mobile_number",
                title: "Mobile Number",
            },
            {
                dataIndex: "user_id",
                title: "User ID",
            }
        ];
        this.schoolAdminModalRef = React.createRef();
        this.role = localStorage.getItem("currentAdminRole");
    }

    sumitForm(event, data) {
        console.log("add generic info: ", data);
    }

    addModalFields() {
        let updatedFields = this.state.modalFields.filter((o) => o.name != "user_id");
        let fields = (updatedFields || []).reduce(
            (o, n) => ({ ...o, [n.name]: n }),
            {}
        );
        let formdata = Object.map(fields, (v, k) => "");
        // console.log("formdata: ", fields, formdata);
        this.setState({
            popupfields: fields,
            popupdata: formdata,
        });
    }

    onNext() { }

    onPrev() { }

    onPageChange(pageData) {
        this.setState(
            {
                search: {
                    ...this.state.search,
                    page: pageData.current,
                    limit: pageData.pageSize || pageData.defaultPageSize || 25,
                },
            },
            () => this.fetchSchoolAdminInfo()
        );
    }

    deleteAction(value) {
        const school_id = (this.props.selectedEditMode || this.props.readMode) ?
        this.props.selectedRowData.school_id : localStorage.getItem("school_id");
        let payload = {
            school_id: school_id,
            profile_id: value.profile_id,
        };
        confirm(`Do you really want to delete these admin?`).promise.then(() => {
            this.props.api.apiDeleteSchoolAdmin(payload).then(() => {
              this.fetchSchoolAdminInfo();
            }).catch((error) => {
                console.log('error', error);
            });
          });
    }

    editAction(v) {
        // console.log("edit: ", v);
        const updatedEditFields = [...this.state.modalFields];
          const emailIdIndex = updatedEditFields.findIndex(
            (field) => field.name === "email"
          );
          if (emailIdIndex !== -1) {
            updatedEditFields[emailIdIndex] = {
              ...updatedEditFields[emailIdIndex],
              disabled: v.email ? true : false,
            };
          }
        let fields = (updatedEditFields || []).reduce(
            (o, n) => ({ ...o, [n.name]: n }),
            {}
        );
        let updatedField = Object.except(v, ["school_id", "createdAt", "updatedAt", "user_id", "role", "profile_id"]);
        let userIdField = Object.only(v, ["user_id"]);
        let popupdata = {
            ...updatedField,
            ...userIdField,
        }
        this.openSchoolAdminModal();
        this.setState({
            popupfields: fields,
            popupdata: popupdata,
            editMode: true,
            viewMode: false,
            editData: v,
            profileId: v.profile_id,
        });
    }

    viewAction(v) {
        // console.log("view: ", v);
        let updatedField = Object.except(v, ["school_id", "createdAt", "updatedAt", "user_id", "role", "profile_id"]);
        let userIdField = Object.only(v, ["user_id"]);
        let popupdata = {
            ...updatedField,
            ...userIdField,
        }
        this.openSchoolAdminModal();
        this.setState({
            popupdata: popupdata,
            editMode: false,
            viewMode: true,
            editData: v,
            profileId: v.profile_id,
        });
    }

    openSchoolAdminModal() {
        this.schoolAdminModalRef.current.click();
    }
    closeModal() {
        document.querySelector("#add_school_admin_modal #bsmodalclose").click();
    }

    schoolAdminConfigUpdated(text) {
        toast.success(text, {
          autoClose: 3000,
          closeButton: false,
          hideProgressBar: true,
        });
    }

    handleSubmit(event, data, form) {
        event.preventDefault();
        // console.log("formData", data, form);
        this.setState({ loader: true })
        if (this.state.editMode) {
            let payload = {
                name: data.name,
                email: data.email,
                mobile_number: data.mobile_number,
            };
            this.props.api
            .apiEditSchoolAdminById(payload, {profile_id: this.state.profileId})
            .then(() => {
                this.setState({
                  loader: false
                })
                this.schoolAdminConfigUpdated("School Admin Info. Updated Successfully");
                this.closeModal();
                this.fetchSchoolAdminInfo();
            }).catch((err) => {
                this.setState({
                  loader: false
                })
              });
        } else {
            const school_id = (this.props.selectedEditMode || this.props.readMode) ? this.props.selectedRowData.school_id : localStorage.getItem("school_id");
            let payload = {
                school_id: school_id,
                name: data.name,
                email: data.email,
                mobile_number: data.mobile_number,
              };
            this.props.api.apiAddSchoolAdminInfo(payload).then((response) => {
                console.log({apiAddSchoolAdminInfo: response});
                this.setState({ loader: false });
                this.schoolAdminConfigUpdated("School Admin Configured Successfully");
                this.closeModal();
                this.fetchSchoolAdminInfo();
            }).catch((error) => {
                console.error("Error:", error);
                this.closeModal();
                this.setState({ loader: false });
            });
        }
    }

    fetchSchoolAdminInfo() {
        this.setState({ loader: true })
        let school_id = "";
        if(this.role == "SCHOOL_ADMIN") {
            school_id = localStorage.getItem("school_admin_id");
        } else {
            school_id = (this.props.selectedEditMode || this.props.readMode) ? this.props.selectedRowData.school_id : localStorage.getItem("school_id");
        }
        let payload ={
            ...this.state.search,
        }
        this.props.api.apiGetSchoolAdminsById(payload, {school_id: school_id})
        .then((response) => {
            console.log({apiGetSchoolAdminsById: response.data});
            if(response.data && response.data.length !== 0) {
                this.setState({
                    tableData: response.data[0].records,
                    loader: false,
                });
            }
        }).catch((error) => {
            console.log('error', error);
            this.setState({ loader: false })
        });
    }

    componentDidMount() {
        this.fetchSchoolAdminInfo();
    }

    render() {
        return (
            <>
            {this.state.loader && <div className="loader-container">
					<div className="spinner"></div>
				</div>}
            <ToastContainer position="top-right" />
            <div className="bg-white p-3">
                <div className="row">
                    {/* <div className="col"></div> */}
                    {!this.props.readMode && localStorage.getItem("currentAdminRole") == "SUPER_ADMIN" && (
                        <div className="my-4">
                            <Link
                                ref={this.schoolAdminModalRef}
                                data-bs-toggle="modal"
                                data-bs-target="#add_school_admin_modal"
                                onClick={(...args) => this.addModalFields(...args)}
                                className="btn add-btn me-2 mt-1"
                                // onClick={(...args) => this.onAddSchoolAdminInfo(...args)}
                            >
                                <i className="fa fa-plus"></i>
                                Add School Admin
                            </Link>
                        </div>
                    )}
                </div>
                <div className="row schoolmanagement">
                    <div className="col-12">
                        {!!this.state.tableData.length && (
                            <AppTable
                                data={this.state.tableData}
                                columns={this.columns}
                                onNext={() => this.onNext()}
                                onPrev={() => this.onPrev()}
                                onChange={(...arg) => this.onPageChange(...arg)}
                                // total={rowcount}
                                reorder={true}
                                deleteAction={(v) => this.deleteAction(v)}
                                editAction={(v) => this.editAction(v)}
                                viewAction={(v) => this.viewAction(v)}
                                // deactivatable={this.props.readMode ? false : true}
                                deletable={this.props.readMode || this.role == "SCHOOL_ADMIN" ? false : true}
                                editable={this.props.readMode || this.role == "SCHOOL_ADMIN" ? false : true}
                                viewable={this.props.readMode || this.role == "SCHOOL_ADMIN" ? true : false}
                                // viewable={false}
                                targetType="tap"
                                showPagination={false}
                            ></AppTable>
                        )}
                        {this.state.tableData.length == 0 && <div className="empty_layout"></div>}
                    </div>
                </div>
                <div className="row m-auto">
                    <AppForm
                        className="flex flex-col gap-4 bg-white pb-0 pt-4 pe-2"
                    >
                        {((!this.props.readMode && !this.props.selectedEditMode && this.role != "SCHOOL_ADMIN") || this.props.schoolStatus == "PENDING") && <div className="row no-gutters m-2 mb-4">
                            <button className="btn close-btn col col-md-1 ml-auto rounded-1 text-dark "
                            onClick={() => {this.props.setGoSteps(2)}}>
                                Previous
                            </button>
                            <button
                                type="submit"
                                className="btn save-btn col col-md-1 ml-auto ms-2 me-2 rounded-1"
                                onClick={() => {this.props.onSchoolAdminConfigSubmit()}}
                            >
                                Save & Next
                            </button>
                        </div>}
                    </AppForm>
                </div>
                <SchoolModal
                    id="add_school_admin_modal"
                    title={`${this.state.editMode ? "Edit School Admin Details" : this.state.viewMode ? "View School Admin Details" : "Add School Admin"}`}
                    submitText={`${this.state.editMode ? "Update" : "Submit"}`}
                    editdata={this.state.editData}
                    data={this.state.popupdata}
                    fields={this.state.popupfields}
                    disableallfields={this.state.viewMode ? true : false}
                    // disablefields={["email"]}
                    onSubmit={(...args) => this.handleSubmit(...args)}
                    submitButton={this.state.viewMode ? false : true}
                    closeButton={this.state.viewMode ? true : false}
                    onClose={() =>
                        this.setState({
                            popupdata: {},
                            editData: {},
                            editMode: false,
                            viewMode: false,
                        })
                    }
                />
                {(!this.props.readMode && this.props.selectedEditMode && this.props.schoolStatus != "PENDING") && <div className="row m-auto">
                    <AppForm className="flex flex-col gap-4 bg-white pt-0 pb-2">
                        <div className="row no-gutters m-2 mb-4">
                            <button
                                className="btn close-btn col col-md-1 ml-auto rounded-1 text-dark "
                                onClick={() => location.reload()}
                            >Cancel
                            </button>
                        </div>
                    </AppForm>
                </div>}
                {/* below button hidden on UI */}
                <button
                    className="btn btn-sm btn-outline-secondary d-none"
                    onClick={(...args) => this.addModalFields(...args)}
                    ref={this.schoolAdminModalRef}
                    data-bs-toggle="modal"
                    data-bs-target="#add_school_admin_modal"
                >
                <span>Hidden Button</span>
                </button>
            </div>
            </>
        );
    }
}

export default connect(SchoolAdminConfig);
