import React, { Component } from "react";
import { Link } from "react-router-dom";
import LayoutWrapper from "../LayoutWrapper";
import AppInput from "../../../partials/AppInput";

export class SchoolAdmin extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("SchoolManagement");
    this.state = {
      data: [],
      search: {
        limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
      editData: {},
    };
    this.videosData = [
      {
        dataIndex: "school_name",
        title: "School Name",
      },
      {
        dataIndex: "admin_name",
        title: "Admin Name",
      },
      {
        dataIndex: "mobile_number",
        title: "Mobile Number",
      },
    ];
    this.successfulModalRef = React.createRef();
  }

  componentDidMount() {
    this.fetchList();
  }

  fetchList() {
    this.props.api.apiSchoolDataMoke().then((res) => {
      console.log("school data: ", res);
      this.setState({
        data: res,
      });
    });
  }

  downloadFile() {}

  onNext() {}

  onPrev() {}

  handleSubmit(e) {}

  render() {
    let { data = [], rowcount, editData } = this.state;
    let { columns } = this;
    return (
      <LayoutWrapper
        title="VII Grade - Level 2"
        back="Content Delivery / VII Grade - Level 2 / Conceptual / Session -1"
      >
        <div className="row schoolmanagement">
          <div className="col-md-12">
            <div className="assignment-tab">
              <Link className="btn add-btn">Assignment 1</Link>
              <Link className="btn add-btn">Assignment 2</Link>
            </div>
          </div>
          <div className="col-12">
            <div className="assignment-section">
              <div className="row">
                <div className="col-md-12">
                  <div className="question-section">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="question-label">
                          1. Select the three most important values you consider
                          a person should have.
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="answer-section">
                          <div className="checkbox">
                            <input
                              type="checkbox"
                              id="completed1"
                              name="completed1"
                              value="completed"
                              className="form-check-input"
                            />
                            <label for="completed1" className="check-label">
                              Honesty
                            </label>
                          </div>
                          <div className="checkbox">
                            <input
                              type="checkbox"
                              id="completed2"
                              name="completed2"
                              value="completed"
                              className="form-check-input"
                            />
                            <label for="completed2" className="check-label">
                              Honesty
                            </label>
                          </div>
                          <div className="checkbox">
                            <input
                              type="checkbox"
                              id="completed3"
                              name="completed3"
                              value="completed"
                              className="form-check-input"
                            />
                            <label for="completed3" className="check-label">
                              Honesty
                            </label>
                          </div>
                          <div className="checkbox">
                            <input
                              type="checkbox"
                              id="completed4"
                              name="completed4"
                              value="completed"
                              className="form-check-input"
                            />
                            <label for="completed4" className="check-label">
                              Honesty
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="question-section">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="question-label">
                          2. Select the three most important values you consider
                          a person should have.
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="answer-section">
                          <AppInput
                            {...{
                              type: "textarea",
                              name: "",
                              label: "",
                              required: true,
                            }}
                          ></AppInput>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="question-section">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="question-label">
                          3. Select the three most important values you consider
                          a person should have.
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="answer-section">
                          <div className="checkbox">
                            <input
                              type="checkbox"
                              id="completed3"
                              name="completed3"
                              value="completed"
                              className="form-check-input"
                            />
                            <label for="completed3" className="check-label">
                              Honesty
                            </label>
                          </div>
                          <div className="checkbox">
                            <input
                              type="checkbox"
                              id="completed3"
                              name="completed3"
                              value="completed"
                              className="form-check-input"
                            />
                            <label for="completed3" className="check-label">
                              Honesty
                            </label>
                          </div>
                          <div className="checkbox">
                            <input
                              type="checkbox"
                              id="completed3"
                              name="completed3"
                              value="completed"
                              className="form-check-input"
                            />
                            <label for="completed3" className="check-label">
                              Honesty
                            </label>
                          </div>
                          <div className="checkbox">
                            <input
                              type="checkbox"
                              id="completed3"
                              name="completed3"
                              value="completed"
                              className="form-check-input"
                            />
                            <label for="completed3" className="check-label">
                              Honesty
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <Link
                    className="btn add-btn"
                    ref={this.successfulModalRef}
                    data-bs-toggle="modal"
                    data-bs-target="#success_modal"
                  >
                    Submit
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(SchoolAdmin);
