import React, { Component } from "react";
import SubModuleModal from "./SubModuleModal";
import LayoutWrapper from "./LayoutWrapper";
import { Link } from "react-router-dom";
import { toast, ToastContainer  } from "react-toastify";

export class SubModules extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("SubModules");
    this.submoduleModalRef = React.createRef();
    this.state = {
      tableData: [],
      rowcount: 0,
      search: {
        limit: 50,
        page: 1,
        order_by: "curriculum_level",
        order_direction: "ASC",
      },
      editData: {},
      viewMode: false,
      editMode: false,
      popupfields: {},
      popupdata: {},
      loader: false,
      subModuleId: '',
      modalFields: [
        {
          type: "select",
          name: "curriculum_id",
          label: "Grade",
          options: [],
          placeholder: "Select Grade",
          customcolspan: "col-12"
        },
        {
          type: "select",
          name: "module_id",
          label: "Module",
          options: [],
          placeholder: "Select Module",
          required: true,
          customcolspan: "col-12"
        },
        {
          type: "text",
          name: "sub_module_name",
          label: "Sub Module Name",
          placeholder: "Enter Name here",
          required: true,
          customcolspan: "col-12"
        }
      ],
      columns: [
        {
          dataIndex: "curriculum_grade",
          title: "Grade",
        },
        {
          dataIndex: "module_name",
          title: "Module",
        },
        {
          dataIndex: "sub_module_name",
          title: "Sub Module Name",
        },
      ]
    };
  }

  componentDidMount() {
    this.fetchClassList();
    this.fetchAllModules();
    this.fetchSubModule();
  }

  addModalFields() {
    let fields = (this.state.modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    this.setState({
      popupfields: fields,
      popupdata: formdata,
    });
  }

  fetchClassList() {
    this.setState({ loader: true })
    this.props.api.apiGetClasses().then(({ data, total }) => {
      data = data.sort((a, b) => a.curriculum_level - b.curriculum_level);
      let classNames = this.state.modalFields.find((o) => o.name == "curriculum_id");
      classNames.options = data?.options("curriculum_id", "curriculum_grade");
      this.setState({
        modalFields: [...this.state.modalFields],
        loader: false,
      })
    }).catch((error) => {
      this.setState({ loader: false })
    });
  }

  fetchAllModules = () => {
    this.setState({ loader: true })
    this.props.api.apiGetAllModules({school_id: "", class_id: ""}).then((res) => {
      let classNames = this.state.modalFields.find((o) => o.name == "module_id");
      classNames.options = res.data[0]?.records?.options("module_id", "module_name");
      this.setState({
        modalFields: [...this.state.modalFields],
        loader: false,
      })
    }).catch(() => {
      this.setState({ loader: false })
    });
  }

  fetchSubModule() {
    this.setState({ loader: true })
    let payload = { 
      ...this.state.search
    }
    this.props.api.apiGetSubModules(payload).then((res) => {
      console.log({apiGetSubModules: res.data[0]?.records});
      if(res.data && res.data[0].records.length !== 0) {
        this.setState({
            tableData: res.data[0].records,
            rowcount: res.data[0].pagination[0].totalRecords,
            loader: false,
        });
      }
    }).catch(() => {
      this.setState({ loader: false })
    })
  }

  onNext() {}

  onPrev() {}

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      () => this.fetchSubModule()
    );
  }

  editAction(v) {
    console.log("edit: ", v);
    this.openModal();
    this.setState({
      subModuleId: v.sub_module_id,
    })
    const updatedEditFields = [...this.state.modalFields];
    const fieldsToDisable = ["curriculum_id", "module_id"];
    fieldsToDisable.forEach((fieldName) => {
      const fieldIndex = updatedEditFields.findIndex(
        (field) => field.name === fieldName
      );
      if (fieldIndex !== -1) {
        updatedEditFields[fieldIndex] = {
          ...updatedEditFields[fieldIndex],
          disabled: true,
        };
      }
    });

    let fields = (updatedEditFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );

    let popupdata = {
      curriculum_id: v.curriculum_id,
      module_id: v.module_id,
      sub_module_name: v.sub_module_name,
    };

    this.setState({
      popupfields: fields,
      popupdata: popupdata,
      editMode: true,
      viewMode: false,
      editData: v,
    });
  }


  onSearch() {
    // console.log("onSearch data: ", data);

    let data = this.refs.form.form.current.getData();
    data = Object.filter(data, (val) => val);
    // call search api here
    this.setState({ search: data }, () => {
      this.fetchSubModule();
    });
  }

  clearTextData() {
    this.refs.form.form.current.reset();
  }

  openModal() {
    this.submoduleModalRef.current.click();
  }

  closeModal() {
    document.querySelector("#submodules_modal #bsmodalclose").click();
  }

  handleSubmit(event, data, form) {
    event.preventDefault();
    // console.log("formData", data, form);
    this.setState({ loader: true })
    if (this.state.editMode) {
      let payload = {
          curriculum_id: data.curriculum_id,
          module_id: data.module_id,
          sub_module_name: data.sub_module_name,
      }
        this.props.api.apiEditSubModules(payload, { sub_module_id: this.state.subModuleId })
          .then(() => {
            this.setState({
              loader: false,
            });
            this.closeModal();
            this.fetchSubModule();
          }).catch((error) => {
            console.error("Error:", error);
            this.setState({
              loader: false,
            });
          });
    } else {
      let payload = {
        ...data,
      };
      this.props.api.apiAddSubModules(payload).then((response) => {
        console.log({apiAddSubModules: response});
        this.setState({
          loader: false,
        });
        this.closeModal();
        this.fetchSubModule();
      }).catch((error) => {
        // console.error("Error:", error);
        this.setState({
          loader: false,
        });
        toast.error(error.response.data.issues.message, {
          autoClose: 3000,
          closeButton: false,
          hideProgressBar: true,
      });
      });
    }
  }

  updateFields(fields) {
    return fields;
  }
  render() {
    let {
      tableData = [],
      rowcount,
      editData,
      popupdata,
      popupfields,
      editMode,
      viewMode,
      loader,
      columns,
      modalFields,
    } = this.state;
    modalFields = this.updateFields(modalFields);
    return (
      <>
        {loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <ToastContainer position="top-right" />
        <LayoutWrapper title="Sub Modules" back="Sub Modules">
          <div className="row">
            <div className="col"></div>
            <div className="mb-4">
              <Link
                className="btn add-btn"
                ref={this.submoduleModalRef}
                data-bs-toggle="modal"
                data-bs-target="#submodules_modal"
                onClick={(...args) => this.addModalFields(...args)}
              >
                <i className="fa fa-plus"></i>
                Add Sub Module
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {!!tableData.length && (
                <AppTable
                  data={tableData}
                  columns={columns}
                  onNext={() => this.onNext()}
                  onPrev={() => this.onPrev()}
                  onChange={(...arg) => this.onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  editAction={(v) => this.editAction(v)}
                  viewable={false}
                  deletable={false}
                  editable={true}
                  targetType="tap"
                ></AppTable>
              )}
              {tableData.length == 0 && <div className="submodule_layout"></div>}
            </div>
          </div>
          <SubModuleModal
            id="submodules_modal"
            title={`${editMode ? "Edit Sub Module Details" : viewMode ? "View Sub Module Details" : "Add Sub Module"}`}
            submitText={`${editMode ? 'Update' : 'Submit'}`}
            editdata={editData}
            data={popupdata}
            fields={popupfields}
            contentClass="subModuleModal"
            disableallfields={viewMode ? true : false}
            onSubmit={(...args) => this.handleSubmit(...args)}
            submitButton={viewMode ? false : true}
            closeButton={viewMode ? true : false}
            onClose={() =>
              this.setState({
                popupdata: {},
                editData: {},
                editMode: false,
                viewMode: false,
              })
            }
          />
        </LayoutWrapper>
      </>
    );
  }
}

export default connect(SubModules);
