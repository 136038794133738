import schoolmanagementapi from "./devapis/schoolmanagementapi";
import contentmanagementapi from "./devapis/contentmanagementapi"

export const devapis = {
  ...schoolmanagementapi,
  ...contentmanagementapi,

  login: {
    url: "/api/v1/auth/login",
    method: "post",
  },
  refreshToken: {
    url: "/api/v1/auth/refresh",
    method: "post",
  },
  forgetpassword: {
    url: "/api/v1/auth/forget",
    method: "post",
  },
  resetStudentPassword: {
    url: "/api/v1/auth/student_forget",
    method: "post",
  },
  changepassword: {
    url: "/api/v1/auth/password",
    method: "put",
  },

  getDashboardBarChartData: {
    url: "/api/v2/dashboard/school_progress/{school_id}",
    method: "get",
  },
  getSessionProgressBarChart: {
    url: "/api/v2/dashboard/session_progress/?month={month}&year={year}&school_id={school_id}&class_id={class_id}&module_id={module_id}",
    method: "get",
  },
  getDashboard: {
    url: "/api/v2/dashboard/?school_id={school_id}",
    method: "get",
  },
  otpLogin: {
    url: "/api/v1/auth/otp/{mobile_num}",
    method: "get",
  },
  otpLoginVerify: {
    url: "/api/v1/auth/verify",
    method: "post",
  },
  downloadFile: {
    url: `/api/v1/download-documents`,
    method: "post",
  },
  // PROFILES
  createProfile: {
    url: "/api/v1/profile",
    method: "post",
  },
  getProfile: {
    url: "/api/v1/profile",
    method: "get",
  },
  getUser: {
    url: "/api/v1/profile/me",
    method: "get",
  },
  getProfileById: {
    url: "/api/v1/profile/{profile_id}",
    method: "get",
  },
  updateProfileById: {
    url: "/api/v1/profile/{profile_id}",
    method: "put",
  },
  deleteProfile: {
    url: "/api/v1/profile/{profile_id}",
    method: "delete",
  },
};

export default devapis;