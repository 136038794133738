import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

const ChangePassword = (props) => {
  const {
    control: control2,
    handleSubmit: handleChangePasswordSubmit,
    setError: setError2,
    formState: { errors2 },
  } = useForm();

  const togglePasswordField = (field, toggleButton) => {
    field.type = field.type === "password" ? "text" : "password";
    toggleButton.classList.toggle("fa-eye", field.type === "text");
    toggleButton.classList.toggle("fa-eye-slash", field.type !== "text");
  };
  
  const toggleSwitchPassword = () => {
    const fields = ["oldPassword", "newPassword", "confirmPassword"];
    const toggles = ["oldTogglePassword", "newTogglePassword", "confirmTogglePassword"];
  
    fields.forEach((fieldId, index) => {
      const field = document.querySelector(`#${fieldId}`);
      const toggle = document.querySelector(`#${toggles[index]}`);
  
      if (toggle.listener) {
        toggle.removeEventListener("click", toggle.listener);
      }
  
      toggle.listener = () => togglePasswordField(field, toggle);
      toggle.addEventListener("click", toggle.listener);
    });
  };

  const onPasswordSubmit = (data) => {
    console.log("onPasswordSubmit", data);
    let payload = {};

    if (data.new_password.localeCompare(data.confirm_password) == 0) {
      payload = {
        old_password: data.old_password,
        new_password: data.new_password,
      };
      console.log({ payload });
      props.api
        .apiChangePassword(payload)
        .then((res) => {
          console.log({ res });
          success("Password Changes Successfully");
        })
        .then(() => {
          props.navigate("/open/login");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("Confirm Password is not same as New Password");
    }
  };
  return (
    <>
      <Helmet>
        <title>Reset Password - CognoSpace</title>
        <meta name="description" content="Reset Password page" />
      </Helmet>
      <div className="account-content">
        <div className="container">
          {/* /Account Logo */}
          <div className="account-box">
            <div className="account-wrapper">
              {/* Account Logo */}
              <div className="py-3 d-flex justify-content-center">
                <Link to={"/open/login"}>
                    <img src={imagepaths.CognoSpaceLogoDark} alt="Logo" width="155px" />
                </Link>
              </div>
              <h3 className="account-title">Reset Password</h3>

              {/* Account Form */}
              <form
                className="email_form"
                key={2}
                onSubmit={handleChangePasswordSubmit(onPasswordSubmit)}
              >
                <>
                  <div className="form-group">
                    <label>Old Password</label>
                    <Controller
                      name="old_password"
                      control={control2}
                      render={({ field: { value, onChange } }) => (
                      <div className="password-container">
                        <input
                          className={`form-control  ${
                            errors2?.required ? "error-input" : ""
                          }`}
                          type="password"
                          id="oldPassword"
                          value={value}
                          onChange={onChange}
                          autoComplete="false"
                          placeholder="Enter Old Password"
                          required={true}
                        />
                        <i
                        class="fa fa-eye-slash  togglePassword"
                        id="oldTogglePassword"
                        onMouseEnter={() => toggleSwitchPassword()}
                        ></i>
                      </div>
                      )}
                    />
                    <small>{errors2?.required?.message}</small>
                  </div>
                  <div className="form-group">
                    <label>New Password</label>
                    <Controller
                      name="new_password"
                      control={control2}
                      render={({ field: { value, onChange } }) => (
                      <div className="password-container">
                        <input
                          className={`form-control  ${
                            errors2?.required ? "error-input" : ""
                          }`}
                          type="password"
                          id="newPassword"
                          value={value}
                          onChange={onChange}
                          autoComplete="false"
                          placeholder="Enter New Password"
                          required={true}
                        />
                        <i
                        class="fa fa-eye-slash togglePassword"
                        id="newTogglePassword"
                        onMouseEnter={() => toggleSwitchPassword()}
                        ></i>
                      </div>
                      )}
                    />
                    <small>{errors2?.required?.message}</small>
                  </div>
                  <div className="form-group">
                    <label>Confirm Password</label>
                    <Controller
                      name="confirm_password"
                      control={control2}
                      render={({ field: { value, onChange } }) => (
                        <div className="password-container">
                        <input
                          className={`form-control  ${
                            errors2?.required ? "error-input" : ""
                          }`}
                          type="password"
                          id="confirmPassword"
                          value={value}
                          onChange={onChange}
                          autoComplete="false"
                          placeholder="Enter Confirm Password"
                          required={true}
                        />
                        <i
                          class="fa fa-eye-slash togglePassword"
                          id="confirmTogglePassword"
                          onMouseEnter={() => toggleSwitchPassword()}
                        ></i>
                        </div>
                      )}
                    />
                    <small>{errors2?.required?.message}</small>
                  </div>
                  <div className="form-group text-center">
                    <button
                      className="btn btn-primary account-btn"
                      type="submit"
                    >
                      Reset Password
                    </button>
                  </div>
                </>
              </form>
              {/* /Account Form */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(ChangePassword);
