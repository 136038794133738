import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import OtpInput from "react-otp-input";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./otp.scss";

export class OtpPage extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("OtpPage");
    this.state = {
      otp: "",
      verifyDisabled: true,
      otpStoreValues: {},
    };
  }

  componentDidMount() {
    let storedOTP = window.store.getState().root.OTPresponses;
    this.setState({ otpStoreValues: storedOTP });

    let remainingTime = new Date(`${storedOTP.expires_in}`).getSeconds();
    console.log(remainingTime);

    let timeInMilliseconds = remainingTime * 1000;

    if (timeInMilliseconds) {
      this.handleOTPtimeout(
        timeInMilliseconds, // milliseconds
        function (timeleft) {
          if (document.querySelector("#otpTimeout")) {
            // called every step to update the visible countdown
            document.querySelector("#otpTimeout").innerHTML =
              timeleft < 10 ? `00:0${timeleft} Sec` : `00:${timeleft} Sec`;
          }
        },
        function () {
          // what to do after
          if (document.querySelector("#otpTimeout")) {
            document.querySelector(
              "#otpTimeout"
            ).innerHTML = `<span class="text-danger">OTP expired</span>`;
          }
        }
      );
    }
  }

  // componentWillUnmount() {
  //   document.querySelector("#otpTimeout").innerHTML = "00:00";
  // }

  handleOTPtimeout(time, update, complete) {
    var start = new Date().getTime();
    var interval = setInterval(function () {
      var now = time - (new Date().getTime() - start);
      if (now <= 0) {
        clearInterval(interval);
        complete();
      } else update(Math.floor(now / 1000));
    }, 100); // the smaller this number, the more accurate the timer will be
  }

  handleChange(otp) {
    // console.log("otp changed: ", otp.length);
    this.setState({ otp });
    if (otp.length === 6) {
      this.setState({
        otp,
        verifyDisabled: false,
      });
    } else {
      this.setState({
        otp,
        verifyDisabled: true,
      });
    }
  }
  handleOTPverify(e) {
    // console.log("handleOTPverify", this.state.otp);

    console.log("OTPresponses", this.state.otpStoreValues);

    let data = {
      otp: this.state.otp,
      message_id: this.state.otpStoreValues.message_id,
    };

    this.props.api.apiOTPLoginVerify(data).then((res) => {
      console.log({ handleOTPverify: res });
      this.props.props.setstore("logged", res.logged);
      this.props.setstore("auth", res.auth);
      this.props.setstore("user", res.user);
      navigate("/welcome");
    });
  }
  render() {
    let { otp, verifyDisabled } = this.state;
    return (
      <>
        <Helmet>
          <title>Register - CognoSpace</title>
          <meta name="description" content="Login page" />
        </Helmet>
        <div className="account-content">
          <div className="container">
            <div className="account-box">
              <div className="account-wrapper">
                {/* Account Logo */}
                <div className="account-logo">
                  <Link to="/open/login">
                    <img src={imagepaths.Applogo} alt="" />
                  </Link>
                </div>
                <h3 className="account-title mb-5">Enter OTP</h3>
                {/* Account Form */}
                <div>
                  <span className="d-block mx-auto mt-3 mb-0 otp_top_text">
                    OTP to verify
                  </span>
                  <OtpInput
                    value={otp}
                    onChange={(...args) => this.handleChange(...args)}
                    numInputs={6}
                    separator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                    containerStyle="otp_container"
                  />
                  <p className="otp_timer" id="otpTimeout">
                    00:00
                  </p>

                  <div className="form-group text-center mb-3">
                    <button
                      className="btn btn-primary account-btn w-50 mx-auto"
                      type="button"
                      onClick={(e) => this.handleOTPverify(e)}
                      disabled={verifyDisabled}
                    >
                      Verify
                    </button>
                  </div>
                  <div className="account-footer">
                    <p className="text-black text-center">
                      Not received OTP?
                      <Link to="/open/login"> Resend</Link>
                    </p>
                  </div>
                </div>
                {/* /Account Form */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(OtpPage);
