import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { BreadCum } from "../../../../layout/admin";

export class LayoutWrapper extends Component {
  render() {
    let { props } = this;
    let { title, back = "Dashboard", className = "", backlink } = props;
    let attrs = Object.except(props, ["title", "back", "className"]);
    return (
      <div className={"content container-fluid relative" + className}>
        <Helmet>
          <title>{title} - CognoSpace</title>
          <meta name="description" content="Login page" />
        </Helmet>
        <div className="page-header">
          <div className="row align-items-center">
            <BreadCum title={title} back={back} backlink={backlink} />
          </div>
        </div>
        {props.children}
      </div>
    );
  }
}

export default connect(LayoutWrapper);
