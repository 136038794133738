import React, { Component } from "react";
import InvoiceModal from "./InvoiceModal";
import LayoutWrapper from "./LayoutWrapper";
import { Link } from "react-router-dom";
import InvoiceSearch from "./InvoiceSearch";
import InvoicePayModal from "./InvoicePayModal";
import { toast, ToastContainer } from "react-toastify";

export class InvoiceForm extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("InvoiceForm");
    this.invoiceModalRef = React.createRef();
    this.editInvoiceModalRef = React.createRef();
    this.payInvoiceModalRef = React.createRef();
    this.state = {
      data: [],
      rowcount: 0,
      search: {
        limit: 50,
        page: 1,
        order_by: "updatedAt",
        order_direction: "DESC",
      },
      editData: {},
      viewMode: false,
      editMode: false,
      popupfields: {},
      popupdata: {},
      loader: false,
      invoiceId: "",
      popupPayData: {},
      popupPayfields: [
        {
          type: "select",
          name: "school_name",
          label: "School Name",
          placeholder: "Select",
          options: [],
          customcolspan: "col-lg-12",
          required: true,
        },
        {
          type: "text",
          name: "invoice_amount",
          label: "Invoice Amount",
          placeholder: "Enter amount in ₹",
          required: true,
        },
      ],
      modalFields: [
        {
          type: "select",
          name: "school_id",
          label: "School Name",
          placeholder: "Select",
          options: [],
          required: true,
        },
        {
          type: "date",
          name: "invoice_date",
          label: "Invoice Date",
          placeholder: "Current Date",
          required: true,
        },
        {
          type: "amount",
          name: "invoice_amount",
          label: "Invoice Amount",
          placeholder: "Enter amount in ₹",
          required: true,
        },
        {
          type: "file",
          fileType: "school_document",
          accept: "application/pdf",
          filename: "File", // find type = file & pass file name here
          name: "attachment",
          label: "Upload Invoice",
          placeholder: "Upload",
          required: true,
        },
      ],
      editFields: [
        {
          type: "textarea",
          name: "comment",
          label: "Comment",
          customcolspan: "col-lg-12",
        },
        {
          type: "select",
          name: "payment_mode",
          label: "Payment Mode",
          placeholder: "Select",
          options: [{ key: "OFFLINE", label: "Offline", index: 2 }],
          onChange: () => this.handlePaymentMode(),
        },
      ],
      paymentFields: [
        {
          type: "select",
          name: "status",
          label: "Status",
          placeholder: "Select",
          options: [
            { key: "PAID", label: "Paid", index: 1 },
            { key: "NOT_PAID", label: "Not Paid", index: 2 },
          ],
        },
        {
          type: "date",
          name: "payment_date",
          label: "Payment Date",
          required: true,
        },
        {
          type: "amount",
          name: "payment_amount",
          label: "Payment Amt",
          placeholder: "Enter amount in ₹",
          required: true,
        },
      ],
    };
    this.columns = [
      {
        dataIndex: "invoice_id",
        title: "Invoice Id",
      },
      {
        dataIndex: "school_name",
        title: "School Name",
        display: localStorage.getItem("currentAdminRole") == "SCHOOL_ADMIN" ? false : true,
        sorter: false,
      },
      {
        dataIndex: "invoice_date",
        title: "Invoice Date",
        render: (text, record) => {
          if(text != "N/A") {
            const date = new Date(text);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
          } else {
            return text;
          }
				},
      },
      {
        dataIndex: "attachment",
        title: "Attachment",
        render: (record) => (
          <a
            href="#"
            className="anchorTag"
            onClick={() => this.downloadFile(record)}
          >
            Attachment
          </a>
        ),
      },
      {
        dataIndex: "invoice_amount",
        title: "Invoice Amt.",
      },
      {
        dataIndex: "payment_date",
        title: "Payment Date",
        render: (text, record) => {
					if(text != "N/A") {
            const date = new Date(text);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
          } else {
            return text;
          }
				},
      },
      {
        dataIndex: "payment_amount",
        title: "Payment Amt.",
      },
      {
        dataIndex: "status",
        title: "Status",
        render: (text, record) => {
          return (
            <span
              className={`badge light border-0 ${
                text === "NOT_PAID" ? "text-primary" : "badge-success"
              } `}
            >
              {text?.toTitleCase()}
            </span>
          );
        },
      },
      {
        dataIndex: "payment",
        title: "Payment",
        display: localStorage.getItem("currentAdminRole") == "SCHOOL_ADMIN",
        render: (text, record) => {
          return (
            <button
              className="btn btn-primary text-white"
              onClick={() => this.openPayModal(record)}
              disabled={record.status === "PAID"}
            >
              Pay
            </button>
          );
        },
      },
    ];
    this.role = localStorage.getItem("currentAdminRole");
  }

  handlePaymentMode() {
    let data = this.state.editData;
    let updatedEditFields = [...this.state.modalFields, ...this.state.editFields, ...this.state.paymentFields];
    let fields = (updatedEditFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let popupdata = {
      school_name: data.school_name,
      invoice_date: data.invoice_date,
      invoice_amount: data.invoice_amount,
      attachment: data.attachment,
      comment: data.comment,
      payment_mode: "OFFLINE",
      status: data.status,
      payment_date: data.payment_date == "N/A" ? "" : data.payment_date,
      payment_amount: data.payment_amount == 0 ? "" : data.payment_amount,
    };
    this.setState({
      invoiceId: data.invoice_id,
      popupfields: fields,
      popupdata: popupdata,
      editMode: true,
      viewMode: false,
    });
  }

  updateDefault = (o) => {
    let defaultValue = companyData[o.name] || o.defaultValue || undefined;
    if (o.type == "select") {
      defaultValue = o.options.find((o) => o.value == defaultValue);
    }
    return {
      ...o,
      defaultValue,
    };
  };

  downloadFile(fileId) {
    let authToken = window.store.getState().root.auth;
    console.log({ authToken: authToken, fileId });
    const apiUrl =
      app.api
        .request(app.apis().fileDownloader)
        .urltransform({ attachmentId: fileId }).url +
      "?auth=" +
      authToken;

    fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/octet-stream",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${fileId}`;
        link.click();
      })
      .catch((error) => console.error("Error downloading file:", error));
  }

  componentDidMount() {
    this.fetchList();
    this.getSchoolNames();
  }

  getSchoolNames() {
    this.setState({ loader: true });
    let payload = {
      status: "ACTIVE",
    };
    if (this.role != "SCHOOL_ADMIN") {
      this.props.api
        .apiGetAllSchools(payload)
        .then(({ data, total }) => {
          // data = data.sort((a, b) => a.curriculum_level - b.curriculum_level);
          let classNames = this.state.modalFields.find(
            (o) => o.name == "school_id"
          );
          classNames.options = data[0].records?.options(
            "school_id",
            "school_name"
          );
          this.setState({
            modalFields: [...this.state.modalFields],
            loader: false,
          });
        })
        .catch((error) => {
          this.setState({
            loader: false,
          });
        });
    }
  }

  addModalFields() {
    let fields = (this.state.modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    this.setState({
      popupfields: fields,
      popupdata: formdata,
    });
  }

  fetchList(data) {
    this.setState({
      loader: true,
    });
    let payload = {
      ...data,
      ...this.state.search,
    };
   if(localStorage.getItem("currentAdminRole") == "SCHOOL_ADMIN") {
    payload.school_id = localStorage.getItem("school_admin_id");
   }
    this.props.api
      .apiGetAllInvoiceForm(payload)
      .then(({ data, total }) => {
        this.setState({
          data: [...data],
          rowcount: total,
        });
      })
      .then(() => {
        this.setState({
          loader: false,
        });
      })
      .catch(() => {
        this.setState({ loader: false });
      });
  }

  onNext() {}

  onPrev() {}

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      () => this.fetchList()
    );
  }

  deleteAction(value) {
    let payload = {
      invoice_id: value.invoice_id,
    };

    confirm(`Are you sure want to delete this Invoice ?`).promise.then(() => {
      this.api.apiDeleteInvoiceForm(payload).then(() => {
        this.fetchList();
      });
    });
  }

  editAction(v) {
    console.log("edit: ", v);

    const updatedEditFields = [...this.state.editFields, ...this.state.modalFields];

    const fieldsToDisable = ["school_id"];
    fieldsToDisable.forEach((fieldName) => {
      const fieldIndex = updatedEditFields.findIndex(
        (field) => field.name === fieldName
      );
      if (fieldIndex !== -1) {
        updatedEditFields[fieldIndex] = {
          ...updatedEditFields[fieldIndex],
          disabled: true,
          defaultValue: v.school_id,
        };
      }
    });

    let fields = (updatedEditFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    
    let popupdata = {
      school_id: v.school_id,
      invoice_date: v.invoice_date,
      invoice_amount: v.invoice_amount,
      attachment: v.attachment,
      comment: v.comment,
      payment_mode: v.payment_mode || "",
    };
    this.openEditModal();
    this.setState({
      popupfields: fields,
      invoiceId: v.invoice_id,
      popupdata: popupdata,
      editMode: true,
      viewMode: false,
      editData: v,
    });
  }

  viewAction(v) {
    // console.log("view: ", v);

    const date = new Date(v.payment_date);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const payment_date =  `${year}-${month}-${day}`;

    const updatedEditFields = [...this.state.editFields, ...this.state.modalFields];

    const fieldsToDisable = ["school_id"];
    fieldsToDisable.forEach((fieldName) => {
      const fieldIndex = updatedEditFields.findIndex(
        (field) => field.name === fieldName
      );
      if (fieldIndex !== -1) {
        updatedEditFields[fieldIndex] = {
          ...updatedEditFields[fieldIndex],
          disabled: v.school_id ? true : false,
          defaultValue: v.school_id
        };
      }
    });

    const paymentModeFields = updatedEditFields.findIndex(
      (field) => field.name === "payment_mode"
    );
    if (paymentModeFields !== -1) {
      updatedEditFields[paymentModeFields] = {
        ...updatedEditFields[paymentModeFields],
        options: [
          { key: "ONLINE", label: "Online", index: 1 },
          { key: "OFFLINE", label: "Offline", index: 2 },
        ],
      };
    }

    let fields = (updatedEditFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );

    let popupdata = {
      school_id: v.school_id,
      invoice_date: v.invoice_date,
      invoice_amount: v.invoice_amount,
      attachment: v.attachment,
    };
    if (v.payment_mode != "N/A") {
      popupdata.comment = v.comment;
      popupdata.payment_mode = v.payment_mode;
      popupdata.status = v.status == "NOT_PAID" ? "Not Paid" : "Paid";
      popupdata.payment_date = v.payment_date == "N/A" ? "" : payment_date;
      popupdata.payment_amount = v.payment_amount == 0 ? "" : v.payment_amount;
    }
    this.openEditModal();
    this.setState({
      popupfields: fields,
      popupdata: popupdata,
      editMode: false,
      viewMode: true,
      editData: v,
    });
  }

  isRowEditable(data) {
    return data.status == "PAID" ? false : true;
  }

  isRowDeletable(data) {
    return data.status == "PAID" ? false : true;
  }

  onSearch(data) {
    // console.log("onSearch data: ", data);
    this.setState({
      search: data
    }, () => {
      this.fetchList(data);
    });
  }

  toastConfig(text, message) {
    const options = {
      autoClose: 3000,
      closeButton: false,
      hideProgressBar: true,
    };
  
    if (message === "success") {
      toast.success(text, options);
    } else {
      toast.error(text, options);
    }
  } 

  // openEnquireModal() {
  //   this.invoiceModalRef.current.click();
  // }

  openEditModal() {
    this.editInvoiceModalRef.current.click();
  }

  openPayModal(record) {
    let formData = {
      school_name: record.school_name,
      invoice_id: record.invoice_id,
      currency: "INR",
      invoice_amount: record.invoice_amount,
    };
    this.payInvoiceModalRef.current.click();
    this.setState({
      popupPayData: formData,
    });
  }

  closeModal() {
    document.querySelector("#invoice_modal #bsmodalclose").click();
  }

  closeEditModal() {
    document.querySelector("#edit_invoice_modal #bsmodalclose").click();
  }

  loadScript = (src) =>
    new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });

  handleMakePayment(event, data, form) {
    event.preventDefault();

    this.setState({
      loader: true,
    });

    const payload = {
      amount: this.state.popupPayData.invoice_amount,
      currency: this.state.popupPayData.currency,
      invoiceId: this.state.popupPayData.invoice_id,
    };
    // const { data } = await axios.post(`${ApiUrl()}payment`, payload);
    this.props.api
      .apiOrderRazrorPayInvoice(payload)
      .then(async ({ data }) => {
        const res = await this.loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
        );
        this.setState({
          loader: false,
        });
        if (!res) {
          alert("Failed to load payment gateway. Please retry.");
          return;
        }

        const options = {
          key: process.env.REACT_APP_RAZOR_PAY_API_KEY,
          currency: this.state.popupPayData.currency,
          name: "CognoSpace",
          description: "Pay for the Invoices",
          image: "http://13.232.147.253/static/media/Logo.1a058d1b.png",
          order_id: data?.id,
          handler: function (response) {
            document.querySelector("#pay_invoice_modal #bsmodalclose").click();
            document.getElementById("#refresh_list")?.click();
            success("Payment made successfully");
          },
          prefill: {
            contact: JSON.parse(localStorage.getItem("profile-data"))
              ?.mobile_number,
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      })
      .catch((error) => {
        console.error("Error:", error);
        this.setState({
          loader: false,
        });
      });
  }

  handleSubmit(event, data, form) {
    event.preventDefault();
    console.log("formData", data, form);
    this.setState({
      loader: true,
    });

    const resolveList = () => {
      success(
        `${
          this.state.editMode
            ? "Invoice Updated Successfully"
            : "Invoice Added Successfully"
        }`
      );
      form.reset();
      this.closeEditModal();
      this.closeModal();
      this.fetchList();
    };
    const paymentDate = data.payment_date ? new Date(data.payment_date).toISOString() : data.payment_date;
    if (this.state.editMode) {
      let payload = {
        invoice_date: data.invoice_date,
        invoice_amount: data.invoice_amount,
        attachment: data.attachment,
        status: data.status,
        comment: data.comment,
      };
      if (data.payment_mode == "OFFLINE") {
        payload.payment = {
          payment_date: paymentDate,
          payment_amount: data.payment_amount,
          payment_mode: data.payment_mode,
        }
      }
      if(data?.status != "NOT_PAID") {
      this.props.api
        .apiEditInvoiceForm(payload, { invoice_id: this.state.invoiceId })
        .then(() => {
          this.setState({
            loader: false,
          });
          this.closeModal();
          this.closeEditModal();
          this.fetchList();
        })
        .catch((error) => {
          console.error("Error:", error);
          this.setState({
            loader: false,
          });
        });
      } else {
        this.toastConfig("Please change the Status to Paid!!", "error");
        this.setState({
          loader: false,
        });
      }
    } else {
      let payload = {
        ...data,
      };
      this.props.api
        .apiAddInvoiceForm(payload)
        .then(resolveList)
        .then(() => {
          this.setState({
            loader: false,
          });
        })
        .catch((error) => {
          this.setState({ loader: false });
        });
    }
  }

  updateFields(fields) {
    return fields;
  }
  render() {
    let {
      data = [],
      rowcount,
      editData,
      popupdata,
      popupfields,
      popupPayfields,
      popupPayData,
      editMode,
      viewMode,
      loader,
      modalFields,
    } = this.state;
    let { columns } = this;
    modalFields = this.updateFields(modalFields);
    return (
      <>
        <ToastContainer position="top-right" />
        {loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <LayoutWrapper title="Invoice Form" back="Invoice Form">
          {this.role != "SCHOOL_ADMIN" && (
            <div className="row">
              <div className="col"></div>
              <div className="mb-4">
                <Link
                  className="btn add-btn"
                  ref={this.invoiceModalRef}
                  data-bs-toggle="modal"
                  data-bs-target="#invoice_modal"
                  onClick={(...args) => this.addModalFields(...args)}
                >
                  <i className="fa fa-plus"></i>
                  Add Invoice
                </Link>
              </div>
            </div>
          )}
          {this.role != "SCHOOL_ADMIN" && (
            <InvoiceSearch
              onSearch={(...arg) => this.onSearch(...arg)}
            />
          )}
          <div className="row">
            <div className="col-12">
              {!!data.length && (
                <AppTable
                  data={data}
                  columns={columns}
                  onNext={() => this.onNext()}
                  onPrev={() => this.onPrev()}
                  onChange={(...arg) => this.onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  deleteAction={(v) => this.deleteAction(v)}
                  editAction={(v) => this.editAction(v)}
                  isRowEditable={(v) => this.isRowEditable(v)}
                  isRowDeletable={(v) => this.isRowDeletable(v)}
                  viewAction={(v) => this.viewAction(v)}
                  viewable={this.role == "SCHOOL_ADMIN" ? false : true}
                  editable={this.role == "SCHOOL_ADMIN" ? false : true}
                  deletable={this.role == "SCHOOL_ADMIN" ? false : true}
                  targetType="tap"
                  isresponsive={true}
                ></AppTable>
              )}
              {data.length == 0 && <div className="empty_layout"></div>}
            </div>
          </div>
          <InvoiceModal
            id="invoice_modal"
            title={`${
              editMode
                ? "Edit Invoice Details"
                : viewMode
                ? "View Invoice Details"
                : "Add Invoice"
            }`}
            submitText={`${editMode ? "Update" : "Save"}`}
            editdata={editData}
            data={popupdata}
            fields={popupfields}
            disableallfields={viewMode ? true : false}
            onSubmit={(...args) => this.handleSubmit(...args)}
            submitButton={viewMode ? false : true}
            closeButton={viewMode ? true : false}
            onClose={() =>
              this.setState({
                popupdata: {},
                editData: {},
                editMode: false,
                viewMode: false,
              })
            }
          />
          <InvoiceModal
            id="edit_invoice_modal"
            title={`${
              editMode
                ? "Edit Invoice Details"
                : viewMode
                ? "View Invoice Details"
                : "Add Invoice"
            }`}
            submitText={`${editMode ? "Update" : "Save"}`}
            editdata={editData}
            data={popupdata}
            fields={popupfields}
            disableallfields={viewMode ? true : false}
            onSubmit={(...args) => this.handleSubmit(...args)}
            submitButton={viewMode ? false : true}
            closeButton={viewMode ? true : false}
            onClose={() =>
              this.setState({
                popupdata: {},
                editData: {},
                editMode: false,
                viewMode: false,
              })
            }
          />
          <InvoicePayModal
            id="pay_invoice_modal"
            title={`Pay Invoice`}
            submitText="Make Payment"
            data={popupPayData}
            fields={popupPayfields}
            disableallfields={true}
            onSubmit={(...args) => this.handleMakePayment(...args)}
            submitButton={true}
            closeButton={true}
            onClose={() =>
              this.setState({
                popupdata: {},
                editData: {},
                editMode: false,
                viewMode: false,
              })
            }
          />
          {/* below button hidden on UI */}
          <button
            className="btn btn-sm btn-outline-secondary d-none"
            onClick={(...args) => this.editModalFields(...args)}
            ref={this.editInvoiceModalRef}
            data-bs-toggle="modal"
            data-bs-target="#edit_invoice_modal"
          >
            <span>Hidden Button</span>
          </button>

          <button
            className="btn btn-sm btn-outline-secondary d-none"
            onClick={() => this.fetchList()}
            id="#refresh_list"
          >
            <span>Hidden Button</span>
          </button>
          <button
            className="btn btn-sm btn-outline-secondary d-none"
            onClick={(...args) => this.editModalFields(...args)}
            ref={this.payInvoiceModalRef}
            data-bs-toggle="modal"
            data-bs-target="#pay_invoice_modal"
          >
            <span>Hidden Button</span>
          </button>
        </LayoutWrapper>
      </>
    );
  }
}

export default connect(InvoiceForm);
