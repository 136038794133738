import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  let navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Login - CognoSpace</title>
        <meta name="description" content="Login page" />
      </Helmet>
      <div className="account-content">
        <div className="container">
          <div className="account-box">
            <div className="account-wrapper">
              <h4 className="account-title">Email Sent Successfully with temporary Password</h4>

              {/* Account Form */}
              <form>
                <div className="form-group mt-5 d-flex justify-content-center">
                  <button
                    className="btn btn-primary account-btn w-25"
                    type="submit"
                    onClick={() => navigate("/open/login")}
                  >
                    Ok
                  </button>
                </div>
              </form>
              {/* /Account Form */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(ForgotPassword);