import React, { Component } from "react";

export class LogoutModal extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.LogoutModal = this;
  }
  render() {
    let { props } = this;
    let { id, title, onClose, contentClass } = props;

    return (
      <AppModalBs id={id} title={title} onClose={onClose} contentClass={contentClass}>
        <b className="namesList d-flex justify-center">Are you sure want to Logout?</b>
        <div className="row no-gutters mt-2">
          <Row className="mt-4 d-flex justify-center">
          <button
              type="reset"
              data-bs-dismiss="modal"
              onClick={() => this.props.handleSubmit()}
              className="btn save-btn col col-md-1 ml-auto ms-2 me-2 rounded-1">
              Yes
            </button>
            <button
              type="reset"
              data-bs-dismiss="modal"
              onClick={onClose}
              className="btn close-btn col col-md-1 ml-auto ms-2 me-2 rounded-1">
              No
            </button>
          </Row>
        </div>
      </AppModalBs>
    );
  }
}

export default LogoutModal;
