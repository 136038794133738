import React, { Component } from "react";

export class ClassSearch extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("ClassSearch");
    this.form = React.createRef();
    this.state = {
      schools: [],
      search: {
        limit: 50,
        page: 1,
        order_by: "updatedAt",
        order_direction: "DESC",
      },
    };
    this.fields = [
      {
        name: "school_id",
        label: "School Name",
        type: "select",
        options: [],
        className: "select-focus",
        onChange: (...args) => this.handleSchoolChange(...args),
      },
    ];
  }

  onSearch(e, data) {
    console.log("data on search", data);
    this.props.onSearch && this.props.onSearch(data);
  }

  handleSchoolChange([value]) {
    console.log({ handleSchoolChange: value });
  }

  handleClusterChange([value]) {}

  componentDidMount() {
    this.fetchSchools();
  }

  fetchSchools() {
    this.api.apiGetSchools(this.state.search).then(({ data, total }) => {
      console.log("school data: ", data);
      this.setState({ schools: data });
    });
  }

  updateFields(fields) {
    fields.find((o) => o.name === "school_id").options =
      this.state.schools.options("school_id", "school_name");
    return fields;
  }

  render() {
    let {} = this.state;
    let { fields } = this;
    fields = this.updateFields(fields);
    return (
      <AppForm
        className="row filter-row"
        ref="form"
        onSubmit={(...args) => this.onSearch(...args)}
      >
        {this.props.hideStatus
          ? fields
            .filter((v) => v.name !== "status")
            .map((field, i) => (
              <div className="col-sm-6 col-md-3" key={i}>
                <AppInputFocus {...field}></AppInputFocus>
              </div>
            ))
          : fields.map((field, i) => (
            <div className="col-sm-6 col-md-3" key={i}>
              <AppInputFocus {...field}></AppInputFocus>
            </div>
          ))}
        <div className="col-sm-6 col-md-3">
          <button className="btn btn-success btn-block w-100">Search</button>
        </div>
      </AppForm>
    );
  }
}

export default connect(ClassSearch);
