import React, { Component } from "react";
import { Link } from "react-router-dom";
import LayoutWrapper from "./LayoutWrapper";
import EmployeeSearch from "./EmployeeSearch";
import EmployeeModal from "./EmployeeModal";

export class EmployeeManagement extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("EmployeeManagement");
    this.state = {
      data: [],
      rowcount: 0,
      search: {
        limit: 50,
        page: 1,
        order_by: "updatedAt",
        order_direction: "DESC",
      },
      editData: {},
      viewMode: false,
      editMode: false,
      popupfields: {},
      popupdata: {},
      loader: false,
      // schoolAdmins: [],
    };
    this.columns = [
      {
        dataIndex: "name",
        title: "Employee Name",
      },
      {
        dataIndex: "mobile_number",
        title: "Mobile Number",
      },
      {
        dataIndex: "email",
        title: "Email ID",
      },
      {
        dataIndex: "role",
        title: "Role",
      },
      {
        dataIndex: "city",
        title: "City",
      },
      {
        dataIndex: "state",
        title: "State",
      },
    ];
    this.modalFields = [
      {
        type: "text",
        name: "name",
        label: "Employee Name",
        required: true,
      },
      {
        type: "text",
        name: "mobile_number",
        label: "Mobile No.",
        pattern: "[0-9]{10}",
        onInvalid: (e) =>
          e.target.setError(
            "ex. 91-9XXXXXXXXX 10 digit phone number with country code."
          ),
        onInput: (e) => e.target.setMessage(),
        required: true,
      },
      {
        type: "email",
        name: "email",
        label: "Email ID",
        required: true,
      },
      {
        type: "select",
        name: "role",
        label: "Designation",
        options: [
          { key: "SCHOOL_COORDINATOR", label: "SCHOOL_COORDINATOR", index: 1 },
        ],
        required: true,
      },
      {
        type: "select",
        name: "state",
        label: "State",
        options: Object.keys(statecity).sort().options(),
        onChange: (value) => {
          let city = this.modalFields.find((o) => o.name == "city");
          city.options = Object.assign(
            city.options,
            statecity[value[0].key].sort().options()
          );
        },
        required: true,
      },
      {
        type: "select",
        name: "city",
        label: "City",
        options: [],
        required: true,
      },
    ];
    this.employeeModalRef = React.createRef();
  }

  componentDidMount() {
    this.fetchList();
  }

  addModalFields() {
    let fields = (this.modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    this.setState({
      popupfields: fields,
      popupdata: formdata,
    });
  }

  fetchList(data) {
    this.setState({
      loader: true,
    });
    let payload = {
      ...data,
      ...this.state.search,
    };
    this.props.api
      .apiGetAllProfile(payload)
      .then(({ data, total }) => {
        console.log({ data });
        this.setState({
          data: [...data],
          rowcount: total,
        });
      })
      .then(() => {
        this.setState({
          loader: false,
        })
      }).catch((err) => {
        this.setState({
          loader: false,
        });
      });
  }

  downloadFile() {}

  onNext() {}

  onPrev() {}

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      () => this.fetchList()
    );
  }

  deleteAction(value) {
    let payload = {
      profile_id: value.profile_id,
    };

    confirm(`Do you really want to delete these employee?`).promise.then(() => {
      this.api.apiDeleteProfile(payload).then(() => {
        this.fetchList();
      });
    });
  }

  editAction(v) {
    // console.log("edit: ", v);
    this.openEmployeeModal();
    this.setState({
      popupdata: Object.except(v, [
        "profile_id",
        "createdAt",
        "updatedAt",
        "is_password_updated",
        "role_id",
        "user_id",
        "password",
        "temp_pass",
      ]),
      editMode: true,
      viewMode: false,
      editData: v,
    });
    // update city based on state values
    let city = this.modalFields.find((o) => o.name == "city");
    city.options = Object.assign(city.options, statecity[v.state].sort().options());
  }

  viewAction(v) {
    console.log("view: ", v);
    this.openEmployeeModal();
    this.setState({
      popupdata: Object.except(v, [
        "profile_id",
        "createdAt",
        "updatedAt",
        "is_password_updated",
        "role_id",
        "user_id",
        "password",
        "temp_pass",
      ]),
      editMode: false,
      viewMode: true,
      editData: v,
    });
    let city = this.modalFields.find((o) => o.name == "city");
    city.options = Object.assign(city.options, statecity[v.state].options());
  }

  onSearch(data) {
    // console.log("onSearch data: ", data);
    this.setState({
      search: data
    }, () => {
      this.fetchList(data);
    });
  }

  openEmployeeModal() {
    this.employeeModalRef.current.click();
  }
  closeModal() {
    document.querySelector("#add_employee_modal #bsmodalclose").click();
  }

  handleSubmit(event, data, form) {
    event.preventDefault();
    // console.log("formData", data, form);
    // delete data.sections;
    this.setState({
      loader: true,
    });

    const resolveList = () => {
      success(
        `${
          this.state.editMode
            ? "Employee Updated successfully"
            : "Employee added successfully"
        }`
      );
      form.reset();
      this.closeModal();
      this.fetchList();
    };
    if (this.state.editMode) {
      this.props.api
        .apiUpdateProfile(data, { profile_id: this.state.editData.profile_id })
        .then(resolveList)
        .then(() => {
          this.setState({
            loader: false,
          });
        }).catch(()=>{
          this.setState({
            loader: false,
          });
        });
    } else {
      let payload = {
        ...data,
      };
      this.props.api
        .apiCreateProfile(payload)
        .then(resolveList)
        .then(() => {
          this.setState({
            loader: false,
          });
        }).catch((error) => {
          this.setState({
            loader: false,
          })
        });
    }
  }

  updateFields(fields) {
    return fields;
  }
  render() {
    let {
      data = [],
      rowcount,
      editData,
      popupdata,
      popupfields,
      editMode,
      viewMode,
      loader,
    } = this.state;
    let { columns, modalFields } = this;
    modalFields = this.updateFields(modalFields);
    return (
      <>
        {loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <LayoutWrapper
          title="Employee Management"
          back="Employee Management"
        >
          <div className="row">
            <div className="col"></div>
            {localStorage.getItem("currentAdminRole") == "SUPER_ADMIN" && (
              <div className="mb-4">
                <Link
                  className="btn add-btn"
                  ref={this.employeeModalRef}
                  data-bs-toggle="modal"
                  data-bs-target="#add_employee_modal"
                  onClick={(...args) => this.addModalFields(...args)}
                >
                  <i className="fa fa-plus"></i>
                  Add Employee
                </Link>
              </div>
            )}
          </div>
          <EmployeeSearch
            onSearch={(...arg) => this.onSearch(...arg)}
          />
          <div className="row schoolmanagement">
            <div className="col-12">
              {!!data.length && (
                <AppTable
                  data={data}
                  columns={columns}
                  onNext={() => this.onNext()}
                  onPrev={() => this.onPrev()}
                  onChange={(...arg) => this.onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  deleteAction={(v) => this.deleteAction(v)}
                  editAction={(v) => this.editAction(v)}
                  viewAction={(v) => this.viewAction(v)}
                  targetType="tap"
                  isresponsive={true}
                ></AppTable>
              )}
              {data.length == 0 && <div className="empty_layout"></div>}
            </div>
          </div>
          <EmployeeModal
            id="add_employee_modal"
            title={`${editMode ? "Update Employee" : (viewMode ? "View Employee" : "Add Employee")}`}
            submitText={`${editMode ? "Update" : "Save"}`}
            editdata={editData}
            data={popupdata}
            fields={popupfields}
            disableallfields={viewMode ? true : false}
            submitButton={viewMode ? false : true}
            closeButton={viewMode ? true : false}
            onSubmit={(...args) => this.handleSubmit(...args)}
            onClose={() =>
              this.setState({
                popupdata: {},
                editData: {},
                editMode: false,
                viewMode: false,
              })
            }
          />
        </LayoutWrapper>
      </>
    );
  }
}

export default connect(EmployeeManagement);
