import { Switch } from "antd";
import React from "react";
import { useRef, useEffect } from "react";

function AppVideoSection({ videosData = [] }) {
  console.log(videosData);
  return (
    <div className="video-container">
      <div className="row border-bottom">
        <div className="col-md-12">
          <div className="title">Session - 1</div>
        </div>
        <div className="col-md-8">
          <div className="video-section">
            <iframe
              src="https://www.youtube.com/embed/5pCZ4SvcPz0"
              frameborder="0"
              allow="autoplay; encrypted-media"
              allowfullscreen
              title="video"
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="video-list-header">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit.
            Voluptates.
          </div>
          <div className="video-list">
            <div className="row mb-3">
              <div className="col-md-12">
                <div className="video-list-head">Video 1</div>
              </div>
              <div className="col-md-4">
                <div className="list-video"></div>
              </div>
              <div className="col-md-8">
                <div className="list-video-checkbox">
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      id="completed1"
                      name="completed1"
                      value="completed"
                      className="form-check-input pr-2"
                    />
                    <label for="completed1" className="check-label">
                      Completed
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12">
                <div className="video-list-head">Video 2</div>
              </div>
              <div className="col-md-4">
                <div className="list-video"></div>
              </div>
              <div className="col-md-8">
                <div className="list-video-checkbox">
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      id="completed2"
                      name="completed2"
                      value="completed"
                      className="form-check-input"
                    />
                    <label for="completed2" className="check-label">
                      Completed
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12">
                <div className="video-list-head">Video 3</div>
              </div>
              <div className="col-md-4">
                <div className="list-video"></div>
              </div>
              <div className="col-md-8">
                <div className="list-video-checkbox">
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      id="completed3"
                      name="completed3"
                      value="completed"
                      className="form-check-input"
                    />
                    <label for="completed3" className="check-label">
                      Completed
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-section">
        <div className="row">
          <div className="col-md-12">
            <div className="percentage">
              <span className="progress-bar"></span>75% Completed
            </div>
            <span className="likes">
              <i class="la la-thumbs-up"></i>
              <span className="count">2,4354</span>
            </span>
            <span className="likes">
              <i class="la la-thumbs-down"></i>
              <span className="count">22,332</span>
            </span>
            <button className="btn btn-success btn-block pull-right">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppVideoSection;
