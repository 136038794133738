export const dashboardService = {
  apiDashboardData(urldata = mixins.params()) {
    return this.request(this.api().getDashboard)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiGetDashboardBarChartData(urldata = mixins.params()) {
    return this.request(this.api().getDashboardBarChartData)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiGetSessionProgressBarChart(urldata = mixins.params()) {
    return this.request(this.api().getSessionProgressBarChart)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
};

export default dashboardService;