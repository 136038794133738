import { Component } from "react";
import { toast } from "react-toastify";
import youtubeIcon from "../../../../../../assets/img/youtube.png";
import LayoutWrapper from "../../../LayoutWrapper";
import "../module.scss";
export class VideoPage extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("VideoPage");
    this.state = {
      data: [],
      currentVideo: {},
      currentIdx: 0,
      renderingData: [],
      breadcrum: "",
      sessionDetails: {},
    };
  }

  componentDidMount() {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
    this.fetchData();
    this.getBreadCums();
  }

  changeVideo(item) {
    this.setState({
      currentVideo: item,
    });
  }

  fetchData() {
    localStorage.setItem("session_id", this.props.urlparams.session_id);
    if(this.props.urlparams.session_id) {
    this.props.api
      .apiGetContentBySession({
        session_id: this.props.urlparams.session_id,
        content_type: "VIDEO",
        school_id: localStorage.getItem("school_id") || "",
        class_id: localStorage.getItem("class_id") || "",
      })
      .then(({ data }) => {
        let tempData = data;
        let newFinalArr = [];
        tempData.map((item) => {
          // let linkArr = item.link.split(".be/");
          // let newStr = "https://www.youtube.com/embed/" + linkArr[1];
          // item.link = newStr;
          newFinalArr.push(item);
        });
        this.setState({
          data: newFinalArr,
        });
        this.setState({
          currentVideo: newFinalArr[0],
        });
      });
    }
  }

  // handleClose(e, values) {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   let closeLink = `/contentmanagement/pillarspage?grade=${this.props.urlparams.grade}&level=${this.props.urlparams.level}&curriculum_id=${this.props.urlparams.curriculum_id}`;
  //   console.log("closeLink----", closeLink);
  //   const lastIndex = closeLink.lastIndexOf("?");
  //   const correctedCloseLink =
  //     closeLink.slice(0, lastIndex) + closeLink.slice(lastIndex + 1);
  //   // console.log("This is close link", correctedCloseLink);
  //   this.props.navigate(correctedCloseLink);
  // }
  routeToSession(e) {
    e.preventDefault();
    e.stopPropagation();
    let closeLink = `/contentmanagement/sessions?grade=${
      this.props.urlparams.grade
    }&level=${this.props.urlparams.level.toUpperCase()}&type=${
      this.props.urlparams.type
    }&curriculum_id=${
      this.props.urlparams.curriculum_id
    }&class_id=${localStorage.getItem("class_id")}`;

    const lastIndex = closeLink.lastIndexOf("?");
    const correctedCloseLink =
      closeLink.slice(0, lastIndex) + closeLink.slice(lastIndex + 1);
    // console.log("This is close link", correctedCloseLink);
    this.props.navigate(correctedCloseLink);
  }

  completed(event, box) {
    console.log("event---", event.target.checked);
    if (this.props.urlparams.session_id) {
      let payload = {
        class_id: localStorage.getItem("class_id"),
        module_id: localStorage.getItem("module_id"),
        session_id: this.props.urlparams.session_id,
        content_id: box?.content_id,
        progress: event.target.checked ? 100 : 0,
      };
      console.log("event---", payload);
      this.props.api
        .apiRecordConentProgress(
          { school_id: localStorage.getItem("school_id") },
          payload
        )
        .then(({ data, total }) => {
          this.fetchData();
          toast.success("Video Status Updated Successfully", {
            autoClose: 3000,
            closeButton: false,
            hideProgressBar: true,
          });
        });
    }
  }

  getBreadCums() {
    if (this.props.urlparams.session_id) {
      let payload = {
        session_id: this.props.urlparams.session_id,
      };
      console.log(payload);
      this.props.api
        .apiGetContentSessionById({
          session_id: this.props.urlparams.session_id,
        })
        .then(({ data }) => {
          // console.log("session data: ", data);
          this.setState({
            sessionDetails: data,
          });
          let breadcrum = `${
            localStorage.getItem("currentAdminRole") != "SUPER_ADMIN"
              ? "Content Delivery"
              : "Content Management"
          }/${this.props.urlparams.grade} Grade - Level ${
            this.props.urlparams.level
          }/
        ${this.props.urlparams?.type}/${data?.session_name} : VIDEO`;

          this.setState({
            breadcrum: breadcrum,
          });
        });
    } else {
      return "VIDEO";
    }
  }
  getBackLink() {
    if (this.props.urlparams.course_id) {
      let backLink = `/lms/courses`;
      return backLink;
    } else {
      return "/contentmanagement";
    }
  }

  getTitle() {
    if (this.props.urlparams.grade) {
      let title = `${this.props.urlparams.grade} Grade - Level ${this.props.urlparams.level}`;

      return title;
    } else {
      return "Session";
    }
  }

  render() {
    let {
      data = [],
      currentVideo = {},
      breadcrum,
      sessionDetails,
    } = this.state;
    return (
      <LayoutWrapper
        title={this.getTitle()}
        back={breadcrum}
        backlink={this.getBackLink()}
      >
        <div className="card bg-white media_box">
          <div className="card-header">
            <h3 className="title">{sessionDetails.session_name}</h3>
          </div>
          <div className="card-body px-0">
            <div className="row g-5 mx-auto px-0">
              <div className="col-12 col-md-7">
                <div style={{ padding: "53.75% 0 0 0", position: "relative" }}>
                  <iframe
                    src={currentVideo.attach_file}
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    title={sessionDetails.session_name}
                  ></iframe>
                </div>
              </div>
              <div className="col-12 col-md-5">
                <p className="content_description">
                  {currentVideo.content_description}
                </p>
                <div className="mini_box_container">
                  {data.map((box, j) => {
                    return (
                      <div
                        className="mini_box cursor-pointer"
                        key={j}
                        onClick={() => this.changeVideo(box)}
                      >
                        <div className="row g-3 m-auto px-0">
                          <div className="col-12">
                            <p className="title">{box.content_title}</p>
                          </div>
                          <div className="col-2">
                            <div className="thumbnail_box">
                              <img src={youtubeIcon} alt="link icon" />
                            </div>
                          </div>
                          {localStorage.getItem("currentAdminRole") == "SCHOOL_COORDINATOR" &&
                          <div className="col-8 d-flex align-items-center justify-content-start">
                            <input
                              type="checkbox"
                              name={`check-complete-${j}`}
                              id={`check-complete-${j}`}
                              className="mx-2"
                              style={{
                                accentColor: "#fba106",
                              }}
                              checked={
                                box.progress === 100 && box?.progress
                                  ? true
                                  : false
                              }
                              // checked={true}
                              onChange={(...args) =>
                                this.completed(...args, box)
                              }
                            />
                            <label for={`check-complete-${j}`}>Completed</label>
                          </div>}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <hr className="mt-5" />
          <div
            className="card-footer social_box"
            style={{
              position: "static",
            }}
          >
          {/* {localStorage.getItem("currentAdminRole") == "SCHOOL_COORDINATOR" && <button className="btn add-btn float-none me-3">
              {data[0]?.module_progress?.toFixed(2)} % Completed
            </button>} */}
            {/* <button type="button" className="social_btn">
              <i class="fa fa-thumbs-o-up"></i>
              <span>{currentVideo.total_likes}</span>
            </button>
            <button type="button" className="social_btn">
              <i class="fa fa-thumbs-o-down"></i>
              <span>{currentVideo.total_dislikes}</span>
            </button> */}

            {/* <button
              type="button"
              onClick={(...args) => this.handleClose(...args)}
              className="btn add-btn min-wd-100"
            >
              Close
            </button> */}
            <button
              type="button"
              onClick={(...args) => this.routeToSession(...args)}
              className="btn add-btn min-wd-100 mr-2"
            >
              Back To Sessions
            </button>
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(VideoPage);
