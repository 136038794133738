import React, { Component } from "react";
import { Link } from "react-router-dom";
import LayoutWrapper from "../schoolmanagement/LayoutWrapper";
import ClassModal from "./ClassModal";
import ClassSearch from "./ClassSearch";
import ContentCard from "./ContentCard";

export class ContentManagement extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("ContentManagement");
    this.state = {
      data: [],
      schools: [],
      rowcount: 0,
      editData: {},
      viewMode: false,
      editMode: false,
      popupfields: {},
      popupdata: {},
      schoolId: window.store.getState().root.school_id,
      search: {
        limit: 10,
        page: 1,
        order_by: "class_level",
        order_direction: "ASC",
      },
      heading: "",
      loader: false,
    };
    this.classModalRef = React.createRef();
    this.modalFields = [
      {
        type: "text",
        name: "curriculum_name",
        label: "Class Name",
        required: true,
      },
      {
        type: "text",
        name: "curriculum_level",
        label: "Class Level",
        required: true,
      },
      {
        type: "text",
        name: "curriculum_description",
        label: "Class Description",
      },
    ];
  }

  componentDidMount() {
    // this.fetchSchools();
    this.fetchList();
    this.setHeading();
  }

  handleStoreChange = () => {
    const newSchoolId = window.store.getState().root.school_id;
    if (newSchoolId !== this.state.schoolId) {
      this.setState({ schoolId: newSchoolId }, () => this.fetchList());
    }
  };

  setHeading() {
    const heading =
      localStorage.getItem("currentAdminRole") == "SCHOOL_COORDINATOR"
        ? "Content Delivery"
        : "Content Management";
    this.setState({
      heading: heading,
    });
  }

  fetchSchools() {
    this.api.apiGetSchools(this.state.search).then(({ data, total }) => {
      // console.log("school data: ", data);
      this.setState({ schools: data });
    });
  }

  addModalFields() {
    let fields = (this.modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    this.setState({
      popupfields: fields,
      popupdata: formdata,
    });
  }

  fetchList() {
    this.setState({ loader: true });
    if (localStorage.getItem("currentAdminRole") == "SUPER_ADMIN") {
      this.props.api
        .apiGetClasses()
        .then(({ data, total }) => {
          data = data.sort((a, b) => a.curriculum_level - b.curriculum_level);
          console.log("curriculum data", data);
          this.setState({
            data,
            loader: false,
          });
        })
        .catch((error) => {
          this.setState({
            loader: false,
          });
        });
    } else {
      this.props.api
        .apiGetClassById({ school_id: this.state.schoolId })
        .then((response) => {
          let data = response.data[0].records;
          data = data.sort((a, b) => a.curriculum_level - b.curriculum_level);
          console.log("curriculum data", data);
          this.setState({
            data,
            loader: false,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            loader: false,
          });
        });
    }
  }

  downloadFile() {}

  onSearch(data) {
    this.fetchList(data);
  }

  closeModal() {
    document.querySelector("#add_class_modal #bsmodalclose").click();
  }

  handleSubmit(e) {
    e.preventDefault();
    let formData = e.target.getData();
    console.log("formData", formData);
    this.setState({ loader: true });
    this.props.api
      .apiAddClass(formData)
      .then((res) => {
        console.log("apiAddClass: ", res);
        success("Class added successfully");
        this.closeModal();
        this.setState({ loader: false });
      })
      .catch(() => {
        this.setState({
          loader: false,
        });
      });
    this.fetchList();
  }

  changeLevel(value) {
    localStorage.setItem("module_id", value.module_id);
    localStorage.setItem("curriculum_id", value.curriculum_id);
    let grade = _enum.MAP_LEVEL_GRADE[value.curriculum_level];
    if (localStorage.getItem("currentAdminRole") == "SCHOOL_COORDINATOR") {
      this.props.navigate(
        `/contentmanagement/pillarspage?grade=${grade}&level=${value.curriculum_level}&curriculum_id=${value.curriculum_id}&class_id=${value.class_id}`
      );
    } else {
      this.props.navigate(
        `/contentmanagement/pillarspage?grade=${grade}&level=${value.curriculum_level}&curriculum_id=${value.curriculum_id}`
      );
    }
  }

  // updateFields(fields) {
  //   fields.find((o) => o.name === "school_id").options =
  //     this.state.schools.options("school_id", "school_name");
  //   return fields;
  // }

  render() {
    let { editData, popupdata, popupfields, editMode, viewMode, heading } =
      this.state;
    return (
      <>
        {this.state.loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <LayoutWrapper title={heading} back={`${heading}`}>
          <div className="row">
            <div className="col"></div>
            {/* {localStorage.getItem("currentAdminRole") == "SUPER_ADMIN" && (
            <div className="mb-4">
              <Link
                className="btn add-btn"
                ref={this.classModalRef}
                data-bs-toggle="modal"
                data-bs-target="#add_class_modal"
                // onClick={(...args) => this.addModalFields(...args)}
              >
                <i className="fa fa-plus"></i>
                Add Class
              </Link>
            </div>
          )} */}
          </div>
          {/* <ClassSearch onSearch={(...arg) => this.onSearch(...arg)} /> */}
          <Row className="mx-0 mb-3 mt-4 g-3">
            {this.state.data?.map((item, i) => {
              return (
                <Col className="col-12 col-lg-4 col-xxl-3" key={i}>
                  <a onClick={() => this.changeLevel(item)}>
                    <ContentCard
                      grade={item.curriculum_grade}
                      level={item.curriculum_level}
                      content={item.curriculum_description}
                    />
                  </a>
                </Col>
              );
            })}
          </Row>
          <ClassModal
            id="add_class_modal"
            title={`${editMode ? "Update Class" : "Add Class"}`}
            submitText={`${editMode ? "Update Class" : "Add Class"}`}
            editdata={editData}
            data={popupdata}
            fields={popupfields}
            disableallfields={viewMode ? true : false}
            onSubmit={(...args) => this.handleSubmit(...args)}
            onClose={() =>
              this.setState({
                popupdata: {},
                editData: {},
                editMode: false,
                viewMode: false,
              })
            }
          />
        </LayoutWrapper>

        <button
          id="fetch_class"
          className="btn btn-sm btn-outline-secondary d-none"
          onClick={() => this.handleStoreChange()}
        >
          <span>Hidden Button</span>
        </button>
      </>
    );
  }
}

export default connect(ContentManagement);
