import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ClassConfigModal from "./ClassConfigModal";
import { toast, ToastContainer  } from "react-toastify";

const ClassConfig = (props) => {
    const toastConfig = {
        autoClose: 3000,
        closeButton: false,
        hideProgressBar: true,
    };
    const classModalRef = useRef();
        const [search,setSearch] = useState({
            limit: 50,
            page: 1,
            order_by: "updatedAt",
            order_direction: "DESC",
        });
        const [loader, setLoader] = useState(false);
        const [tableData,setTableData] = useState([]);
        const [editData, setEditData] = useState({});
        const [viewMode, setViewMode] = useState(false);
        const [editMode,setEditMode] = useState(false);
        const [modalOpened, setModalOpened] = useState(false);
        const [classnames, setClassnames] = useState([]);
        const [section, setSection] = useState([{ name: "", no_student: "" }]);
        const [popupfields,setPopupfields] = useState({});
        const [popupdata,setPopupdata] = useState({});
        const [classId, setClassId] = useState("");
        const [columns, setColumns] = useState([
            {
                dataIndex: "curriculum_grade",
                title: "Class Name",
            },
            {
                dataIndex: "name",
                title: "Section",
            },
            {
                dataIndex: "total_students",
                title: "No. of Students",
            }
        ]);
        const [modalFields, setModalFields] = useState([
            {
                type: "select",
                name: "curriculum_id",
                label: "Class Name",
                placeholder: "Select",
                options: [],
                required: true,
                customcolspan: "col-lg-11",
            },
            {
                type: "text",
                name: "name",
                label: "Section Name",
                required: true,
                onChange: (e, index) => {
                    const { name, value } = e.target;
                    const list = [...modalFields];
                    list[index][name] = value;
                    setModalFields(list);
                }
            },
            {
                type: "text",
                name: "no_student",
                label: "No. of Students",
                required: true,
                customcolspan: "col-lg-5",
                pattern: "^[0-9 ]+$",
                invalidmessage: "Please enter a valid number",
                onChange: (e, index) => {
                    const { name, value } = e.target;
                    const list = [...modalFields];
                    list[index][name] = value;
                    setModalFields(list);
                }
            },
            // {
            //     name: "",
            //     type: "icon",
            //     className: "deleteIcon",
            //     label: `${imagepaths.deleteIcon}`,
            //     customcolspan: "col-lg-1",
            //     onClickOfIcon: (...args) => {handleDeleteRow(...args)}
            // },
            {
                name: "+ Add more Section & Students",
                type: "button",
                className: "text-primary addMoreBtn",
                label: "+ Add more Section & Students",
                onButtonClick: (...args) => {handleButtonClick(...args)}
            }
        ]);
        const role = localStorage.getItem("currentAdminRole");

    useEffect(()=> {
        fetchClassList();
        getClassNames();

    }, []);

    const fetchClassList = () => {
        setLoader(true);
        const school_id = role == "SCHOOL_ADMIN" ? localStorage.getItem("school_admin_id")
        : (props.selectedEditMode || props.readMode) ? props.selectedRowData.school_id
        : localStorage.getItem("school_id");
        props.api.apiGetClassById({school_id: school_id})
        .then((response) => {
            // if(response.data && response.data[0].records.length !== 0) {
                const result = response.data[0].records;
                result.map((item) => {
                    const name = item.section.map((v) => v.name);
                    const sectionName = name.join(', ')
                    item.name = sectionName;
                })
                setTableData(result);
            // }
            setLoader(false);
        }).catch((error) => {
            console.log('error', error);
            setLoader(false);
        });
    }

    const getClassNames = () => {
        // setLoader(true);
        props.api.apiGetClasses().then(({ data, total }) => {
            data = data.sort((a, b) => a.curriculum_level - b.curriculum_level);
            // let classNames = modalFields.find((o) => o.name == "curriculum_id");
            // classNames.options = data?.options("curriculum_id", "curriculum_grade");
            // setModalFields([...modalFields]);
            // setLoader(false);
            setClassnames(data?.options("curriculum_id", "curriculum_grade"))
        }).catch((error) => {
            console.log('error', error);
            // setLoader(false);
        });
    }

    const handleDeleteRow = (event, i) => {
        const list = [...modalFields];
        list.splice(index, 3);
        setModalFields(list);
    }

    const handleButtonClick = () => {
        let addMoreSections = modalFields.filter((field) => field.name != "+ Add more Section & Students");
        console.log(addMoreSections, 'addmoresections')
        let fieldsToAdd = [{
            type: "text",
            name: "name",
            label: "Section Name",
            required: true,
        },
        {
            type: "text",
            name: "no_student",
            label: "No. of Students",
            max: 10000,
            required: true,
            customcolspan: "col-lg-5",
        },
        {
            name: "",
            type: "icon",
            className: "deleteIcon",
            label: `${imagepaths.deleteIcon}`,
            customcolspan: "col-lg-1",
            onClickOfIcon: (index) => {handleDeleteRow(index)}
        },
        {
            name: "+ Add more Section & Students",
            type: "button",
            className: "text-primary addMoreBtn",
            label: "+ Add more Section & Students",
            customcolspan: "col-lg-12",
            onButtonClick: (...args) => {handleButtonClick(...args)}
        }];
        setModalFields([...addMoreSections, ...fieldsToAdd ])
    };

    // const splitObjectsByKeySuffix = ({ data }) => {
    //     const result = [];
    //     let currentObject = {};

    //     for (const key in data) {
    //         if (data.hasOwnProperty(key) && key !== 'curriculum_id') {
    //             const [baseKey, suffix] = key.split('_');
    //             if (!currentObject.hasOwnProperty(baseKey)) {
    //                 currentObject[baseKey] = {};
    //             }
    //             currentObject[baseKey][key] = data[key];
    //         }
    //     }
    //     for (const key in currentObject) {
    //         if (currentObject.hasOwnProperty(key)) {
    //             result.push(currentObject[key]);
    //         }
    //     }

    //     const sortingresult = Object.keys(result[0]).map((key, i) => {
    //         return { [key]: result[0][key], [`no_student${i === 0 ? '' : '_' + (i * 2 + 4)}`]: result[1][`no_student${i === 0 ? '' : '_' + (i * 2 + 4)}`] };
    //     });

    //     // to change keys name in object
    //     const finalresult = sortingresult.map((obj, index) => {
    //         return {
    //             name: obj[`name${index === 0 ? '' : '_' + (index * 2 + 4)}`],
    //             no_student: obj[`no_student${index === 0 ? '' : '_' + (index * 2 + 4)}`]
    //         };
    //     });

    //     return finalresult;
    // }

    useEffect(() => {
        let formdata = Object.map(modalFields, (v, k) => "");
        setPopupdata(formdata);
        setPopupfields(modalFields);
    }, [modalFields]);

    const onAddClassInfo = () => {
        let fields = (modalFields || []).reduce(
            (o, n) => ({ ...o, [n.name]: n }),
            {}
        );
        // fields['curriculum_id'] = "";
        let formdata = Object.map(fields, (v, k) => "");
        // console.log("formdata: ", fields, formdata);
        setPopupdata(formdata);
        setPopupfields(fields);
        setModalOpened(true);
    }

    const onPageChange = (pageData) => {
        setSearch({
            ...search,
            page: pageData.current,
            limit: pageData.pageSize || pageData.defaultPageSize || 25,
        }, () => fetchClassList())
    }

    const deleteAction = (value) => {
        confirm(`Do you really want to delete this class?`)
        .promise.then(() => {
          props.api.apiDeleteClassConfig({class_id: value.class_id}).then((response) => {
            if (response.data.message)
                toast.error("Class deletion is not allowed please contact admin", toastConfig);
            else toast.success("Class Deleted Successfully", toastConfig);
            fetchClassList();
          });
        })
    }

    const editAction = (v) => {
        console.log("edit: ", v);

        setClassId(v.class_id);
        openClassModal(); 
        setEditMode(true);
        setViewMode(false);

        // filter only needed data for modal fields
        let { school_id, curriculum_id, section } = v;
        let filteredData = { school_id, curriculum_id };
        filteredData.section = section.map((v) => ({
          name: v.name,
          no_student: v.no_student,
        }));
        setEditData(filteredData);
    }

    const viewAction = (v) => {
        console.log("view: ", v);

        openClassModal();
        let fields = (modalFields || []).reduce(
            (o, n) => ({ ...o, [n.name]: n }),
            {}
        );
        let formdata = Object.except(v, [
            "class_id",
            "createdAt",
            "updatedAt",
            "created_by",
            "is_active",
            "last_updated_by",
            "school_id",
            "curriculum_grade",
            "curriculum_name",
            "name",
          ]);
        // Modify structure to view
        v.section.map((item, i) => {
        let index = i === 0 ? "" : "_" + (i * 2 + 2);
        formdata[`name${index}`] = item.name;
        formdata[`no_student${index}`] = item.no_student;
        });
        delete formdata.section;
        delete formdata.total_students;

        setPopupdata(formdata);
        setPopupfields(fields);
        setEditMode(false);
        setViewMode(true);
        setEditData(v);
    }

    const openClassModal = () => {
        classModalRef.current.click();
    }
    const closeModal = () => {
        document.querySelector("#add_class_modal #bsmodalclose").click();
    }


    const addField = (e) => {
        console.log({editMode});
        if(editMode){
            console.log({editData});
        let { school_id, curriculum_id, section } = editData;
        let filteredData = { school_id, curriculum_id };
        filteredData.section = [...section, { name: "", no_student: "" } ];
        setEditData(filteredData);
        }else{
            setSection([...section, { name: "", no_student: "" }]);
        }
    };

    const deleteField = (e, index,form) => {
      console.log({ index });
      e.preventDefault();
      if(editMode && !viewMode){
        let list;
        // console.log({editData});
        list = [...editData?.section];
        const remove = list.filter((_, indexFilter) => !(indexFilter === index));

        let { school_id, curriculum_id } = editData;
        let filteredData = { school_id, curriculum_id };

        filteredData.section = remove;
        setEditData(filteredData);
      } else if(!viewMode) {
        let list = [...section];
        const formData = form.current.form.current.getData();

        list.forEach((item, i) => {
            if (i >= index) {
            form.current.form.current.elements[`section.${i}.name`].value =
                formData[`section.${i + 1}.name`];
            form.current.form.current.elements[`section.${i}.no_student`].value =
                formData[`section.${i + 1}.no_student`];
            }
        });

        if (index >= 0 && index < list.length) {
            list.splice(index, 1);
          }
        setSection(list);
        setEditData(editData);
      }
    };
      
    const handleSubmit = (event, data, form) => {
        event.preventDefault();
        console.log("formData", data, form);
        let parsedPayload = parseInput(data);
        console.log({parsedPayload});

        setLoader(true);
        const resolveList = () => {
            toast.success(`${editMode ? "Class Updated Successfully" : "Class Configured Successfully"}`, toastConfig);
            form.reset();
            closeModal();
            fetchClassList();
        };
        const schoolId = (props.selectedEditMode || props.readMode) ? props.selectedRowData.school_id : localStorage.getItem("school_id");
        if (editMode) {
            let editPayload = {
                ...Object.except(parsedPayload, "curriculum_id")
            };
            props.api.apiEditClassInfo(editPayload, { class_id: classId })
                .then(resolveList)
                .then(() => {
                    setLoader(false);
                }).catch((error) => {
                    console.error("Error:", error);
                    setLoader(false);
                });
        } else {
            let addPayload = {
                school_id: schoolId,
                ...parsedPayload,
            };
              props.api.apiAddClassInfo(addPayload).then(resolveList).then((response) => {
                // console.log("Response", response);
                setLoader(false);
                fetchClassList();
              }).catch((error) => {
                console.error("Error:", error);
                setLoader(false);
              });
        }
    }


        return (
            <>
            {loader && <div className="loader-container">
					<div className="spinner"></div>
				</div>}
            <ToastContainer position="top-right" />
            <div className="bg-white p-2">
                <div className="row">
                    {/* <div className="col"></div> */}
                    {!props.readMode && role == "SUPER_ADMIN" && (
                        <div className="mt-4 pe-4">
                            <Link
                                ref={classModalRef}
                                data-bs-toggle="modal"
                                data-bs-target="#add_class_modal"
                                // onClick={(...args) => addModalFields(...args)}
                                className="btn add-btn me-2 mt-1"
                                onClick={() => onAddClassInfo()}
                            >
                                <i className="fa fa-plus"></i>
                                Add Class
                            </Link>
                        </div>
                    )}
                </div>
                <div className="row schoolmanagement">
                    <div className="col-12">
                        {!!tableData.length && (
                            <AppTable
                                data={tableData}
                                columns={columns}
                                onNext={() => onNext()}
                                onPrev={() => onPrev()}
                                onChange={(...arg) => onPageChange(...arg)}
                                // total={rowcount}
                                reorder={true}
                                // deactivatable={false}
                                deactivatable={false}
                                deletable={props.readMode || role == "SCHOOL_ADMIN" ? false : true}
                                editable={props.readMode || role == "SCHOOL_ADMIN" ? false : true}
                                deleteAction={(v) => deleteAction(v)}
                                editAction={(v) => editAction(v)}
                                viewAction={(v) => viewAction(v)}
                                targetType="tap"
                                showPagination={false}
                            ></AppTable>
                        )}
                        {tableData.length == 0 && <div className="empty_layout"></div>}
                    </div>
                </div>
                <div className="row m-auto">
                    <AppForm
                        className="flex flex-col gap-4 bg-white pt-0 pb-2"
                    >
                        {((!props.readMode && !props.selectedEditMode && role != "SCHOOL_ADMIN") || props.schoolStatus == "PENDING")
                        && <div className="row no-gutters m-2 mb-4">
                            <button className="btn close-btn col col-md-1 ml-auto rounded-1 text-dark " 
                             onClick={() => {props.setGoSteps(0)}}>
                                Previous
                            </button>
                            <button
                                type="submit"
                                className="btn save-btn col col-md-1 ml-auto ms-2 me-2 rounded-1"
                                onClick={() => {props.onClassConfigSubmit()}}
                            >
                                Save & Next
                            </button>
                        </div>}
                    </AppForm>
                </div>
                <ClassConfigModal
                    id="add_class_modal"
                    title={`${editMode ? "Edit Class Details" : viewMode ? "View Class Details" : "Add Class"}`}
                    submitText={`${editMode ? "Update" : "Submit"}`}
                    editdata={editData}
                    // data={popupdata}
                    // fields={popupfields}
                    disableallfields={viewMode ? true : false}
                    // disablefields={["email"]}
                    onSubmit={(...args) => handleSubmit(...args)}
                    submitButton={viewMode ? false : true}
                    closeButton={viewMode ? true : false}
                    modalopened={modalOpened}
                    classnames={classnames}
                    editmode={editMode}
                    viewmode={viewMode}
                    deleteField={(...args) => deleteField(...args)}
                    addField={(...args) => addField(...args)}
                    section={section}
                    onClose={() => 
                        {setPopupdata('');
                        setEditData('');
                        setEditMode(false);
                        setViewMode(false)
                        setModalOpened(false);
                        setSection([{}]);
                        }
                    }
                />
                {(!props.readMode && props.selectedEditMode && props.schoolStatus != "PENDING") && <div className="row m-auto">
                    <AppForm className="flex flex-col gap-4 bg-white pt-0 pb-2">
                        <div className="row no-gutters m-2 mb-4">
                            <button
                                className="btn close-btn col col-md-1 ml-auto rounded-1 text-dark "
                                onClick={() => location.reload()}
                            >Cancel
                            </button>
                        </div>
                    </AppForm>
                </div>}
                {/* below button hidden on UI */}
                <button
                    className="btn btn-sm btn-outline-secondary d-none"
                    onClick={(e) => onAddClassInfo(e)}
                    ref={classModalRef}
                    data-bs-toggle="modal"
                    data-bs-target="#add_class_modal"
                >
                    <span>Hidden Button</span>
                </button>
            </div>
            </>
        );
}

export default connect(ClassConfig);