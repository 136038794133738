import { Component } from "react";
import { toast, ToastContainer } from "react-toastify";

export class ManageAccess extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("ManageAccess");

    this.state = {
      tableData: [],
      data: [],
      loader: false,
      openAccordionIndex: null,
      openModuleIndex: null,
      openSubModuleIndex: null,
      disableUpdateBtn: true,
      selectedCurriculum: {},
      updateSessions: [],
    };
    this.role = localStorage.getItem("currentAdminRole");
  }

  onNext() {}

  onPrev() {}

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      () => this.fetchList()
    );
  }

  componentDidMount() {
    this.fetchManageAccessData();
  }

  componentDidUpdate() {
    let accord = document.getElementsByClassName("accordion-btn");

    for (let i = 0; i < accord.length; i++) {
      accord[i].onclick = function () {
        this.classList.toggle("active");
        this.nextElementSibling.classList.toggle("show");
      };
    }
  }

  fetchManageAccessData = () => {
    let school_id =
      this.props.selectedEditMode || this.props.readMode
        ? this.props.selectedRowData.school_id
        : localStorage.getItem("school_id");
    this.props.api
      .apiGetManageAccessData({
        school_id: school_id,
      })
      .then((res) => {
        this.setState({
          tableData: res.data.classes,
        });
      })
      .catch((error) => {
        console.log({ error: error });
      });
  };

  handleModuleToggle = (index) => {
    this.setState((prevState) => ({
      openModuleIndex: prevState.openModuleIndex === index ? null : index,
      openSubModuleIndex: null,
    }));
  };

  handleSubModuleToggle = (index) => {
    this.setState((prevState) => ({
      openSubModuleIndex: prevState.openSubModuleIndex === index ? null : index,
    }));
  };

  handleModuleCheckboxChange = (event, classIndex, moduleIndex) => {
    const tableDataCopy = [...this.state.tableData];
    const moduleCopy = { ...tableDataCopy[classIndex].modules[moduleIndex] };
    const isChecked = event.target.checked;

    // Update the copy of the module with the new value
    moduleCopy.is_eligible = isChecked;

    // If the module is changed, change submodules as well
    moduleCopy.sub_modules.forEach((submodule) => {
      submodule.is_eligible = isChecked;
      submodule.sessions.forEach((session) => {
        session.is_eligible = isChecked;
      });
    });

    // Update the state with the modified copy
    tableDataCopy[classIndex].modules[moduleIndex] = moduleCopy;

    // Set the updated state
    this.setState({
      tableData: tableDataCopy,
    });
  };

  handleSubModuleCheckboxChange = (
    event,
    classIndex,
    moduleIndex,
    submoduleIndex
  ) => {
    const tableDataCopy = [...this.state.tableData];
    const isChecked = event.target.checked;
    const submoduleCopy = {
      ...tableDataCopy[classIndex].modules[moduleIndex].sub_modules[
        submoduleIndex
      ],
    };

    // Update the copy of the submodule with the new value
    submoduleCopy.is_eligible = isChecked;

    // If the submodule is changed, change all sessions as well
    submoduleCopy.sessions.forEach((session) => {
      session.is_eligible = isChecked;
    });

    // Update the state with the modified copy
    tableDataCopy[classIndex].modules[moduleIndex].sub_modules[submoduleIndex] =
      submoduleCopy;

    if (isChecked) {
      tableDataCopy[classIndex].modules[moduleIndex].is_eligible = true;
    }
    // Set the updated state
    this.setState({
      tableData: tableDataCopy,
    });
  };

  handleSessionCheckboxChange = (
    event,
    classIndex,
    moduleIndex,
    submoduleIndex,
    sessionIndex
  ) => {
    const tableDataCopy = [...this.state.tableData];
    const moduleCopy = { ...tableDataCopy[classIndex].modules[moduleIndex] };
    const submoduleCopy = { ...moduleCopy.sub_modules[submoduleIndex] };
    const sessionCopy = { ...submoduleCopy.sessions[sessionIndex] };
    const isChecked = event.target.checked;

    // Update the copy of the session with the new value
    sessionCopy.is_eligible = isChecked;

    // Update the state with the modified copy
    tableDataCopy[classIndex].modules[moduleIndex].sub_modules[
      submoduleIndex
    ].sessions[sessionIndex] = sessionCopy;

    if (isChecked) {
      tableDataCopy[classIndex].modules[moduleIndex].is_eligible = true;
      tableDataCopy[classIndex].modules[moduleIndex].sub_modules[
        submoduleIndex
      ].is_eligible = true;
    }
    // Set the updated state
    this.setState({
      tableData: tableDataCopy,
    });

    this.findSessionDetails(tableDataCopy, sessionCopy);
  };

  findSessionDetails = (tableDataCopy, sessionCopy) => {
    for (let school of tableDataCopy) {
      for (let module of school.modules) {
        for (let subModule of module.sub_modules) {
          for (let session of subModule.sessions) {
            if (session.session_id === sessionCopy.session_id) {
              this.setState((prevState) => {
                let newSessionData = {
                  module_id: module.module_id,
                  sub_module_id: subModule.sub_module_id,
                  session_id: session.session_id,
                  session_name: session.session_name,
                  session_description: session.session_description,
                  is_eligible: session.is_eligible,
                };
                const isSameCurriculum =
                  prevState.selectedCurriculum?.curriculum_id ===
                  school.curriculum_id;
                let updatedSessions = [...prevState.updateSessions];

                if (isSameCurriculum) {
                  const index = prevState.updateSessions.findIndex(
                    (s) => s.session_id === sessionCopy.session_id
                  );

                  if (index !== -1) {
                    updatedSessions.splice(index, 1);
                  } else {
                    // If session doesn't exist, add it
                    updatedSessions.push(newSessionData);
                  }
                } else {
                  updatedSessions = [newSessionData];
                }

                return {
                  selectedCurriculum: {
                    school_id: school.school_id,
                    class_id: school.class_id,
                    curriculum_id: school.curriculum_id,
                  },
                  updateSessions: updatedSessions,
                  disableUpdateBtn: updatedSessions.length > 0 ? false : true,
                };
              });
              return;
            }
          }
        }
      }
    }
  };

  handleSubmit = (e) => {
    let school_id =
      this.props.selectedEditMode || this.props.readMode
        ? this.props.selectedRowData.school_id
        : localStorage.getItem("school_id");

    let payloadData = this.state.tableData;
    this.setState({
      loader: true,
    });
    this.props.api
      .apiUpdateManageAccessData(
        {
          data: {
            classes: payloadData.map(({ curriculum, ...rest }) => rest),
          },
        },
        {
          school_id: school_id,
        }
      )
      .then((res) => {
        toast.success("Access Data Updated Successfully", {
          autoClose: 3000,
          closeButton: false,
          hideProgressBar: true,
        });
        this.setState({
          loader: false,
        });
        this.props.setGoSteps(3);
      })
      .catch((err) => {
        this.setState({
          loader: false,
        });
      });
  };

  updateCurriculum = (data) => {
    let school_id =
      this.props.selectedEditMode || this.props.readMode
        ? this.props.selectedRowData.school_id
        : localStorage.getItem("school_id");

 
    // let payloadData = this.state.tableData;
    // let curriculumObj = payloadData.map(({ curriculum, ...rest }) => rest);
    // let payload;
    // curriculumObj.forEach((curriculum) => {
    //   if (curriculum.curriculum_id === data.curriculum_id) {
    //     payload = curriculum;
    //   }
    // });

    let payload = {
      school_id: this.state.selectedCurriculum.school_id,
      curriculum_id: this.state.selectedCurriculum.curriculum_id,
      class_id: this.state.selectedCurriculum.class_id,
      sessions: [...this.state.updateSessions],
    };

    // console.log(payload, 'payload');
    if (payload.sessions.length < 40) {
      this.setState({
      loader: true,
    });
      this.props.api
        .apiUpdateManageAccessByIdData(
          {
            ...payload,
          },
          {
            school_id: school_id,
          }
        )
        .then((res) => {
          // console.log("apiEditGenericInfo: ", res);
          toast.success("Access Data Updated Successfully", {
            autoClose: 3000,
            closeButton: false,
            hideProgressBar: true,
          });
          this.setState({
            loader: false,
            updateSessions: [],
          });
          this.props.setGoSteps(3);
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
        });
    } else {
      alert("Update session threshold limit is crossed, Please contact support.");
    }
  };

  render() {
    const { tableData, disableUpdateBtn, openAccordionIndex } = this.state;

    return (
      <>
        {this.state.loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <ToastContainer position="top-right" />
        <div className="bg-white p-3">
          {tableData.map((data, classIndex) => (
            <div key={classIndex} className="accordion nested_accordion">
              <button
                className="accordion-btn outer-accord"
                onClick={() =>
                  this.setState({
                    openAccordionIndex:
                      openAccordionIndex === classIndex ? null : classIndex,
                  })
                }
              >
                <span>
                  <span>Class {data.curriculum.curriculum_grade}</span>
                </span>
                <span className="arrow-border">
                  <i className="arrow up"></i>
                </span>
              </button>
              <div
                className={`panel ${
                  openAccordionIndex === classIndex ? "show" : ""
                }`}
              >
                {data.modules.map((moduleData, moduleIndex) => (
                  <div key={moduleIndex}>
                    <button
                      className="accordion-btn inner-accord"
                      onClick={() => this.handleModuleToggle(moduleIndex)}
                    >
                      <span>
                        <input
                          className="manage_check"
                          type="checkbox"
                          disabled={this.props.readMode}
                          onChange={(e) => {
                            this.setState({
                              disableUpdateBtn: false,
                            });
                            this.handleModuleCheckboxChange(
                              e,
                              classIndex,
                              moduleIndex
                            );
                          }}
                          checked={
                            tableData[classIndex].modules[moduleIndex]
                              .is_eligible
                          }
                        />
                        <span>{moduleData.module_name}</span>
                      </span>
                      <span className="arrow-border">
                        <i className="arrow up"></i>
                      </span>
                    </button>
                    <div className="panel">
                      {moduleData.sub_modules.map(
                        (submoduleData, submoduleIndex) => (
                          <div key={submoduleIndex}>
                            <button
                              className="accordion-btn inner-inner-accord"
                              onClick={() =>
                                this.handleSubModuleToggle(submoduleIndex)
                              }
                            >
                              <span>
                                <input
                                  className="manage_check"
                                  type="checkbox"
                                  disabled={this.props.readMode}
                                  onChange={(e) => {
                                    this.setState({
                                      disableUpdateBtn: false,
                                    });
                                    this.handleSubModuleCheckboxChange(
                                      e,
                                      classIndex,
                                      moduleIndex,
                                      submoduleIndex
                                    );
                                  }}
                                  checked={
                                    tableData[classIndex].modules[moduleIndex]
                                      .sub_modules[submoduleIndex].is_eligible
                                  }
                                />
                                <span>{submoduleData.sub_module_name}</span>
                              </span>
                              <span className="arrow-border">
                                <i className="arrow up"></i>
                              </span>
                            </button>
                            <div className="panel">
                              <div className="row ml-4">
                                {submoduleData.sessions.map(
                                  (session, sessionIndex) => (
                                    <div
                                      key={sessionIndex}
                                      className="content1 col-6"
                                    >
                                      <input
                                        className="manage_check"
                                        type="checkbox"
                                        disabled={this.props.readMode}
                                        onChange={(e) => {
                                          this.handleSessionCheckboxChange(
                                            e,
                                            classIndex,
                                            moduleIndex,
                                            submoduleIndex,
                                            sessionIndex
                                          );
                                        }}
                                        checked={
                                          tableData[classIndex].modules[
                                            moduleIndex
                                          ].sub_modules[submoduleIndex]
                                            .sessions[sessionIndex].is_eligible
                                        }
                                      />{" "}
                                      <span>{session.session_name}</span>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ))}

                {this.props.selectedEditMode &&
                this.props.schoolStatus !== "PENDING" ? (
                  <div className="d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn save-btn col col-md-1 ml-auto mt-3 ms-2 me-2 rounded-1"
                      disabled={this.props.selectedEditMode && disableUpdateBtn}
                      onClick={() => {
                        this.setState({
                          disableUpdateBtn: true,
                        });
                        this.updateCurriculum(data);
                      }}
                    >
                      Update
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
          {tableData.length == 0 && <div className="empty_layout"></div>}
          {/* </AppForm> */}
        </div>
        <div className="row m-auto">
          <AppForm className="flex flex-col gap-4 pt-0 pb-2">
            {!this.props.readMode && this.role !== "SCHOOL_ADMIN" && (
              <div className="row no-gutters m-2 mb-4">
                {this.props.selectedEditMode &&
                this.props.schoolStatus !== "PENDING" ? (
                  ""
                ) : (
                  <div className="d-flex">
                    <button
                      className="btn close-btn col col-md-1 ml-auto mt-3 rounded-1 text-dark "
                      onClick={() => {
                        this.props.selectedEditMode &&
                        this.props.schoolStatus !== "PENDING"
                          ? location.reload()
                          : this.props.setGoSteps(1);
                      }}
                    >
                      Previous
                    </button>
                    <button
                      type="submit"
                      className="btn save-btn col col-md-1 ml-auto mt-3 ms-2 me-2 rounded-1"
                      onClick={() => {
                        this.handleSubmit();
                      }}
                    >
                      Save & Next
                    </button>
                  </div>
                )}
              </div>
            )}
          </AppForm>
        </div>
      </>
    );
  }
}

export default connect(ManageAccess);
