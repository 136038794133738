export default {
    tablinks: [
        {
            path: "/contentmanagement/sessions/addsessiondetails",
            label: "Session Details"
        },
        {
            path: "/contentmanagement/sessions/addvideos",
            label: "Videos"
        },
        {
            path: "/contentmanagement/sessions/addppt",
            label: "Manual"
        },
        {
            path: "/contentmanagement/sessions/addhandouts",
            label: "Handouts"
        },
        {
            path: "/contentmanagement/sessions/addassessments",
            label: "Assessments"
        },
    ]
}