import smMapcolumns from "./sm.mapcolumns";

export default {
  apiGetSchools(data) {
    let payload = {
      ...data,
    };

    return this.request(this.api().getSchool, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data[0].records.map((record) =>
            smMapcolumns.mapFields("schools", record)
          ),
          total: response.data[0].pagination[0]?.totalRecords || 0,
        };
      })
      .get();
  },
  apiAddSchool(data) {
    console.log({ apiAddSchool: data });
    let payload = {
      ...smMapcolumns.mapPayload("schools", data),
    };
    payload = Object.filter(payload, (v) => v);

    // const profile_id = JSON.parse(localStorage.getItem("userresponse")).data[0].records[0].profile_id;

    // payload = {admin_profile_id: profile_id, ...payload}
    // delete(payload.admin_name)

    return this.request(this.api().createSchool, payload).exec("Please wait").log().get();
  },
  apiEditSchool(data, urldata = {}) {
    delete Object.assign(data, { ["admin_name"]: data["school_admin"] })[
      "school_admin"
    ];
    console.log({ apiEditSchool: data, urldata });
    urldata = { ...mixins.params(), ...urldata };
    // let payload =
    // {
    //     ...smMapcolumns.mapPayload('schools', data),
    // }
    // payload = Object.filter(payload, (v) => v);

    return this.request(this.api().updateSchoolById, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiDeleteSchool(urldata = mixins.params()) {
    return this.request(this.api().deleteSchool)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiGetAllProfile(data) {
    let payload = {
      ...data,
    };

    return this.request(this.api().getAllProfile, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data[0].records.map((record) =>
            smMapcolumns.mapFields("employees", record)
          ),
          total: response.data[0].pagination[0]?.totalRecords || 0,
        };
      })
      .get();
  },
  apiCreateProfile(data) {
    console.log({ apiAddSchool: data });
    let payload = {
      ...smMapcolumns.mapPayload("employees", data),
    };
    payload = Object.filter(payload, (v) => v);

    // const profile_id = JSON.parse(localStorage.getItem("userresponse")).data[0].records[0].profile_id;

    // payload = {admin_profile_id: profile_id, ...payload}
    // delete(payload.admin_name)

    return this.request(this.api().createProfile, payload).exec().log().get();
  },
  apiUpdateProfile(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().updateProfile, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiDeleteProfile(urldata = mixins.params()) {
    return this.request(this.api().deleteProfile)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiGetProfileById(data) {
    let urldata = {...mixins.params()}
    let payload = {
      ...data,
    };

    return this.request(this.api().getAllProfile, payload)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data[0].records.map((record) =>
            smMapcolumns.mapFields("employees", record)
          ),
          total: response.data[0].pagination[0]?.totalRecords || 0,
        };
      })
      .get();
  },
};
