import React, { useEffect, useState } from "react";
import Cropper from "react-easy-crop";

const AppImageCropper = ({ image, onCropDone, onCropCancel, getFile }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [croppedArea, setCroppedArea] = useState("");
  const [aspectRatio, setAspectRatio] = useState(1 / 1);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    // setCroppedArea(croppedAreaPixels, () => {
    //   onCropDone(croppedArea);
    // });
    console.log({ croppedAreaPixels });
    setCroppedArea(croppedAreaPixels);
  };

  useEffect(() => {
    onCropDone(croppedArea);
  }, [croppedArea]);

  return (
    <>
      <Cropper
        image={image}
        aspect={aspectRatio}
        crop={crop}
        // zoom={zoom}
        // onZoomChange={setZoom}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        style={{
          containerStyle: {
            width: "100%",
            height: "100%",
            backgroundColor: "#fff",
          },
        }}
      />
      <div className="btn_container">
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={onCropCancel}
        >
          Change Image
        </button>

        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            getFile();
          }}
        >
          Crop & Upload
        </button>
      </div>
    </>
  );
};

export default AppImageCropper;
