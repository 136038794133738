export default {

    apiGetAllLeads(data) {
        let payload = {
            ...data,
        };
        return this.request(this.api().getAllLeads, payload)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0,
                };
            })
            .get();
    },
    apiAddLead(data) {
        // console.log({ apiAddLead: data });
        let payload = {
            ...data,
        };
        return this.request(this.api().addLead, payload).exec().log().get();
    },
    apiUpdateLead(data, urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().updateLead, data)
            .urltransform(urldata)
            .exec()
            .log()
            .get();
    },
    apiDeleteLead(urldata = mixins.params()) {
        return this.request(this.api().deleteLead)
            .urltransform(urldata)
            .exec()
            .log()
            .get();
    },
}