import React, { Component } from "react";
import { Link } from "react-router-dom";
import GalleryModal from "./GalleryModal";
import LayoutWrapper from "./LayoutWrapper";
import { Button, Modal } from "react-bootstrap";
import PhotoModal from "./PhotoModal";

export class GalleryManagement extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("GalleryManagement");
    this.state = {
      data: [],
      rowcount: 0,
      search: {
        limit: 10,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
      editData: {},
      columns: [],
      currentView: "photos",
      popupfields: [],
      popupdata: {},
      editMode: false,
      sections: [],
      loader: false,
      show: false,
    };
    this.photoColumns = [
      {
        dataIndex: "photo_name",
        title: "Photo Name",
      },
      {
        dataIndex: "photo_id",
        title: "Attachment",
        render: (text, record) => {
          return (
            <a
              href="#"
              className="link_btn"
              onClick={(e) => this.openPhotoModal(e, record?.photo_id)}
            >
              Attachment here
            </a>
          );
        },
      },
      {
        dataIndex: "photo_description",
        title: "Description",
      },
      {
        dataIndex: "createdAt",
        title: "Created on",
        render: (text, record) => {
          let date = new Date(text).dateToDDMMYYYY(new Date(text));
          return date;
        },
      },
      {
        dataIndex: "created_by_name",
        title: "Created by",
      },
    ];
    this.videoColumns = [
      {
        dataIndex: "video_name",
        title: "Video Name",
      },
      {
        dataIndex: "link",
        title: "Attachment",
        render: (text) => {
          return (
            <a href={text} className="link_btn" target="_blank">
              Attachment here
            </a>
          );
        },
      },
      {
        dataIndex: "video_description",
        title: "Description",
      },
      {
        dataIndex: "createdAt",
        title: "Created on",
        render: (text, record) => {
          let date = new Date(text).dateToDDMMYYYY(new Date(text));
          return date;
        },
      },
      {
        dataIndex: "created_by_name",
        title: "Created by",
      },
    ];
    this.photoFields = [];
    this.videoFields = [
      {
        type: "text",
        name: "video_name",
        label: "Video Name",
        required: true,
      },
      {
        type: "text",
        name: "link",
        label: "Video Link",
        required: true,
      },
      // {
      //   type: "file",
      //   fileType: "gallery",
      //   name: "video_thumbnail",
      //   label: "Video thumbnail",
      // },
      {
        type: "textarea",
        name: "video_description",
        label: "Video Description",
      },
    ];

    this.modalRef = React.createRef();
    this.photoModalRef = React.createRef();
  }

  componentDidMount() {
    this.fetchPhotosData();
  }
  // DATA FETCHING START

  fetchPhotosData() {
    this.setState({
      loader: true,
    });
    let payload = {
      ...this.state.search,
    };
    this.props.api.apiGetGalleryPhotos(payload).then((res) => {
      console.log({ apiGetGalleryPhotosDataMoke: res });

      this.photoFields = [
        {
          type: "text",
          name: "photo_name",
          label: "Photo Name",
          customcolspan: "12",
          required: true,
        },
        {
          type: "text",
          name: "createdBy",
          label: "Created By",
        },
        {
          type: "date",
          name: "createdOn",
          label: "Created On",
        },
        {
          type: "textarea",
          name: "photo_description",
          label: "Photo Description",
          customcolspan: "col-12"
        },
        // {
        //   type: "file",
        //   fileType: "gallery",
        //   name: "attachmentFile",
        //   label: "Upload Photo",
        // },
        {
          type: "fileCrop",
          fileType: "gallery",
          name: "photo_attachment",
          label: "Upload Photo",
          customcolspan: "12",
          required: true,
        },
      ];
      this.setState({
        data: res.data,
        rowcount: res.total,
        loader: false,
        columns: this.photoColumns,
        popupfields: this.photoFields,
      });
    });
  }

  fetchVideoData() {
    this.setState({
      loader: true,
    });
    let payload = {
      ...this.state.search,
    };
    // this.props.api.apiGetGalleryVideosDataMoke().then((data) => {
    //   this.setState({
    //     data,
    //     loader: false,
    //   });
    // });
    this.props.api
      .apiGetGalleryVideos(this.state.search, payload)
      .then((res) => {
        console.log("gallery videos data: ", res);
        this.setState({
          data: res.data,
          rowcount: res.total,
        });
      })
      .then(() => {
        this.setState({
          loader: false,
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
        });
      });
  }
  // DATA FETCHING END

  onNext() {}

  onPrev() {}

  onPageChange(pageData) {
    if (this.state.currentView == "photos") {
      this.setState(
        {
          search: {
            ...this.state.search,
            page: pageData.current,
            limit: pageData.pageSize || pageData.defaultPageSize || 10,
          },
        },
        () => this.fetchPhotosData()
      );
    } else {
      this.setState(
        {
          search: {
            ...this.state.search,
            page: pageData.current,
            limit: pageData.pageSize || pageData.defaultPageSize || 10,
          },
        },
        () => this.fetchVideoData()
      );
    }
  }

  // TABLE FUNCTIONALITIES START
  deleteAction(v) {
    console.log("delete ", v);
    if (this.state.currentView == "photos") {
      let payload = {
        photo_id: v?.photo_id,
      };

      confirm(`Do you really want to delete this record?`)
        .promise.then(() => {
          this.api.apiDeleteGalleryPhoto(payload).then(() => {
            success("Photo Deleted successfully");
            this.fetchPhotosData();
          });
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
        });
    } else {
      let payload = {
        video_id: v?.video_id,
      };

      confirm(`Do you really want to delete these records?`).promise.then(
        () => {
          this.api.apiDeleteGalleryVideo(payload).then((res) => {
            success("Record Deleted successfully");
            this.fetchVideoData();
          });
        }
      );
    }
  }

  editAction(v) {
    console.log("edit: ", v);
    this.openModal();
    if (this.state.currentView == "photos") {
      this.setState((prevState) => ({
        popupdata: {
          ...v,
        },
        editData: v,
        editMode: true,
        viewMode: false,
      }));
    } else {
      this.setState((prevState) => ({
        popupdata: {
          ...v,
        },
        editData: v,
        editMode: true,
        viewMode: false,
      }));
    }
  }

  viewAction(v) {
    console.log("view: ", v);
    this.openModal();
    if (this.state.currentView == "photos") {
      this.setState((prevState) => ({
        popupdata: {
          ...v,
        },
        editData: v,
        editMode: false,
        viewMode: true,
      }));
    } else {
      let filteredData = Object.only(
        v,
        this.videoFields.map((v) => v.name)
      );
      console.log({ filteredData });
      this.setState((prevState) => ({
        popupdata: {
          ...filteredData,
        },
        editData: v,
        editMode: false,
        viewMode: true,
      }));
    }
  }
  // TABLE FUNCTIONALITIES END

  // MODAL FUNCTIONS START
  openModal() {
    this.modalRef.current.click();
  }

  closeModal() {
    document.querySelector("#gallery_modal #bsmodalclose").click();
  }

  openPhotoModal() {
    this.photoModalRef.current.click();
  }

  closePhotoModal() {
    document.querySelector("#photo_modal #bsmodalclose").click();
  }
  addModalFields(values, modalfields) {
    console.log({ values, modalfields });
    const fields = (modalfields || []).reduce((o, n) => {
      o[n.name] = n;
      return o;
    }, {});

    const formdata = Object.keys(fields).reduce((o, k) => {
      o[k] = "";
      return o;
    }, {});
    this.setState({
      popupfields: fields,
      popupdata: formdata,
    });
  }
  // MODAL FUNCTIONS START
  handleTabChange(e) {
    console.log(e);
    if (e == "photos") {
      this.setState({
        columns: this.photoColumns,
        currentView: "photos",
        popupfields: this.photoFields,
      });
      this.fetchPhotosData();
    } else {
      this.setState({
        columns: this.videoColumns,
        currentView: "videos",
        popupfields: this.videoFields,
      });
      this.fetchVideoData();
    }
  }

  handleSubmit(e, data, form) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      loader: true,
    });

    const resolvelist = (title) => {
      setTimeout(() => {
        success(`${title} added successfully`);
        this.setState({
          loader: false,
        });
        this.closeModal();
      }, 1000);
    };

    let payload;

    if (this.state.currentView == "photos") {
      payload = {
        ...Object.except(data, ["createdBy", "createdOn"]),
      };
      console.log({ payload });

      if (typeof payload?.photo_attachment !== "string") {
        this.setState({
          loader: false,
        });
        alert("Please crop and upload the image");
        return;
      }
      if (!this.state.editMode) {
        this.props.api
          .apiCreateGalleryPhoto(payload)
          .then((res) => {
            console.log("apiCreateGalleryPhoto: ", res);
            resolvelist("Photos");
            this.fetchPhotosData();
          })
          .then(() => {
            this.setState({
              loader: false,
            });
          })
          .catch((err) => {
            this.setState({
              loader: false,
            });
          });
      } else {
        let urldata = {};
        // this.props.api.apiEditStudent(payload, urldata).then((res) => {
        //   // console.log("apiAddSchoolMoke: ", res);
        //   success("Student Edited successfully");
        //   this.closeModal();
        //   this.fetchPhotosData();
        // }).then(() => {
        //   this.setState({
        //     loader: false
        //   })
        // }).catch((err) => {
        //   this.setState({
        //     loader: false
        //   })
        // });
      }
    } else {
      payload = {
        ...data,
      };
      console.log({ payload });

      if (!this.state.editMode) {
        this.props.api
          .apiCreateGalleryVideo(payload)
          .then((res) => {
            console.log("apiCreateGalleryVideo: ", res);
            resolvelist("Videos");
            this.fetchVideoData();
          })
          .then(() => {
            this.setState({
              loader: false,
            });
          })
          .catch((err) => {
            this.setState({
              loader: false,
            });
          });
      } else {
        let urldata = {};
        // this.props.api.apiEditTable(payload, urldata).then((res) => {
        //   console.log("apiAddSchoolMoke: ", res);
        //   success("Table Edited successfully");
        //   this.closeModal();
        //   this.fetchVideoData();
        // }).catch((err) => {
        //   console.log("tablerr", err);
        // }).then(() => {
        //   this.setState({
        //     loader: false
        //   })
        // })
        // .catch((err) => {
        //   this.setState({
        //     loader: false
        //   })
        // });
      }
    }
  }

  render() {
    let {
      data = [],
      rowcount,
      columns,
      currentView,
      viewMode,
      popupfields,
      editMode,
      popupdata,
      editData,
      loader,
    } = this.state;
    let { videoFields, photoFields } = this;
    return (
      <>
        {loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <LayoutWrapper
          title="Gallery Management"
          back="Gallery Management"
        >
          <div className="row">
            <div className="col-md-12">
              <button
                className={`btn rounded-full border mx-1 min-w-[150px] m-clip ${
                  currentView == "photos" ? "tab-active" : ""
                }`}
                onClick={() => this.handleTabChange("photos")}
              >
                Photos
              </button>
              <button
                className={`btn rounded-full border mx-1 min-w-[150px] m-clip ${
                  currentView == "videos" ? "tab-active" : ""
                }`}
                onClick={() => this.handleTabChange("videos")}
              >
                Videos
              </button>
            </div>
          </div>
          {/* Student Tabs */}
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col"></div>
                <div className="mb-4">
                  {currentView == "photos" && (
                    <Link
                      className="btn add-btn"
                      ref={this.modalRef}
                      data-bs-toggle="modal"
                      data-bs-target="#gallery_modal"
                      onClick={(...args) => {
                        this.addModalFields(...args, photoFields);
                      }}
                    >
                      <i className="fa fa-plus"></i>
                      Add Photos
                    </Link>
                  )}
                  {currentView == "videos" && (
                    <Link
                      className="btn add-btn"
                      ref={this.modalRef}
                      data-bs-toggle="modal"
                      data-bs-target="#gallery_modal"
                      onClick={(...args) => {
                        this.addModalFields(...args, videoFields);
                      }}
                    >
                      <i className="fa fa-plus"></i>
                      Add Videos
                    </Link>
                  )}
                </div>
              </div>

              <div className="row GalleryManagement">
                <div className="col-12">
                  {!!data.length && (
                    <AppTable
                      data={data}
                      columns={columns}
                      onNext={() => this.onNext()}
                      onPrev={() => this.onPrev()}
                      onChange={(...arg) => this.onPageChange(...arg)}
                      total={rowcount}
                      reorder={true}
                      pageSize={50}
                      deleteAction={(v) => this.deleteAction(v)}
                      editable={false}
                      viewable={false}
                      // editAction={(v) => this.editAction(v)}
                      // viewAction={(v) => this.viewAction(v)}
                      targetType="tap"
                    ></AppTable>
                  )}
                  {data.length == 0 && <div className="empty_layout"></div>}
                </div>
              </div>
              <Link
                className="btn add-btn d-none"
                ref={this.photoModalRef}
                data-bs-toggle="modal"
                data-bs-target="#photo_modal"
                onClick={(...args) => {
                  this.openPhotoModal(...args);
                }}
              >
                <i className="fa fa-plus"></i>
                Add Videos
              </Link>
              <GalleryModal
                id="gallery_modal"
                title={`${editMode ? "Update" : viewMode ? "View" : "Add"} ${
                  currentView == "photos" ? "Photo" : "Video"
                }`}
                submitText={`${
                  editMode ? "Update" : viewMode ? "View" : "Add"
                } ${currentView == "photos" ? "Photo" : "Video"}`}
                data={popupdata}
                fields={popupfields}
                disableallfields={viewMode}
                editData={editData}
                onSubmit={(...args) => {
                  this.handleSubmit(...args);
                }}
                onClose={() =>
                  this.setState({
                    popupdata: {},
                    editData: {},
                    editMode: false,
                    viewMode: false,
                  })
                }
              />
              <PhotoModal id="photo_modal" title="" className="photo_modal">
                <img
                  src="https://i.ytimg.com/vi/u31qwQUeGuM/maxresdefault.jpg"
                  className="img-fluid"
                  alt=""
                />
              </PhotoModal>
            </div>
          </div>
        </LayoutWrapper>
      </>
    );
  }
}

export default connect(GalleryManagement);
