export default {
    apiGetAllInvoiceForm(data) {
        return this.request(this.api().getInvoiceForm, data)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0,
                };
            })
            .get();
    },
    apiAddInvoiceForm(data) {
        let payload = {
          ...smMapcolumns.mapPayload("Invoice Form", data),
        };
        payload = Object.filter(payload, (v) => v);
    
        return this.request(this.api().addInvoiceForm, payload).exec().log().get();
    },
    apiDeleteInvoiceForm(urldata = mixins.params()) {
        return this.request(this.api().deleteInvoiceForm)
            .urltransform(urldata)
            .exec()
            .log()
            .get();
    },
    apiDeleteDevice(urldata = mixins.params()) {
        return this.request(this.api().deleteDevice)
            .urltransform(urldata)
            .exec()
            .log()
            .get();
    },
}