import React from 'react';
import { toast } from "react-toastify";

const passwordResetModal = ({ userId, otp, onClose }) => {
  const handleShare = () => {
    const textToCopy = `User Id: ${userId}\nOne Time Password: ${otp}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success("Copied to clipboard!", {
          autoClose: 3000,
          closeButton: false,
          hideProgressBar: true,
        });
        onClose();
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <>
      <div className="modal-overlay">
        <div className="popup">
          <button className="close-btn" onClick={onClose}>
            ×
          </button>
          <div className="tick_mark">
            <h1>✔</h1>
          </div>
          <h2>New Password Details  </h2>
          <p>
            User Id: <span>{userId}</span>
          </p>
          <p>
            One Time Password: <span>{otp}</span>
          </p>
          <button className="share-btn" onClick={handleShare}>
            Share
          </button>
        </div>
      </div>
    </>
  );
};

export default passwordResetModal;
