import React, { Component } from "react";
import { Link } from "react-router-dom";
import SchoolModal from "./SchoolModal";
import { toast, ToastContainer  } from "react-toastify";

export class SchoolCoordinatorConfig extends Component {
    constructor(props) {
        super(props);
        props.initialconstruct.bind(this)("SchoolCoordinatorConfig");
        this.state = {
            tableData: [],
            editData: {},
            viewMode: false,
            editMode: false,
            popupfields: {},
            popupdata: {},
            loader: false,
            showProfile: false,
            search: {
				limit: 50,
				page: 1,
				order_by: "updatedAt",
				order_direction: "DESC",
			},
            modalFields: [
                {
                    type: "select",
                    name: "school_coordinator",
                    label: "Name",
                    placeholder: "Select",
                    options: [],
                    required: true,
                    customcolspan: 'col-lg-12',
                    onChange: (...args) => { this.handleProfileDetails(...args) }
                }
            ],
        };
        this.columns = [
            {
                dataIndex: "name",
                title: "Name",
            },
            {
                dataIndex: "email",
                title: "Email ID",
            },
            {
				dataIndex: "mobile_number",
				title: "Mobile Number",
			},
            {
                dataIndex: "state",
                title: "State",
            },
            {
                dataIndex: "city",
                title: "City",
            }
        ];
        this.profileDetails = [
            {
                type: "text",
                name: "mobile_number",
                label: "Mobile Number",
                disabled: true,
            },
            {
                type: "text",
                name: "email",
                label: "Email ID",
                disabled: true,
            },
            {
                type: "text",
                name: "state",
                label: "State",
                disabled: true,
            },
            {
                type: "text",
                name: "city",
                label: "City",
                disabled: true,
            },
            {
                type: "textarea",
                className: "schoolName",
                name: "school_name",
                label: "Associated Schools",
                placeholder: " ",
                disabled: true,
                customcolspan: 'col-lg-12',
            }
        ];
        this.schoolAdminModalRef = React.createRef();
        this.role = localStorage.getItem("currentAdminRole");
    }

    addModalFields() {
        let fields = (this.state.modalFields || []).reduce(
            (o, n) => ({ ...o, [n.name]: n }),
            {}
        );

        let formdata = Object.map(fields, (v, k) => "");
        // clear all field data here
        // console.log("formdata: ", fields, formdata);
        this.setState({
            popupfields: fields,
            popupdata: formdata,
        });
    }

    getAllProfiles() {
        this.setState({ loader: true })
		this.props.api.apiGetAllProfile(this.state.search).then(({ data, total }) => {
            const updatedFields = this.state.modalFields.find((o) => o.name == "school_coordinator");
            updatedFields.options = data?.options("profile_id", "name");
            this.setState({ loader: false })
		}).catch((err) => {
			this.setState({
				loader: false
			})
		});
	} 

    getSchoolCoordinatorById(){
        this.setState({ loader: true })
        let school_id = "";
        if(this.role == "SCHOOL_ADMIN") {
            school_id = localStorage.getItem("school_admin_id");
        } else {
            school_id = (this.props.selectedEditMode || this.props.readMode) ? this.props.selectedRowData.school_id : localStorage.getItem("school_id");
        }
        let payload = {
            ...this.state.search,
        }
        this.props.api.apiGetSchoolCoordinatorsById(payload, {school_id: school_id})
        .then((response) => {
            // console.log({apiGetSchoolCoordinatorsById: response.data});
            if(response.data && response.data.length !== 0) {
                this.setState({
                    tableData: response.data[0].records,
                    loader: false,
                });
            }
        }).catch((error) => {
            console.log('error', error);
            this.setState({ loader: false })
        });
    }

    handleProfileDetails(value){
        this.setState({ showProfile: value[0].profile_id ? true : false })
        this.props.api.apiGetProfileDetailsById({profile_id: value[0].profile_id})
        .then((response) => {
            const mobile_number = this.profileDetails.find((o) => o.name === "mobile_number");
            mobile_number.value = response.data?.mobile_number;
            const email = this.profileDetails.find((o) => o.name === "email");
            email.value = response.data?.email;
            const state = this.profileDetails.find((o) => o.name === "state");
            state.value = response.data?.state;
            const city = this.profileDetails.find((o) => o.name === "city");
            city.value = response.data?.city;
                let schoolObject = response.data.school_name;
                let formattedValues = [];
                for (const key in schoolObject) {
                    if (schoolObject.hasOwnProperty(key)) {
                        const formattedValue = `${parseInt(key) + 1}. ${schoolObject[key]}`;
                        console.log(formattedValue);
                        formattedValues.push(formattedValue);
                    }
                }
                const school_name = this.profileDetails.find((o) => o.name === "school_name");
                school_name.value = formattedValues.join("\n");
                //..............
                let updateFields = [...this.state.modalFields, ...this.profileDetails]
                let poupFields = this.state.showProfile ? updateFields : this.state.modalFields;
                let fields = (poupFields || []).reduce(
                    (o, n) => ({ ...o, [n.name]: n }),
                    {}
                );
        
                let formdata = Object.map(fields, (v, k) => "");
                this.setState({
                    popupfields: fields,
                    popupdata: formdata,
                });
        }).catch((error) => {
            console.log('error', error);
            this.setState({ loader: false })
        });
    }

    onNext() { }

    onPrev() { }

    onPageChange(pageData) {
        this.setState(
            {
                search: {
                    ...this.state.search,
                    page: pageData.current,
                    limit: pageData.pageSize || pageData.defaultPageSize || 25,
                },
            },
            () => this.getSchoolCoordinatorById()
        );
    }

    deleteAction(value) {
        const school_id = (this.props.selectedEditMode || this.props.readMode) ?
        this.props.selectedRowData.school_id : localStorage.getItem("school_id");
        let payload = {
            school_id: school_id,
            profile_id: value.profile_id,
        };
        confirm(`Do you really want to delete the coordinator?`).promise.then(() => {
            this.props.api.apiDeleteSchoolCoordiator(payload).then(() => {
              this.getSchoolCoordinatorById();
            }).catch((error) => {
                console.log('error', error);
            });
          });
    }

    editAction(v) {
        // console.log("edit: ", v);
        this.openSchoolAdminModal();
        this.setState({
            popupdata: Object.except(v, ["school_id", "createdAt", "updatedAt"]),
            editMode: true,
            viewMode: false,
            editData: v,
        });
    }

    viewAction(v) {
        // console.log("view: ", v);
        this.openSchoolAdminModal();
        this.props.api.apiGetProfileDetailsById({profile_id: v.profile_id})
        .then((response) => {
            let schoolObject = response.data.school_name;
            let formattedValues = [];
            for (const key in schoolObject) {
                if (schoolObject.hasOwnProperty(key)) {
                    const formattedValue = `${parseInt(key) + 1}. ${schoolObject[key]}`;
                    console.log(formattedValue);
                    formattedValues.push(formattedValue);
                }
            }
            let viewData = {
                ...Object.except(v, [
                    "school_id", "createdAt", "updatedAt", "role", "profile_id", "user_id"
                ]),
              };
            viewData.school_name = formattedValues.join("\n");

           
            let newObj = {
                type: "text",
                name: "name",
                label: "Name",
                placeholder: "Select",
                required: true,
                value: v.name,
                customcolspan: 'col-lg-12', 
            }

            let viewModalFields = [newObj, ...this.profileDetails];

            let fields = (viewModalFields || []).reduce(
            (o, n) => ({ ...o, [n.name]: n }),
            {}
            );
            console.log({v, viewModalFields, fields, viewData});
            this.setState({
                popupfields: fields,
                popupdata: viewData,
                editMode: false,
                viewMode: true,
                editData: v,
            });
        }).catch((error) => {
            console.log(error);
        })

    }

    openSchoolAdminModal() {
        this.schoolAdminModalRef.current.click();
    }
    closeModal() {
        document.querySelector("#add_school_admin_modal #bsmodalclose").click();
    }

    schoolCoordinateInfo(text) {
        toast.success(text, {
          autoClose: 3000,
          closeButton: false,
          hideProgressBar: true,
        });
    }

    handleSubmit(event, data, form) {
        event.preventDefault();
        // console.log("formData", data, form);
        this.setState({ loader: true })
        const school_id = (this.props.selectedEditMode || this.props.readMode) ? this.props.selectedRowData.school_id : localStorage.getItem("school_id");
        let payload = {
            school_id: school_id,
            school_coordinator: data.school_coordinator,
        };
        this.props.api.apiAddSchoolCoordinateInfo(payload).then((response) => {
            // console.log({ apiAddSchoolCoordinateInfo: response });
            this.setState({ loader: false });
            this.schoolCoordinateInfo("School Coordinate Configured Successfully");
            this.closeModal();
            this.getSchoolCoordinatorById();
        }).catch((error) => {
            console.error("Error:", error);
            this.setState({ loader: false });
        });
    }

    componentDidMount() {
        this.getAllProfiles();
        this.getSchoolCoordinatorById();
    }

    render() {
        return (
            <>
            {this.state.loader && <div className="loader-container">
					<div className="spinner"></div>
				</div>}
            <ToastContainer position="top-right" />
            <div className="bg-white p-3">
                <div className="row">
                    {/* <div className="col"></div> */}
                    {!this.props.readMode && localStorage.getItem("currentAdminRole") == "SUPER_ADMIN" && (
                        <div className="my-4">
                            <Link
                                ref={this.schoolAdminModalRef}
                                data-bs-toggle="modal"
                                data-bs-target="#add_school_admin_modal"
                                onClick={(...args) => this.addModalFields(...args)}
                                className="btn add-btn me-2 mt-1"
                                // onClick={(...args) => this.onAddSchoolCoordinatorInfo(...args)}
                            >
                                <i className="fa fa-plus"></i>
                                Add School Coordinator
                            </Link>
                        </div>
                    )}
                </div>
                <div className="row schoolmanagement">
                    <div className="col-12">
                        {!!this.state.tableData.length && (
                            <AppTable
                                data={this.state.tableData}
                                columns={this.columns}
                                onNext={() => this.onNext()}
                                onPrev={() => this.onPrev()}
                                onChange={(...arg) => this.onPageChange(...arg)}
                                // total={rowcount}
                                reorder={true}
                                deleteAction={(v) => this.deleteAction(v)}
                                editAction={(v) => this.editAction(v)}
                                viewAction={(v) => this.viewAction(v)}
                                deactivatable={false}
                                deletable={this.props.readMode || this.role == "SCHOOL_ADMIN" ? false : true}
                                editable={false}
                                viewable={true}
                                targetType="tap"
                                showPagination={false}
                            ></AppTable>
                        )}
                        {this.state.tableData.length == 0 && <div className="empty_layout"></div>}
                    </div>
                </div>
                <div className="row m-auto">
                    <AppForm
                        className="flex flex-col gap-4 bg-white pb-0 pt-4 pe-2"
                        onSubmit={this.props.onSchoolCoordinatorConfig}
                    >
                        {((!this.props.readMode && !this.props.selectedEditMode && this.role != 'SCHOOL_ADMIN') || this.props.schoolStatus == "PENDING")
                        && <div className="row no-gutters m-2 mb-4">
                            <button className="btn close-btn col col-md-1 ml-auto rounded-1 text-dark "
                            onClick={() => {this.props.setGoSteps(3)}}>
                                Previous
                            </button>
                            <button
                                type="submit"
                                className="btn save-btn col col-md-1 ml-auto ms-2 me-2 rounded-1"
                            >
                                Save & Submit
                            </button>
                        </div>}
                    </AppForm>
                </div>
                <SchoolModal
                    id="add_school_admin_modal"
                    title={`${this.state.editMode ? "Edit School Coordinator Details" : this.state.viewMode ? "View School Coordinator Details" : "Add School Coordinator"}`}
                    submitText={`${this.state.editMode ? "Update" : "Submit"}`}
                    editdata={this.state.editData}
                    data={this.state.popupdata}
                    fields={this.state.popupfields}
                    disableallfields={this.state.viewMode ? true : false}
                    // disablefields={["email"]}
                    onSubmit={(...args) => this.handleSubmit(...args)}
                    submitButton={this.state.viewMode ? false : true}
                    closeButton={this.state.viewMode ? true : false}
                    onClose={() =>
                        this.setState({
                            popupdata: {},
                            editData: {},
                            editMode: false,
                            viewMode: false,
                        })
                    }
                />
                {(!this.props.readMode && this.props.selectedEditMode && this.props.schoolStatus != "PENDING") && <div className="row m-auto">
                    <AppForm className="flex flex-col gap-4 bg-white pt-0 pb-2">
                        <div className="row no-gutters m-2 mb-4">
                            <button
                                className="btn close-btn col col-md-1 ml-auto rounded-1 text-dark "
                                onClick={() => location.reload()}
                            >Cancel
                            </button>
                        </div>
                    </AppForm>
                </div>}
                {/* below button hidden on UI */}
                <button
                    className="btn btn-sm btn-outline-secondary d-none"
                    onClick={(...args) => this.addModalFields(...args)}
                    ref={this.schoolAdminModalRef}
                    data-bs-toggle="modal"
                    data-bs-target="#add_school_admin_modal"
                >
                <span>Hidden Button</span>
                </button>
            </div>
            </>
        );
    }
}

export default connect(SchoolCoordinatorConfig);
