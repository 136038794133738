export const appMethods =  {
    randomcolor() {
        let red = Math.ceil(Math.random()*250);
        let green = Math.ceil(Math.random()*250);
        let blue = Math.ceil(Math.random()*250);
        return `rgb(${red},${green},${blue})`;
    },
    setRequests(data) {
        return new Promise((resolve)=>{
            setTimeout(()=>{
                let app = window?.app;
                let requests = (app.props.store.requests||[]);
                app.store('requests',[...requests,...data]);
                resolve(requests);
            })
        });
    },
    pushRequest(data) {
        let app = window?.app;
        return app.mixin.setRequests([data]);
    },
    popRequest(id) {
        let app = window?.app;
        let requests = (app.props.store.requests||[]);
        return new Promise((resolve)=>{
            requests = requests.filter(o=>o.id!==id);
            window?.app.store('requests',requests);
            resolve(requests);
        });
    },
    alert:(message,config={type:"alert"})=> {
        let id = Math.random().toString().slice(2).parse();
        let close = ()=>{
            mixins.popRequest(id);
        };
        return {close,promise:new Promise((resolve,reject)=>{
            window.modalrequest = window.modalrequest||{};
            window.modalrequest[id] = {resolve,reject};
            mixins.pushRequest({ message, id,okText:"Close",...config });
        })}
    },
    success(message="Success",config={}) {
        return mixins.alert(message,{
            type:"alert",
            title:<div>
                <em className="la la-check-circle text-green-500 bounce"></em>
                <div className="inline-block mx-2">Success</div>
            </div>,
            okText:"DONE",
            ...config
        });
    },
    error(message="Failure",config={}) {
        return mixins.alert(message,{
            type:"alert",
            title:<div>
                <em className="la la-exclamation-triangle text-red-500 bounce"></em>
                <div className="inline-block mx-2">Error</div>
            </div>,
            okText:"DONE",
            ...config
        });
    },
    confirm(message,config={}) {
        return mixins.alert(message,{type:"confirm",title:"Attention!!",okText:"CONFIRM",cancelText:"CANCEL",...config});
    },
    prompt(question,attrs={},content=null,config={}) {
        let inattrs = {name:'prompt',id:"prompt",...attrs};
        content = content||(<div>
            <AppInput type="text" {...inattrs}></AppInput>
        </div>);
        let result = mixins.alert(content,{type:"confirm",title:question,okText:"CONFIRM",cancelText:"CANCEL",...config});
        let promise = result.promise;
        result.promise = new Promise((resolve,reject)=>{
            promise.then(()=>{
                let promptEle = document.querySelector('#prompt');
                let value = promptEle?.value||promptEle;
                resolve(value);
            }).catch(reject);
        });
        return result;
    },
    loader:(messgae)=>mixins.alert(<div>{messgae||"Loading..."}</div>,{type:'loader'})
}

export default appMethods;