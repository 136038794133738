import { useRef, useState } from "react";

const AppUploadFileCrop = (props) => {
  const inputRef = useRef();
  const [filevalue, setFilevalue] = useState(null);
  const [image, setImage] = useState("");
  const [croppedFile, setCroppedFile] = useState([]);
  const [currentPage, setCurrentPage] = useState("choose-img");
  const [imgAfterCrop, setImgAfterCrop] = useState(
    imagepaths.imgPlaceholderIcon
  );

  const onImageSelected = (selectedImg) => {
    setImage(selectedImg);
    setCurrentPage("crop-img");
  };

  const handleOnChange = (event) => {
    var input = document.getElementById("imageInput");
    var maxSizeInBytes = 1024 * 1024; // 1MB

    if (input.files.length > 0) {
      var fileSize = input.files[0].size;

      if (fileSize > maxSizeInBytes) {
        error("File size exceeds the allowed limit (1MB).");
        input.value = ""; // Clear the file input
      } else {
        if (event.target.files && event.target.files.length > 0) {
          const reader = new FileReader();
          let file = event.target.files[0];
          console.log({ file });
          reader.readAsDataURL(event.target.files[0]);
          reader.onload = function (e) {
            onImageSelected(reader.result);
          };
        }
      }
    }
  };

  const onChooseImg = () => {
    inputRef.current.click();
  };

  const onCropDone = (imgCroppedArea) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imgCroppedArea.width;
    canvasEle.height = imgCroppedArea.height;

    const context = canvasEle.getContext("2d");

    let imageObj1 = new Image();
    imageObj1.src = image;
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      );

      const dataURL = canvasEle.toDataURL("image/jpeg");

      setImgAfterCrop(dataURL);
      // console.log({ dataURL });
      // setCurrentPage("img-cropped");
      //
      // Step 1: Extract base64 data
      var base64Data = dataURL.split(",")[1];

      // Step 2: Decode base64 data
      var binaryData = atob(base64Data);

      // Step 3: Create Uint8Array
      var uint8Array = new Uint8Array(binaryData.length);
      for (var i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }

      // Step 4: Create Blob
      var blob = new Blob([uint8Array], { type: "image/png" });

      // Step 5: Create File object
      var fileName = "image.png"; // You can set the desired file name
      var file = new File([blob], fileName, { type: "image/png" });

      // Now you have a File object that you can use or manipulate as needed
      // console.log(file);
      setCroppedFile(file);
    };
  };

  const getFile = () => {
    let fileinput = document.getElementById("imageInput");
    console.log(fileinput, croppedFile);
    let loaded = loader("Uploading file please wait....");
    if (croppedFile) {
      setTimeout(() => {
        // let attachmentId = "fef8f4ad-2abb-47a7-964f-514fcd6ac09b";
        // setFilevalue(attachmentId);
        // console.log(inputRef);
        // // inputRef.current.value = attachmentId;
        // inputRef.current.setAttribute("value", attachmentId);
        // loaded.close();
        //
        app.api
          .apiFileUploader({
            file: croppedFile,
            file_type: props.fileType || "registration_form",
          })
          .then((fileresponse) => {
            console.log({ fileresponse });
            let attachmentId = fileresponse.data.attachment_id;
            setFilevalue(attachmentId);
            console.log({ file: attachmentId });
            // inputRef.current.setAttribute("invalue", attachmentId);
            inputRef.current.dataset.invalue = attachmentId;
            loaded.close();
          });
      }, 1000);
    }
  };

  const onCropCancel = () => {
    setImage("");
    setImgAfterCrop(imagepaths.imgPlaceholderIcon);
    setCurrentPage("choose-img");
  };

  return (
    <div className="image_crop_container">
      {currentPage === "choose-img" ? (
        <>
          <div className="cropimage_dropzone">
            <input
              id="imageInput"
              type="file"
              accept="image/*"
              ref={inputRef}
              name={props.name}
              onChange={handleOnChange}
            />
            <img
              src={imagepaths.imgPlaceholderIconSm}
              className="img-fluid placeholder_img"
              alt=""
            />
            <a href="#" type="button" className="" onClick={onChooseImg}>
              Select Image
            </a>
          </div>
          <div className="cropimage_previewzone">
            <img
              src={imgAfterCrop}
              className="cropped-img img-fluid"
              alt="Preview"
            />
          </div>
        </>
      ) : currentPage === "crop-img" ? (
        <>
          <input
            id="imageInput"
            type="file"
            accept="image/*"
            ref={inputRef}
            name={props.name}
            onChange={handleOnChange}
          />
          <div className="cropimage_container">
            <AppImageCropper
              image={image}
              onCropDone={onCropDone}
              onCropCancel={onCropCancel}
              getFile={getFile}
            />
          </div>
          <div className="cropimage_previewzone">
            <img
              src={imgAfterCrop}
              className="cropped-img img-fluid"
              alt="Preview"
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
export default connect(AppUploadFileCrop);
