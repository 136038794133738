import { yupResolver } from "@hookform/resolvers/yup";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";

const ForgotPassword = (props) => {
  // const schema2 = yup.object({
  //   email: yup
  //     .string()
  //     .matches(window?.constant?.emailrgx, "Email is required")
  //     .required("Email is required")
  //     .trim(),
  // });
  let navigate = useNavigate();

  const {
    control: control2,
    handleSubmit: handlePasswordSubmit,
    setError: setError2,
    formState: { errors2 },
  } = useForm();

  const onPasswordSubmit = (data) => {
    console.log("onPasswordSubmit", data);
    let payload = {}
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    // if (data?.email?.match(validRegex)) {
    //   payload['email'] = data.email;
    // }
    // else {
      payload['email'] = data.email;
    // }

    props.api
      .apiForgetPassword(payload)
      .then((res) => {
        console.log({ res });
        navigate('/open/sentsuccessfully');
      })
      .catch((err) => {
        console.log(err);
      });
    //*/
    
  };
  return (
    <>
      <Helmet>
        <title>Forgot Password - CognoSpace</title>
        <meta name="description" content="Forgot Password page" />
      </Helmet>
      <div className="account-content">
        <div className="container">
          {/* /Account Logo */}
          <div className="account-box">
            <div className="account-wrapper">
              {/* Account Logo */}
              <div className="py-3 d-flex justify-content-center">
                <Link to={"/open/login"}>
                    <img src={imagepaths.CognoSpaceLogoDark} alt="Logo" width="155px" />
                </Link>
              </div>
              <h3 className="account-title">Forgot Password</h3>

              {/* Account Form */}
              <form
                className="email_form"
                key={2}
                onSubmit={handlePasswordSubmit(onPasswordSubmit)}
              >
                <>
                  <div className="form-group">
                    <label>Email Id</label>
                    <Controller
                      name="email"
                      control={control2}
                      render={({ field: { value, onChange } }) => (
                        <input
                          className={`form-control  ${errors2?.required ? "error-input" : ""
                            }`}
                          type="text"
                          value={value}
                          onChange={onChange}
                          autoComplete="false"
                          placeholder="Enter Email"
                          required={true}
                        />
                      )}
                    />
                    <small>{errors2?.required?.message}</small>
                  </div>
                  <div className="form-group text-center">
                    <button
                      className="btn btn-primary account-btn"
                      type="submit"
                    >
                      Forgot Password
                    </button>
                  </div>
                </>
              </form>
              {/* /Account Form */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(ForgotPassword);